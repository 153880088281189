import React, { useState,useEffect,useRef }  from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import appico from '../iconimg/appico.png';
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import { MuiThemeProvider } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import { makeStyles,withStyles } from "@material-ui/core/styles";
import Footer from '../containers/Footer';

import ReplayIcon from '@mui/icons-material/Replay';
import FormControl from '@mui/material/FormControl';
import isEmail from 'validator/lib/isEmail';
import InputAdornment from "@mui/material/InputAdornment";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Button from '@mui/material/Button';

import Header from '../containers/Header';
import axios from 'axios';
import {isMobile,browserName} from 'react-device-detect';
 
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Card from 'react-bootstrap/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CloseIcon from '@mui/icons-material/Close';
import '../containers/amirifont.css'
import { FaMapMarkerAlt,FaPhoneAlt,FaMobileAlt,FaMapSigns, FaEnvelopeOpenText,FaMapMarkedAlt,FaMapPin,FaInfoCircle } from 'react-icons/fa';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import InfoIcon from '@mui/icons-material/Info';


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://AtibaDz.com/">
        Abdelaziz sebrou
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

 
  // Create rtl cache
  const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
  });


  const defaultColor = "#ff0000";
  const hoverColor = "#0000ff";
  const focusColor = "#00ff00";

  const themetextfieldnew = createTheme({
    overrides: {
      MuiOutlinedInput: {
        root: {
          "&:hover $notchedOutline": {
            borderColor: hoverColor
          },
          "&$focused $notchedOutline": {
            borderColor: focusColor
          }
        },
        notchedOutline: {
          borderColor: defaultColor
        }
      }
    },
    fieldset: {
      fontFamily: 
        '"Changa", sans-serif', 
        fontSize: 45,
         

    } 
  });



export default function LoginDon() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState('');
   // const history = useHistory();
    const navigate = useNavigate();

    const refemail = useRef(null);
    const ismobref = useRef(null);
    

    const [valueemail, setValueemail] = useState('');
    const [isValidemail, setIsValidemail] = useState(false);
    const [dirty, setDirty] = useState(false);
    const [emailverificated, setemailverificated] = React.useState(false);

    const [emailsended, setemailsended] = React.useState(false);
    const [errorsendingemail, seterrorsendingemail] = React.useState(false);
    const refpassword1 = useRef(null);

    const [FailedAuth, setFailedAuth] = useState(false);

    const [OpenDialog, setOpenDialog] = useState(false);

    const handleClickOpenDialog = () => {
      setOpenDialog(true);
      };
      
      
      const handleClickCloseDialog = () => {
        if(ismobref.current){
          ismobref.current.click();
        }
      setOpenDialog(false);
      };


    const [valuesPassword, setValuesPassword] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
      });

      const handleChangePassword = (prop) => (event) => {
        setValuesPassword({ ...valuesPassword, [prop]: event.target.value });
      };
    
      const handleClickShowPassword = () => {
        setValuesPassword({
          ...valuesPassword,
          showPassword: !valuesPassword.showPassword,
        });
      };
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

      
       



const onemailblur = event => {
  const val = event.target.value;   
   
  if(isValidemail){
  setDirty(true);
  }

   
    
 }

/*
 useEffect(() => {
  const timeoutId = setTimeout(() => {
    alert(ismobref.current.name)
    ///labelRef.current.click() // trigger click event of target element
    ismobref.current.click();
  }, 500)

  return () => clearTimeout(timeoutId)
}, [])
*/

const hostn=window.location.hostname;
const full = window.location.protocol + '//' + window.location.host;

const ismoblinkred='intent:'+full+'/LoginDon#Intent;package=com.android.chrome;end';
const [valuelink, setvaluelink] = useState(ismoblinkred);

 useEffect(() => {
  
   //alert('ismobile: '+browserName);
  if (isMobile && (browserName !=="Chrome" || browserName=="Facebook" || browserName=="" || browserName==undefined || browserName=="undefined" )) {
    if(ismobref.current){
     ismobref.current.click();
       handleClickOpenDialog();
}
  }

}, [valueemail]);
 

                useEffect(() => {
           
                  // Remove saved data from sessionStorage
                  sessionStorage.removeItem('Email_doct');
                  sessionStorage.removeItem('ID');
                   
                  
                }, []);



 useEffect(() => {
  console.log('diry changed='+dirty)
  if(dirty && isValidemail){
     ///ResendEmailClick();  
  }
  
}, [dirty]);


useEffect(() => {
  console.log('isValidemail '+isValidemail+ 'emailverificated ' +emailverificated + 'emailsended '+emailsended )
  setemailsended(false);
 
}, [isValidemail]);


const handleChangeemail = event => {
    const val = event.target.value;                
         
    if(isEmail(val)) {
       setIsValidemail(true);  
       console.log(val+"   yes email");            
    } else {
       setIsValidemail(false); 
       console.log(val+"   not email");                         
    }
         
    setValueemail(val); 
    
    
    /////handleChangeemail(val, isValidemail);
 }



    const Auth = async (e) => {

        

        let axiosConfig = {
            headers: {
                //'Content-Type' : 'application/json; charset=UTF-8',
                ///application/x-www-form-urlencoded
                ///'Content-Type' : 'application/x-www-form-urlencoded',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Accept': 'Token',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "POST",
                "Access-Control-Allow-Headers": "*",
                "Access-Control-Max-Age": "86400",
 
                
             }
          };

        e.preventDefault();
        try {


            const params = new URLSearchParams();
            params.append('Email_doct', valueemail);
            params.append('password', valuesPassword.password);
           

            await axios.post('https://www.doctorsdz.machrou3.com/user/checklogindon/'
            , params,axiosConfig)
            .then(response => {
                 
                console.log(response.data)
                ///checklogindonexist
                //alert((response.data.checklogindonexist))
                if(response.data.checklogindonexist){
                    setFailedAuth(false)
                    // Access value associated with the key
                var Email_doct = sessionStorage.getItem("Email_doct");
                sessionStorage.setItem("Email_doct", valueemail);

                var ID = sessionStorage.getItem("ID");
                sessionStorage.setItem("ID", response.data.ID);     
                  navigate('/UpdateRegisterDon');
                }else{
                    setFailedAuth(true)
                }
            })
            
 
        } catch (error) {
            
            if (error.response) {
                console.log(error.response)
                return 
            }

        }

 

    }


  const handleSubmit = (event) => {
    event.preventDefault();
    
   /// ismobref.current.click();

    if(!isValidemail){
        refemail.current.focus();
        return;
    }

    if(valuesPassword.password.length==0 ){
        refpassword1.current.focus();
        return
      }

    
    
    Auth(event);
    /*
    const data = new FormData(event.currentTarget);
     
    
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
    */

     
    

  };




  
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
        // Default transform is "translate(14px, 20px) scale(1)""
        // This lines up the label with the initial cursor position in the input
        // after changing its padding-left.
        transform: "translate(34px, 20px) scale(1);",
        fontFamily: 
        '"Changa", sans-serif',
              fontSize: 25,
              direction: "rtl"
      }
    },
    inputRoot: {
      
      color: "#191970",

      
      // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        fontFamily: '"Tajawal", sans-serif', 
        fontWeight:700,
        color: "#191970",
        // Default left padding is 6px
        paddingLeft: 26
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "#add8e6"
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "red"
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "purple"
      }
    }
  }));

  const classes = useStyles();
  
  function onLoad() {
    console.log('loaded');
    //alert('sdf');
  }


  return (
   
    <>
    <a style={{ display:'none' }} href={valuelink}
 
 ref={ismobref}
 
 target="_blank"></a>
    


    <Header />



    <Container component="main" maxWidth="md">
 
    <Grid
    container
    spacing={0}
    direction="column"
   justifyContent="flex-start"
   alignItems="center"
    style={{ minHeight: '100vh' }}
    >
    
    <div className="container" style={{marginTop:'-30px'}}>
               
               <div className="row text-center">

              
         
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar
          ////onClick={() => ismobref.current.click()}
           sx={{ m: 1, width:50, height:50, border: '2px solid #448a9a' }} src={appico}>
             
          </Avatar>
          <Typography 
          sx={{ color: "#00000", fontFamily: 
          '"Tajawal", sans-serif' 
          ,fontWeight:900,
          fontSize:16,color: "#191970", }}
          >
            تسجيل الدخول <br></br>
            (متبرع بالدم)
          </Typography>
          <Box component="form"  noValidate sx={{ mt: 1 }}>







{/* Email */ }
<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box sx={{ mt: 2 }}>
                  

<Grid container >
       

        <Grid item xs={12}>

        <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth variant="outlined"
       
       
       >

                <TextField    
                
                sx={{
                                    
                  '& .MuiFormHelperText-root': {
                    fontFamily: '"Tajawal", sans-serif',
                     fontSize:'14px',
                     color: '#b31d3e',
                     fontWeight:700
                      
                 },
                  '& .MuiFormLabel-root': {
                    fontFamily: '"Tajawal", sans-serif',
                     fontSize:'18px',
                     color: '#191970',
                     dir: 'rtl',
                      
                 },
                  '& .MuiInputBase-input': {
                     fontFamily: '"Tajawal", sans-serif',
                      fontSize:'22px',
                      color: '#191970',
                      fontWeight:700,
                      //text-align: right,
                      
                       
      
                                },
                  '& label.Mui-focused': {
                    fontSize:'16px',
                    color: '#b31d3e',
                    fontWeight:700
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#add8e6',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize:'16px',
                      borderColor: '#add8e6',
                    borderWidth: "2px",
                     
                    },
                    '&:hover fieldset': {
                      borderColor: 'purple',
                    },
                    '&.Mui-focused fieldset': {
                      
                      borderColor: 'purple',
                    },
                  } 
                }}

                      inputRef={refemail}
                     error={isValidemail === false}  
                     label="البريد الإلكتروني"
                     helperText={isValidemail === false ? "أدخل عنوان بريد إلكتروني صالح": <>                  
                       

                      </>}  

                    onBlur={(e) => onemailblur(e)}

                    onKeyPress={(ev) => {
                      //console.log(`Pressed keyCode ${ev.key}`);
                     if (ev.key === 'Enter') {
                        handleSubmit(ev);
                       ev.target.blur();///to hide keyboard
                       console.log("enter")
                       // Do code here
                       //console.log(JSON.stringify(ev.target.value));
                       //this.onSearchClickbtn();
                       ev.preventDefault();
                     }
                   }}

                  ///  id={props.fieldName}                    
                   /// label={props.label}
                  //  name={props.fieldName}                    
                    variant="outlined" 
                    size={'small'}
                   // helperText={props.helperText}
                    value={valueemail}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => handleChangeemail(e)}
                    name="email"
                />
                
            </FormControl>


            </Box>
</Grid>
 
</Grid>
 </Box>

</MuiThemeProvider>


</CacheProvider>



{/* Password 01 */ }
<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box sx={{ mt: 2 }}>

<Grid container >
       

        <Grid item xs={12}>

        <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth variant="outlined"
       
       
       > 


 
<InputLabel 
          sx={{ color: "#b31d3e", fontFamily: 
          '"Tajawal", sans-serif', 
          fontSize: 16
          ,fontWeight:700 }}
          htmlFor="outlined-adornment-password">كلمة المرور</InputLabel>
          
          <OutlinedInput
           fullWidth 
           dir="rtl"  
           sx={{
                
                //direction:'ltr',
                
               '& .MuiFormHelperText-root': {
                 fontFamily: '"Tajawal", sans-serif',
                  fontSize:'14px',
                  color: '#b31d3e',
                  fontWeight:700
                   
              },
               '& .MuiFormLabel-root': {
                 fontFamily: '"Tajawal", sans-serif',
                  fontSize:'18px',
                  color: '#191970',
                  dir: 'rtl',
                   
              },
               '& .MuiInputBase-input': {
                  fontFamily: '"Tajawal", sans-serif',
                   fontSize:'22px',
                   color: '#191970',
                   fontWeight:700,
                   //text-align: right,
                   
                    
   
                             },
               '& label.Mui-focused': {
                 fontSize:'16px',
                 color: '#b31d3e',
                 fontWeight:700
               },
               '& .MuiInput-underline:after': {
                 borderBottomColor: '#add8e6',
               },
               '& .MuiOutlinedInput-root': {
                 '& fieldset': {
                   fontFamily: '"Tajawal", sans-serif',
                   fontSize:'16px',
                   borderColor: '#add8e6',
                 borderWidth: "2px",
                  
                 },
                 '&:hover fieldset': {
                   borderColor: 'purple',
                 },
                 '&.Mui-focused fieldset': {
                   
                   borderColor: 'purple',
                 },
               } 
             }}
  
             onKeyPress={(ev) => {
                //console.log(`Pressed keyCode ${ev.key}`);
               if (ev.key === 'Enter') {
                  handleSubmit(ev);
                 ev.target.blur();///to hide keyboard
                 console.log("enter")
                 // Do code here
                 //console.log(JSON.stringify(ev.target.value));
                 //this.onSearchClickbtn();
                 ev.preventDefault();
               }
             }}

             inputRef={refpassword1}
            id="outlined-adornment-password"
            type={valuesPassword.showPassword ? 'text' : 'password'}
            value={valuesPassword.password}
            onChange={handleChangePassword('password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {valuesPassword.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="كلمة المرور"
            name="password"

          />




       </FormControl>
          </Box>
</Grid>
 
</Grid>
 </Box>

</MuiThemeProvider>


</CacheProvider>




{FailedAuth? <>
        
        <FormHelperText 
         sx={{ color: "#b31d3e", fontFamily: 
         '"Tajawal", sans-serif', 
         fontSize: 12,
         mt:2
         ,fontWeight:700 }}
         error id="accountId-error">
          .بريدك الإلكتروني غير مسجل لدينا أو كلمة السر غير صحيحة
          </FormHelperText>
            
      
      </>: <></>}
        





 

           {/* Submit Button */ }
<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box sx={{ mt: 2 }}>

<Grid container >
       

        <Grid item xs={12}>

        <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth variant="outlined"
       
       
       > 

      <div>

       
      <Button
              fullWidth
              onClick={handleSubmit}
              variant="contained"
              
                
            endIcon={<LockOpenIcon sx={{ml:'5px', dir:'rtl',transform:'scaleX(-1)'}} />}>
    <Typography  sx={{ color: "#FFFFFF", fontFamily: 
           '"Tajawal", sans-serif', 
           fontSize: 22
           ,fontWeight:900 }}>
           تسجيل الدخول     
    </Typography>
              
            </Button>



            </div>


      </FormControl>
          </Box>
</Grid>
 
</Grid>
 </Box>

</MuiThemeProvider>


</CacheProvider>


 


 
<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
            <Grid spacing={0}
  direction="column"
  alignItems="center"
  justifyContent="center"
  dir="rtl" container sx={{mt:2,}}>
              <Grid item >
                <Link href="/ResetPwDon" variant="body2"
                sx={{ fontFamily: 
                '"Tajawal", sans-serif', 
                fontSize: 12
                ,fontWeight:900 }}>
                  هل نسيت كلمة السر؟
                </Link>
              </Grid>
              <Grid item sx={{mt:1,}}>
                <Link href="/RegisterDon"  variant="body2"
                sx={{ fontFamily: 
                  '"Tajawal", sans-serif', 
                  fontSize: 12
                  ,fontWeight:900 }}>
                  {"هل تريد إنشاء حساب جديد؟"}
                </Link>
              </Grid>
            </Grid>

            </MuiThemeProvider>


</CacheProvider>



          </Box>
        </Box>
         
     
     
        </div>

</div>


   
   </Grid>

   </Container>

   <Footer />


       
   <Dialog
       PaperProps={{
        style: {
          backgroundColor: '#add8e6',
          boxShadow: 'none',
        },
      }}
       //fullScreen={mediumScreen}
        open={OpenDialog}
        onClose={handleClickCloseDialog}
        aria-labelledby="responsive-dialog-title"
      >

<Card  style={{}} sx={{ maxWidth: 345  }}>

  {/* <p className="h5 font-weight-bold text-primary" id={showhidvalueteldate}><FaCalendarAlt/> تاريخ آخر تبرع: {props.daydon+'/'+props.monthdon+'/'+props.yeardon}</p>
 */ }
<CardHeader
        avatar={
          
            <InfoIcon sx={{color:'#2196F3',width: 50, height: 50,ml:2}}></InfoIcon>
         
        }
        action={
         
           
        <IconButton  onClick={handleClickCloseDialog} aria-label="settings" sx={{ ml:-1,mt:-1  }}>
                    
        <CloseIcon  /> 

        </IconButton>
        } 
        title={
          <>
          <Box sx={{ ml:2, mt:0, display: 'flex', alignItems: 'flex-end', justifyContent: 'center'}}>

          <Typography sx={{ color:'#191970', fontFamily: '"Tajawal", sans-serif', fontSize: 14,fontWeight:900}} >
          من الأفضل إستعمال متصفح كروم Chrome 
          </Typography>
            </Box>
            
            </>
        
        }
        subheader=""
      />


       


</Card>

      </Dialog>
         



   </>
   
  );
}