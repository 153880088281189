import React, { useState,useEffect, useRef }  from 'react';

import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Header from '../containers/Header';
import Footer from '../containers/Footer';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { CacheProvider } from "@emotion/react";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import rtlPlugin from "stylis-plugin-rtl";
import { makeStyles,withStyles } from "@material-ui/core/styles";
import FormControl from '@mui/material/FormControl';
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import IconButton from '@material-ui/core/IconButton';
import ReplayIcon from '@mui/icons-material/Replay';
import Container from '@mui/material/Container';
import SaveIcon from '@mui/icons-material/Save';

import CircularProgress from '@mui/material/CircularProgress';


import SearchIcon from "@mui/icons-material/Search";

import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';

import Input from '@mui/material/Input';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';


import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
 import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import isEmail from 'validator/lib/isEmail';
import InputAdornment from "@mui/material/InputAdornment";
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Card from 'react-bootstrap/Card';
import Avatar from '@mui/material/Avatar';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CloseIcon from '@mui/icons-material/Close';
import '../containers/amirifont.css'
import { FaMapMarkerAlt,FaPhoneAlt,FaMobileAlt,FaMapSigns, FaEnvelopeOpenText,FaMapMarkedAlt,FaMapPin,FaInfoCircle } from 'react-icons/fa';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';


const options_Sp = [ //sp mean  blood typ
  { value: 1, label: '+A' },
  { value: 2, label: '-A' },
  { value: 3, label: '+B' },
  { value: 4, label: '-B' },
  { value: 5, label: '+AB' },
  { value: 6, label: '-AB' },
  { value: 7, label: '+O' },
  { value: 8, label: '-O' }
]


let options_Wly = [];
 let codeemail = Math.floor(1000 + Math.random() * 9000);

let Wlylists=[];
 let BldOnly=[];
 
 let userinfos=[];

let searchtxtvalue='';
let selectSpValue=0;
let selectWlyValue=0;
let selectBldValue=0;
 
let Bldlists=[];
let BldlistsCard=[];
let selectedWlyOption=null;
let firstloadwly=false;
let firstloadbld=false;

let sendemailbyemailoneortwo='https://www.doctorsdz.machrou3.com/user/sendemailbyemailone/';


  // Create rtl cache
  const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
  });

  // Create ltr cache
  const cacheLtr = createCache({
    key: 'muiltr',
    stylisPlugins: [prefixer, rtlPlugin],
  });


  const defaultColor = "#ff0000";
  const hoverColor = "#0000ff";
  const focusColor = "#00ff00";

  const themetextfieldnew = createTheme({
    overrides: {
      MuiOutlinedInput: {
        root: {
          "&:hover $notchedOutline": {
            borderColor: hoverColor
          },
          "&$focused $notchedOutline": {
            borderColor: focusColor
          }
        },
        notchedOutline: {
          borderColor: defaultColor
        }
      }
    },
    fieldset: {
      fontFamily: 
        '"Changa", sans-serif', 
        fontSize: 45,
         

    } 
  });




const textmaskphone1Custom = React.forwardRef(function textmaskphone1Custom(props, ref) {
    const { onChange, ...other } = props;
    
    return (
        <>       

        

         <CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box >

<Grid container >
       

        <Grid item xs={12}>



      <IMaskInput
      
        {...other}
        mask="0000000000"
        ///value=""
        unmask={false} // true|false|'typed'
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
        // input props also available
        placeholder='مثال: 069988776655'
      />


</Grid>
      </Grid>

</Box>
</MuiThemeProvider>


</CacheProvider>


</>


    );
  });
  
 
  textmaskphone1Custom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };


  const textmaskphone2Custom = React.forwardRef(function textmaskphone2Custom(props, ref) {
    const { onChange, ...other } = props;

   return (
        <>       
        
         <CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box >

<Grid container >
       

        <Grid item xs={12}>



      <IMaskInput
      
        {...other}
        mask="0000000000"
        ///value=""
        unmask={false} // true|false|'typed'
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
        // input props also available
        placeholder='مثال: 069988776655'
      />


</Grid>
      </Grid>

</Box>
</MuiThemeProvider>


</CacheProvider>


</>


    );
  });
  


  textmaskphone2Custom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };



  const textmaskcodeemailCustom = React.forwardRef(function textmaskcodeemailCustom(props, ref) {
    const { onChange, ...other } = props;

   return (
        <>       
        
         <CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box >

<Grid container >
       

        <Grid item xs={12}>

      <IMaskInput
      
        {...other}
        mask="0000"
        ///value=""
        unmask={false} // true|false|'typed'
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
        // input props also available
        placeholder='أدخل رمز التحقق هنا'      />


</Grid>
      </Grid>

</Box>
</MuiThemeProvider>


</CacheProvider>


</>


    );
  });
  


  textmaskcodeemailCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };






const UpdateRegisterDon = () => {
  var relativeTime = require('dayjs/plugin/relativeTime')

  dayjs.extend(relativeTime)

  const [OpenDialog, setOpenDialog] = useState(false);

  const [disableallfield, setdisableallfield] = React.useState(false);
  const [activeswitch, setactiveswitch] = React.useState('active');

  

  //const [valueDate, setValueDate] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [valueDate, setValueDate] = React.useState(dayjs());
  //const [valueDate, setValueDate] = React.useState(dayjs('1999-01-01'));

  const [emailverificated, setemailverificated] = React.useState(false);

  const [emailsended, setemailsended] = React.useState(false);
  const [errorsendingemail, seterrorsendingemail] = React.useState(false);

  const [valuefullname, setvaluefullname] = React.useState('');
  
  const [BldName, setBldName] = React.useState([]);

  
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
    };
    
    
    const handleClickCloseDialog = () => {
    setOpenDialog(false);
    };




  const handleChangeDate = (newValue) => {
    setValueDate(newValue);
  };


 
  const ALPHA_NUMERIC_DASH_REGEX = /[a-zA-Zء-ي\s\p{N}]/; ///^[\p{Arabic}\s\p{N}]+$ //////[a-zA-Zء-ي]/




  const [valuesPassword, setValuesPassword] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const [valuesPasswordRe, setValuesPasswordRe] = React.useState({
    amount: '',
    PasswordRe: '',
    weight: '',
    weightRange: '',
    showPasswordRe: false,
  });


  const handleChangePasswordRe = (prop) => (event) => {
    setValuesPasswordRe({ ...valuesPasswordRe, [prop]: event.target.value });
  };

  const handleClickShowPasswordRe = () => {
    setValuesPasswordRe({
      ...valuesPasswordRe,
      showPasswordRe: !valuesPasswordRe.showPasswordRe,
    });
  };

  const handleMouseDownPasswordRe = (event) => {
    event.preventDefault();
  };


  /////////////////


  const handleChangePassword = (prop) => (event) => {
    setValuesPassword({ ...valuesPassword, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValuesPassword({
      ...valuesPassword,
      showPassword: !valuesPassword.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };








    const [valuesphone1, setValuesphone1] = React.useState({
        textmaskphone1: '',
        numberformat: '1320',
      });


      const [valuesphone2, setValuesphone2] = React.useState({
        textmaskphone2: '',
        numberformat: '1320',
      });

      const [valuescodeemail, setValuescodeemail] = React.useState({
        textmaskcodeemail: '',
        numberformat: '1320',
      });
    
    
      const handleChangephone1 = (event) => {
        setValuesphone1({
          ...valuesphone1,
          [event.target.name]: event.target.value,
        });
      };

      const handleChangephone2 = (event) => {
        setValuesphone2({
          ...valuesphone2,
          [event.target.name]: event.target.value,
        });
      };

      

      const handleChangecodeemail = (event) => {
        if(event.target.value == codeemail){
          //alert('verificated succussfully');
          setemailverificated(true);
        }
        setValuescodeemail({
          ...valuescodeemail,
          [event.target.name]: event.target.value,
        });
      };

       

      

      const ResendEmailClick = async ( ) => {

        /// alert(codeemail+' now')
          setemailverificated(false);
          setemailsended(false);
          seterrorsendingemail(false)
          setDirty(false)

          if(sendemailbyemailoneortwo =='https://www.doctorsdz.machrou3.com/user/sendemailbyemailone/'){
            sendemailbyemailoneortwo='https://www.doctorsdz.machrou3.com/user/sendemailbyemailtwo/';
          }
          else{
            sendemailbyemailoneortwo='https://www.doctorsdz.machrou3.com/user/sendemailbyemailone/';
          }


        let axiosConfig = {
            headers: {
                //'Content-Type' : 'application/json; charset=UTF-8',
                ///application/x-www-form-urlencoded
                ///'Content-Type' : 'application/x-www-form-urlencoded',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Accept': 'Token',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "POST",
                "Access-Control-Allow-Headers": "*",
                "Access-Control-Max-Age": "86400",
 
             }
          };

       // e.preventDefault();
        try {

            const params = new URLSearchParams();
            params.append('codeemail', codeemail);
            params.append('emailtosend', valueemail);
           
            await axios.post(sendemailbyemailoneortwo
            , params,axiosConfig)
            .then(response => {
                  
                 if(codeemail == response.data.codeemail){
                  
                  setemailsended(true);
                  //alert('resp '+response.data.emailtosend)
                  

                 }
                  
                console.log(response)
            })
            //navigate('/dashboard');
 
        } catch (error) {
            
            if (error.response) {
                seterrorsendingemail(true)
                console.log(error.response)
                return 
            }

        }

 

       
    }






      







    const onsubmitclicked = async (e) => {

       


      let axiosConfig = {
          headers: {
              //'Content-Type' : 'application/json; charset=UTF-8',
              ///application/x-www-form-urlencoded
              ///'Content-Type' : 'application/x-www-form-urlencoded',
              'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
              'Accept': 'Token',
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "POST",
              "Access-Control-Allow-Headers": "*",
              "Access-Control-Max-Age": "86400",

           }
        };

      e.preventDefault();
      try {

          const params = new URLSearchParams();
          params.append('Namef', valuefullname);
          params.append('bloodtype', bloodtype);
          params.append('Tel1', valuesphone1.textmaskphone1);

          params.append('Tel2', valuesphone2.textmaskphone2);
          params.append('Wilaya', valuewly.value);
          params.append('idbaladiya', valuebld.value);
          params.append('daydon', valueDate.get('date'));
          params.append('monthdon', valueDate.get('month')+1);
          params.append('yeardon', valueDate.get('year'));
          params.append('Email_doct', userinfos[0].Email_doct); 
          let ended=0;
          if(activeswitch==false){
            ended=1;
          }else{
            ended=0;
          }
           
          params.append('ended', ended);   
          
 /*
    refphone1
refphone2
refemail
refcodeemail
refpassword1
refpassword2
  ///alert(valueDate.get('year'))
      
     dayjs().get('year')
     dayjs().get('month') // start 0
     dayjs().get('date')
      

     
  /*  alert('bloodtype:'+bloodtype+'  valuewly:'+valuewly.value+' valuebld'+valuebld.value+' valuefullname'+valuefullname
    +'valuesphone1.textmaskphone1: '+valuesphone1.textmaskphone1+' valuesphone1.textmaskphone2: '+valuesphone2.textmaskphone2+' valueemail: '+valueemail+' valuesPassword.password:'+valuesPassword.password);
*/

          await axios.post('https://www.doctorsdz.machrou3.com/user/updatedonuserinfo/'
          , params,axiosConfig)
          .then(response => {
                  
            if(response.data.savedsucces){
                 // navigate('/LoginDon');
                 handleClickOpenDialog();
                  
            }else{
                alert('لم يتم تسجيل المعلومات حاول مرة')
            }
                 ///alert('response '+response.data.Namef)
               

                
              console.log(response)
          })
          //navigate('/dashboard');

      } catch (error) {
          
          if (error.response) {
              
              console.log(error.response)
              return 
          }

      }



     
  }















    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');
    const [msg, setMsg] = useState('');
    

    const [showhidvalue, setshowhidvalue] = useState('HideBld');

    const [bloodtype, setbloodtype] = React.useState(options_Sp[0].value);

    const [options_Bld, setoptions_Bld] = useState([]);


    const [valuebld, setvaluebld] = useState([]);

  const [valuewly, setvaluewly] = useState([]);

  const [phoneV, setphoneV] = useState(0);

  const phone = useRef('phone');

  const reffullname = useRef(null);

  const refphone1 = useRef(null);
  const refphone2 = useRef(null);
  const refemail = useRef(null);
  const refcodeemail = useRef(null);
  const refpassword1 = useRef(null);
  const refpassword2 = useRef(null);



const onChangePhone = (data) => {
    setphoneV(data.target.value)
  }



  const [hidepage, sethidepage] = useState(true);


  const [valueemail, setValueemail] = useState('');
  const [isValidemail, setIsValidemail] = useState(false);
  const [dirty, setDirty] = useState(false);

  
  const handleChangeemail = event => {
    const val = event.target.value;                
         
    if(isEmail(val)) {
       setIsValidemail(true);  
       console.log(val+"   yes email");            
    } else {
       setIsValidemail(false); 
       console.log(val+"   not email");                         
    }
         
    setValueemail(val); 
    
    
    /////handleChangeemail(val, isValidemail);
 }

 const onemailblur = event => {
  const val = event.target.value;   
   
  if(isValidemail){
  setDirty(true);
  }

   
    
 }

 useEffect(() => {
  console.log('diry changed='+dirty)
  if(dirty && isValidemail){
     ResendEmailClick();  
  }
  
}, [dirty]);


useEffect(() => {
  console.log('isValidemail '+isValidemail+ 'emailverificated ' +emailverificated + 'emailsended '+emailsended )
  setemailsended(false);
 
}, [isValidemail]);





  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
        // Default transform is "translate(14px, 20px) scale(1)""
        // This lines up the label with the initial cursor position in the input
        // after changing its padding-left.
        transform: "translate(34px, 20px) scale(1);",
        fontFamily: 
        '"Changa", sans-serif',
              fontSize: 25,
              direction: "rtl"
      }
    },
    inputRoot: {
      
      color: "#191970",

      
      // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        fontFamily: '"Tajawal", sans-serif', 
        fontWeight:700,
        color: "#191970",
        // Default left padding is 6px
        paddingLeft: 26
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "#add8e6"
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "red"
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "purple"
      }
    }
  }));

  const classes = useStyles();
  
  const useStylesSelect = makeStyles(() => ({
    customselect: {
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "#add8e6",
      },
      '& .MuiSelect-select': {
        fontFamily: '"Tajawal", sans-serif', 
        fontWeight:700,
        color: "#191970",
        // Default left padding is 6px
        paddingLeft: 26
      }
    },


  }));

  const classesSelect = useStylesSelect();


  const onSpChanged = (event, values) => {
    //settags(values);
     selectSpValue=event.target.value;
  console.log(event.target.value);
  setbloodtype(event.target.value);
  //alert(event.target.value)
  //onSearchClick();
    
  }




  const onwlyChanged = (event, values) => {
    
    
    console.log('selectedWlyOption value= '+values.value)
    selectWlyValue=values.value;
     setvaluewly(values);
     selectedWlyOption=values;
      
      ///alert("wly changed")
    ///onSearchClick();

  }


  const onbldChanged = (event, values) => {
    selectBldValue=values.value;
    setvaluebld(values)
    console.log('values.value onbldChanged'+values)
    ///onSearchClick();
  }


     useEffect(() => {

      if(sessionStorage.getItem("ID")==null || sessionStorage.getItem("ID")==undefined || sessionStorage.getItem("ID")==""){
        sethidepage(true)
      }else{
        sethidepage(false)
      }

   
   


      ///alert(sessionStorage.getItem("ID"))
    // Logging the event that component is mount on first render
     console.log('useEffect is mounted');
      
     selectSpValue=0;
     selectWlyValue=0;
     selectBldValue=0;
        
         
   fetch('https://doctorsdz.machrou3.com/user/getBaladiya4appdoc')
   .then(response=> response.json())
   .then(getBaladiya4appdoc => {
    
   Wlylists=getBaladiya4appdoc;
   Bldlists=getBaladiya4appdoc;
   BldlistsCard=getBaladiya4appdoc;


   /////////////Wilayets///////////////////

const WlyOnly = Wlylists.filter(Wlylists =>{
  return Wlylists.wlycaptal !==null;
  })
  
  options_Wly =WlyOnly.sort(function(a, b){
  if(Number(a.MatWilaya) < Number(b.MatWilaya)) return -1;  // Ascending Order
  if(Number(a.MatWilaya) > Number(b.MatWilaya)) return 1;   // Descending Order  
  return 0; // Default
  }).map(function (Wlylists,i) {
  return { value: Wlylists.MatWilaya, label:  Wlylists.MatWilaya+'- ' + Wlylists.NOM+' ('+Wlylists.Nomfr+')',indexvalue:i};
  }) 
  
  ///setvaluewly(options_Wly[0]);
  
   
   })
   .then(response => {
    
     //alert('wly first loaded'+Wlylists.length)
    if(userinfos.length !==0){
      /// alert('wly first loaded')
     
       if(options_Wly.length !=0 && userinfos[0].Wilaya !=null){
           const  WlyName = options_Wly.find(options_Wly =>{
             return options_Wly.value ==userinfos[0].Wilaya;
           })
           if( WlyName !=null){
            setvaluewly(WlyName)
           }else{
             setvaluewly(options_Wly[0]);
           }
         } 
        
     }else{
       setvaluewly(options_Wly[0]);
     }
 
})
.catch((response) => {

});


  
 


   fetch('https://doctorsdz.machrou3.com/user/getinfoAppBloodbyID/'+sessionStorage.getItem("ID"))
   .then(response=> response.json())
   .then(getinfoAppBlood => {
    
     userinfos=getinfoAppBlood;
      //alert(userinfos[0].Namef)
     setvaluefullname(userinfos[0].Namef);
     setbloodtype(userinfos[0].bloodtype);
     setValuesphone1({
      ...valuesphone1,
      textmaskphone1: '0'+userinfos[0].Tel1,
    });

    setValuesphone2({
      ...valuesphone2,
      textmaskphone2: '0'+userinfos[0].Tel2,
    });

    setValueDate(dayjs().set('date', userinfos[0].daydon).set('month', userinfos[0].monthdon-1).set('year', userinfos[0].yeardon))
   

    if(userinfos[0].ended==0){
      setdisableallfield(false);
      setactiveswitch('active')
    }else{
      setdisableallfield(true);
      setactiveswitch(false)
    }


    /////////setValueemail(userinfos[0].Email_doct);

   /*

   if(userinfos.length !=0){
      if(userinfos[0].idbaladiya !=null){
        const  BldName = options_Bld.find(options_Bld =>{
          return options_Bld.value ==userinfos[0].idbaladiya;
        })
        if( BldName !=null){
          
          setvaluebld(BldName)
        }
      }

    }

   */



     ///setvaluewly(userinfos[0].Wilaya);
     ///setvaluebld(userinfos[0].idbaladiya);

    // setvaluebld(options_Bld[0]);

     /*
     $this->session->set_userdata('Namef', $resbyid->Namef);
  $this->session->set_userdata('bloodtype', $resbyid->bloodtype);
  $this->session->set_userdata('Tel1', $resbyid->Tel1);
  $this->session->set_userdata('Tel2', $resbyid->Tel2);
  $this->session->set_userdata('Wilaya', $resbyid->Wilaya);
  $this->session->set_userdata('baladiya', $resbyid->idbaladiya);
  $this->session->set_userdata('daydon', $resbyid->daydon);
  $this->session->set_userdata('monthdon', $resbyid->monthdon);
  $this->session->set_userdata('yeardon', $resbyid->yeardon);

     userinfos.map(function (userinfos,i) {
       alert(userinfos.Namef)
    }) */

  })
  


    
   
   
      
    
 


/*
    if(firstloadbld ==false && userinfos.length !=0 && userinfos[0].Wilaya==valuewly.value ) {
      alert(userinfos[0].Wilaya)
      if(userinfos[0].idbaladiya !=null){
        const  BldName = options_Bld.find(options_Bld =>{
          return options_Bld.value ==userinfos[0].idbaladiya;
        })
        if( BldName !=null){
          alert(BldName.label)
          setvaluebld(BldName)
          firstloadbld=true;
        }
      }
    
    }*/

    


    
    















}, []);


useEffect(() => {
    if (options_Wly.length!=0) {

        ////alert('wly loaded');
      
        selectWlyValue=valuewly.value;
    
        selectedWlyOption=valuewly;

    
    {BldOnly = Bldlists.filter(Bldlists =>{
      

      return Bldlists.MatWilaya ==selectedWlyOption.value;
    })}

    console.log('BldOnly value= '+BldOnly.length)

    /////////////Baladia///////////////////
{setoptions_Bld (BldOnly.sort(function(a, b){
if(Number(a.ID) < Number(b.ID)) return -1;  // Ascending Order
if(Number(a.ID) > Number(b.ID)) return 1;   // Descending Order  
return 0; // Default
}).map(function (Bldlists,i) {
return { value: Bldlists.ID, label: (i+1)+'- ' + Bldlists.NOM+' ('+Bldlists.Nomfr+')',indexvalue:i};
}))}



    }
     
    
    
  }, [valuewly]);

 
  useEffect(() => {

    
    if(userinfos.length !=0){
      if(firstloadbld ==false && userinfos[0].Wilaya ==valuewly.value){
         
         
    
         if(userinfos[0].idbaladiya !=null){
          ///alert('wly loaded ok'+options_Bld.length)
          const  BldName1 = options_Bld.find(options_Bld =>{
            return options_Bld.value ==userinfos[0].idbaladiya;
          })
          if( BldName1 !=null){
             
           /// alert(BldName1.value)
            setvaluebld(BldName1)
            firstloadbld=true;
             
          }
        }
    
    
      }else{
        setvaluebld(options_Bld[0]);
      }
    }

    
}, [options_Bld]); 


/*
useEffect(() => {

  if (options_Wly.length != 0) {
    
alert('loaded wly')
}
  
}, [options_Wly]);
*/


    ///const history = useHistory();
    const navigate = useNavigate();
    ///navigate('/home');


    const Register = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://doctorsdz.machrou3.com/user/singup_don_appdoc', {
                name: name,
                email: email,
                password: password,
                confPassword: confPassword
            }).then(response => {
                setMsg(response.data.msg);
                ///console.log(JSON.parse(JSON.stringify(response)));
                alert(JSON.parse(JSON.stringify(response.data.msg)))
                ///JSON.parse(JSON.stringify(response));
            });
            //history.push("/");
            //navigate('/');
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }

    return (

      

        <>
        {!hidepage? <>
        <Header />


        { !(Wlylists.length || Bldlists.length || options_Sp.length || options_Wly.length) ?  
    <>
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <CircularProgress />
      {/*<Box sx={{ width: '100%',mt:2 }} > 
      
      <LinearProgress />
      </Box>
      */}

      </div>
 
      </>

      :

      <>



        <Container component="main" maxWidth="md">

<Grid
container
spacing={0}
direction="column"
alignItems="center"
justifyContent="center"
style={{ minHeight: '100vh' }}
>

<div className="container" style={{marginTop:'-30px'}}>
           
           <div className="row text-center">


           
           <CacheProvider value={cacheRtl}>

           <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth variant="outlined"
       
       classes={{ root: classesSelect.customselect }}
       >
 
        <InputLabel id="demo-simple-select-label"
        sx={{ color: "#b31d3e", fontFamily: 
        '"Tajawal", sans-serif', 
        fontSize: 16
        ,fontWeight:700 }}
        >زمرة الدم</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={bloodtype}
          label="زمرة الدم"
          onChange={onSpChanged}
          disabled={disableallfield}
          classes={{ root: classesSelect.customselect }}
        >
   
  
  {options_Sp.map((u,i) =>

i % 2 == 0 ? 
      
<MenuItem key={i} sx={{ backgroundColor: "#add8e6",color: "#191970", fontFamily: 
'"Tajawal", sans-serif', 
fontSize: 18
,fontWeight:700 }} value={u.value} primaryText={u.label}>
{u.label}
</MenuItem>
      
            
          :
           
          <MenuItem key={i} sx={{ backgroundColor: "#FFFFFF",color: "#191970", fontFamily: 
          '"Tajawal", sans-serif', 
          fontSize: 18
          ,fontWeight:700 }} value={u.value} primaryText={u.label}>
          {u.label}
          </MenuItem>

           
          )}
       
       
    
        
         
        </Select>
      </FormControl>
    </Box>



</CacheProvider>






{/* Autocomplement Wilayat  */}
  
<CacheProvider value={cacheRtl}>

 
<Autocomplete
disabled={disableallfield}
noOptionsText={'حدث الصفحة مرة أخرى'}
classes={classes}
id="combo-box-demo"
onChange={onwlyChanged}
options={options_Wly}
value={valuewly}
sx={{ mt: 2 }}
defaultValue={options_Wly[0]}
//disablePortal
/* classes={{
option: styles.option
}}*/
renderOption={(props, option) => {
const { label, indexvalue } = option;

return (
indexvalue % 2 == 0 ? 

<span {...props}   style={{ backgroundColor: "#add8e6",color: "#191970", fontFamily: 
'"Tajawal", sans-serif', 
fontSize: 18
,fontWeight:700 }}>

{(indexvalue  == -1 ?
<>
  {'- ' +label} 
   </>
  :
  <>
  {label} 
  </>
  )}
  

</span>
:

<span {...props}   style={{ backgroundColor: "#FFFFFF",color: "#191970", fontFamily: 
'"Tajawal", sans-serif', 
fontSize: 18
,fontWeight:700 }}>

{(indexvalue  == -1 ?
<>
  {'- ' +label} 
   </>
  :
  <>
  {label} 
  </>
  )}


</span>

);
}} 



//sx={{ width: 300 }}
renderInput={(params) => <Box display="flex" justifyContent="center">  <TextField fullWidth  {...params} label="الولايات"  /> </Box>  }
/>



</CacheProvider>





{/* Autocomplement Baladia */ }
  
<CacheProvider value={cacheRtl}>

 
   
<Autocomplete
disabled={disableallfield}
noOptionsText={'إختر الولاية'}
classes={classes}
value={valuebld}
id="combo-box-demo"
options={options_Bld}
defaultValue={options_Bld[0]}
/* onChange={(_, newValue) => {
setvaluebld(newValue)
}}*/
sx={{ mt: 2 }}
// value={selectedBldOption}
onChange={onbldChanged}
//disablePortal
/*classes={{
option: styles.option
}}*/
renderOption={(props, option) => {
const { label, indexvalue } = option;

return (
indexvalue % 2 == 0 ? 

<span {...props}   style={{ backgroundColor: "#add8e6",color: "#191970", fontFamily: 
'"Tajawal", sans-serif', 
fontSize: 18
,fontWeight:700 }}>

{(indexvalue  == -1 ?
<>
  {'- ' +label} 
   </>
  :
  <>
  {label} 
  </>
  )}
  

</span>
:

<span {...props}   style={{ backgroundColor: "#FFFFFF",color: "#191970", fontFamily: 
'"Tajawal", sans-serif', 
fontSize: 18
,fontWeight:700 }}>

{(indexvalue  == -1 ?
<>
  {'- ' +label} 
   </>
  :
  <>
  {label} 
  </>
  )}


</span>

);
}} 



//sx={{ width: 300 }}
renderInput={(params) => <Box display="flex" justifyContent="center">  <TextField fullWidth  {...params} label="البلديات"  /> </Box>  }
/>


</CacheProvider>

{/* Full name */ }
  

<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box sx={{ mt: 2 }}>

<Grid container >
       

        <Grid item xs={12}>
        
<TextField 
disabled={disableallfield}
fullWidth   
error={valuefullname.length<4? true: false}
helperText={valuefullname.length<4? "لا تترك خانة الإسم الكامل فارغة": ""}
value={valuefullname}
inputRef={reffullname}
onChange={(ev)=>{
  
    const value = ev.target.value;
    if (value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(value)) {
      return;
    }
  setvaluefullname(ev.target.value)
}}
sx={{
    
     
    '& .MuiFormHelperText-root': {
      fontFamily: '"Tajawal", sans-serif',
       fontSize:'14px',
       color: '#b31d3e',
       fontWeight:700
        
   },
    '& .MuiFormLabel-root': {
      fontFamily: '"Tajawal", sans-serif',
       fontSize:'16px',
       color: '#191970'
   },
    '& .MuiInputBase-input': {
       fontFamily: '"Tajawal", sans-serif',
        fontSize:'18px',
        color: '#191970',
        fontWeight:700
    },
    '& label.Mui-focused': {
      fontSize:'16px',
      color: '#b31d3e',
      fontWeight:700
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#add8e6',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        fontFamily: '"Tajawal", sans-serif',
        fontSize:'16px',
        borderColor: '#add8e6',
      borderWidth: "2px",
       
      },
      '&:hover fieldset': {
        borderColor: 'purple',
      },
      '&.Mui-focused fieldset': {
        
        borderColor: 'purple',
      },
    } 
  }}
  
  id="outlined-basic" label="الإسم الكامل" variant="outlined" />

   
</Grid>
      </Grid>

</Box>


</MuiThemeProvider>


</CacheProvider>



{/* phone01 */ }
<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box sx={{ mt: 2 }}>

<Grid container >
       

        <Grid item xs={12}>

        <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth variant="outlined"
       
       
       >

        <InputLabel id="demo-simple-select-label"
        sx={{ color: "#b31d3e", fontFamily: 
        '"Tajawal", sans-serif', 
        fontSize: 16
        ,fontWeight:700 }}
        >هاتف 01  (إجباري) </InputLabel>

 
        <Input  
        disabled={disableallfield}
        fullWidth 
        dir="ltr"  
        sx={{
             
             //direction:'ltr',
             
            '& .MuiFormHelperText-root': {
              fontFamily: '"Tajawal", sans-serif',
               fontSize:'14px',
               color: '#b31d3e',
               fontWeight:700
                
           },
            '& .MuiFormLabel-root': {
              fontFamily: '"Tajawal", sans-serif',
               fontSize:'18px',
               color: '#191970',
               dir: 'rtl',
                
           },
            '& .MuiInputBase-input': {
               fontFamily: '"Tajawal", sans-serif',
                fontSize:'22px',
                color: '#191970',
                fontWeight:700,
                //text-align: right,
                
                 

                          },
            '& label.Mui-focused': {
              fontSize:'16px',
              color: '#b31d3e',
              fontWeight:700
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: '#add8e6',
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                fontFamily: '"Tajawal", sans-serif',
                fontSize:'16px',
                borderColor: '#add8e6',
              borderWidth: "2px",
               
              },
              '&:hover fieldset': {
                borderColor: 'purple',
              },
              '&.Mui-focused fieldset': {
                
                borderColor: 'purple',
              },
            } 
          }}
  
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          value={valuesphone1.textmaskphone1}
          inputRef={refphone1}
          defaultValue="0"
          ///error={valuesphone1.textmaskphone1.length !=9 ? true:false}
          ///helperText={valuesphone1.textmaskphone1.length !=9 ? "أدخل رقم هاتف صحيح":""}
          onChange={handleChangephone1}
          name="textmaskphone1"
           id="formatted-text-mask-input"
          inputComponent={textmaskphone1Custom}
          />
          
          {valuesphone1.textmaskphone1.length !=10 ? <>
        
        <FormHelperText 
         sx={{ color: "#b31d3e", fontFamily: 
         '"Tajawal", sans-serif', 
         fontSize: 14
         ,fontWeight:700 }}
         error id="accountId-error">
          أدخل رقم هاتف صحيح
          </FormHelperText>
            
      
      </>: <></>}



          </FormControl>
          </Box>
</Grid>
 
</Grid>
 </Box>

</MuiThemeProvider>


</CacheProvider>







{/* phone02 */ }
<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box sx={{ mt: 2 }} >

<Grid container >
       

        <Grid item xs={12}>

        <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth variant="outlined"
       
       
       >

        <InputLabel id="demo-simple-select-label"
        sx={{ color: "#b31d3e", fontFamily: 
        '"Tajawal", sans-serif', 
        fontSize: 16,
        color:'green'
        ,fontWeight:700 }}
        > هاتف 02 (إختياري)</InputLabel>
 
        <Input  
        disabled={disableallfield}
        fullWidth 
        dir="ltr"  
        sx={{
            
             //direction:'ltr',
             
            '& .MuiFormHelperText-root': {
              fontFamily: '"Tajawal", sans-serif',
               fontSize:'14px',
               color: '#b31d3e',
               fontWeight:700
                
           },
            '& .MuiFormLabel-root': {
              fontFamily: '"Tajawal", sans-serif',
               fontSize:'18px',
               color: '#191970',
               dir: 'rtl',
                
           },
            '& .MuiInputBase-input': {
               fontFamily: '"Tajawal", sans-serif',
                fontSize:'22px',
                color: '#191970',
                fontWeight:700,
                //text-align: right,
                
                 

                          },
            '& label.Mui-focused': {
              fontSize:'16px',
              color: '#b31d3e',
              fontWeight:700
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: '#add8e6',
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                fontFamily: '"Tajawal", sans-serif',
                fontSize:'16px',
                borderColor: '#add8e6',
              borderWidth: "2px",
               
              },
              '&:hover fieldset': {
                borderColor: 'purple',
              },
              '&.Mui-focused fieldset': {
                
                borderColor: 'purple',
              },
            } 
          }}
  
          type="number"
          inputRef={refphone2}
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue="0"
          value={valuesphone2.textmaskphone2}
          onChange={handleChangephone2}
          name="textmaskphone2"
           id="formatted-text-mask-input"
          inputComponent={textmaskphone2Custom}
          />
          
          {valuesphone2.textmaskphone2.length !=10 && valuesphone2.textmaskphone2 !=0 && valuesphone2.textmaskphone2.length !=0 ? <>
        
        <FormHelperText 
         sx={{ color: "#b31d3e", fontFamily: 
         '"Tajawal", sans-serif', 
         fontSize: 14
         ,fontWeight:700 }}
         error id="accountId-error">
        أتركها فارغة أو أدخل رقم هاتف صحيح
          </FormHelperText>
            
      
      </>: <></>}

          </FormControl>
          </Box>
</Grid>
 
</Grid>
 </Box>

</MuiThemeProvider>


</CacheProvider>







{/* Datepicker */ }
<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box >

<Grid container >
       

        <Grid item xs={12}>

        <Box sx={{ minWidth: 120, mt: 2 }} >
      <FormControl fullWidth variant="outlined"
       
       
       >

       
 


<LocalizationProvider dateAdapter={AdapterDayjs}>

<MobileDatePicker
disabled={disableallfield}
fullWidth 
        dir="ltr"  
        

          label="تاريخ آخر تبرع"
          inputFormat="DD/MM/YYYY"
          value={valueDate}
          onChange={handleChangeDate}
          renderInput={(params) => <TextField
            sx={{
              
               //direction:'ltr',
               
              '& .MuiFormHelperText-root': {
                fontFamily: '"Tajawal", sans-serif',
                 fontSize:'14px',
                 color: '#b31d3e',
                 fontWeight:700
                  
             },
              '& .MuiFormLabel-root': {
                fontFamily: '"Tajawal", sans-serif',
                 fontSize:'18px',
                 color: '#191970',
                 dir: 'rtl',
                  
             },
              '& .MuiInputBase-input': {
                 fontFamily: '"Tajawal", sans-serif',
                  fontSize:'22px',
                  color: '#191970',
                  fontWeight:700,
                  //text-align: right,
                  
                   
  
                            },
              '& label.Mui-focused': {
                fontSize:'16px',
                color: '#b31d3e',
                fontWeight:700
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: '#add8e6',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  fontFamily: '"Tajawal", sans-serif',
                  fontSize:'16px',
                  borderColor: '#add8e6',
                borderWidth: "2px",
                 
                },
                '&:hover fieldset': {
                  borderColor: 'purple',
                },
                '&.Mui-focused fieldset': {
                  
                  borderColor: 'purple',
                },
              } 
            }}
  
            {...params} />}
        />


</LocalizationProvider>



</FormControl>
          </Box>
</Grid>
 
</Grid>
 </Box>

</MuiThemeProvider>


</CacheProvider>







{/* disable all info */ }
<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box >

<Grid container >
       

        <Grid item xs={12}>

        <Box sx={{ minWidth: 120, mt: 2 }} >
      <FormControl fullWidth variant="outlined"
       
       
       >

       
<FormGroup>
      <FormControlLabel control={<Switch
      checked={activeswitch}
      value="active"
      onClick={(e)=>{
         if(activeswitch==false){
          setdisableallfield(false);
         }else{
          if(valuefullname.length<4){
     
            reffullname.current.focus();
            
            return
          }
      
          if(valuesphone1.textmaskphone1.length !=10){
            refphone1.current.focus();
            return
          }
          
          if(valuesphone2.textmaskphone2.length !=10 && valuesphone2.textmaskphone2 !=0 && valuesphone2.textmaskphone2.length !=0){
            refphone2.current.focus();
            return
          }

          setdisableallfield(true);
         }

          setactiveswitch(!activeswitch);
         
      }}
      
      
       />} label={!activeswitch? <>
       <Typography
       sx={{ color: "#b31d3e", fontFamily: 
       '"Tajawal", sans-serif', 
       fontSize: 16
       ,fontWeight:700 }}>
        هذه المعلومات لن تعرض على التطبيق و الموقع الإلكتروني.
        </Typography>
        
        
        
         </>:<>
         <Typography
      sx={{ color: "#b31d3e", fontFamily: 
      '"Tajawal", sans-serif', 
      fontSize: 16,
      color:'green'
      ,fontWeight:700 }}>

       
         هذه المعلومات سوف تعرض على التطبيق و الموقع الإلكتروني. 

         </Typography>
         
         </>} />
    </FormGroup>

    


<LocalizationProvider dateAdapter={AdapterDayjs}>




</LocalizationProvider>



</FormControl>
          </Box>
</Grid>
 
</Grid>
 </Box>

</MuiThemeProvider>


</CacheProvider>






{/* Submit Button */ }
<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box sx={{ mt: 2 }}>

<Grid container >
       

        <Grid item xs={12}>

        <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth variant="outlined"
       
       
       > 

      <div>
  <Button 
  onClick={(e)=>{
    e.preventDefault();

 

    if(valuefullname.length<4){
     
      reffullname.current.focus();
      
      return
    }

    if(valuesphone1.textmaskphone1.length !=10){
      refphone1.current.focus();
      return
    }
    
    if(valuesphone2.textmaskphone2.length !=10 && valuesphone2.textmaskphone2 !=0 && valuesphone2.textmaskphone2.length !=0){
      refphone2.current.focus();
      return
    }
    
    onsubmitclicked(e);

 

 

     
  }}
  
  variant="contained"
   endIcon={<SaveIcon sx={{ml:'5px', dir:'rtl'}} />}>
    <Typography  sx={{ color: "#FFFFFF", fontFamily: 
           '"Tajawal", sans-serif', 
           fontSize: 22
           ,fontWeight:900 }}>
            حفظ التغييرات    
    </Typography>
        
      </Button>
      </div>


      </FormControl>
          </Box>
</Grid>
 
</Grid>
 </Box>

</MuiThemeProvider>


</CacheProvider>





 </div>

 </div>


    
    </Grid>

    </Container>

</>
}


<Footer />
        












       
<Dialog
       PaperProps={{
        style: {
          backgroundColor: '#add8e6',
          boxShadow: 'none',
        },
      }}
       //fullScreen={mediumScreen}
        open={OpenDialog}
        onClose={handleClickCloseDialog}
        aria-labelledby="responsive-dialog-title"
      >

<Card  style={{}} sx={{ maxWidth: 345  }}>

  {/* <p className="h5 font-weight-bold text-primary" id={showhidvalueteldate}><FaCalendarAlt/> تاريخ آخر تبرع: {props.daydon+'/'+props.monthdon+'/'+props.yeardon}</p>
 */ }
<CardHeader
        avatar={
          
            <CheckCircleIcon sx={{color:'#4BB543',width: 50, height: 50,ml:2}}></CheckCircleIcon>
         
        }
        action={
         
           
        <IconButton  onClick={handleClickCloseDialog} aria-label="settings" sx={{ ml:-1,mt:-1  }}>
                    
        <CloseIcon  /> 

        </IconButton>
        } 
        title={
          <>
          <Box sx={{ ml:2, mt:0, display: 'flex', alignItems: 'flex-end', justifyContent: 'center'}}>

          <Typography sx={{ color:'#191970', fontFamily: '"Tajawal", sans-serif', fontSize: 16,fontWeight:900}} >
          تم حفظ معلوماتك بنجاح</Typography>
            </Box>
            
            </>
        
        }
        subheader=""
      />


       


</Card>

      </Dialog>
         



     



{/* 
        <section className="hero has-background-grey-light is-fullheight is-fullwidth">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-4-desktop">
                            <form onSubmit={Register} className="box">
                                <p className="has-text-centered">{msg}</p>
                                <div className="field mt-5">
                                    <label className="label">Name</label>
                                    <div className="controls">
                                        <input type="text" className="input" placeholder="Name"
                                            value={name} onChange={(e) => setName(e.target.value)} />
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <label className="label">Email</label>
                                    <div className="controls">
                                        <input type="text" className="input" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <label className="label">Password</label>
                                    <div className="controls">
                                        <input type="password" className="input" placeholder="******" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <label className="label">Confirm Password</label>
                                    <div className="controls">
                                        <input type="password" className="input" placeholder="******" value={confPassword} onChange={(e) => setConfPassword(e.target.value)} />
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <button className="button is-success is-fullwidth">Register</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
*/ }

</>:<></>}

        </>
    )
}

export default UpdateRegisterDon
