import React, { useState,useEffect } from "react";

import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
 

import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import CloseIcon from '@mui/icons-material/Close';
import iconbarleft from '../iconimg/iconbarleft.png';

import { Link } from "react-router-dom";
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import bloodlogin from '../iconimg/bloodlogin.png';
import logout from '../iconimg/logout.png';



import {
  Button,
  Grid
} from "@mui/material";

import Avatar from '@mui/material/Avatar';
import appico from '../iconimg/appico.png';
import downapp from '../iconimg/downapp.png';

import Footer from '../containers/Footer';

import './amirifont.css';
import pharmacy from '../iconimg/pharmacy.png';
import blooddon from '../iconimg/blooddon.png';
import HomeIcon from '@mui/icons-material/Home';
import moresearch from '../iconimg/moresearch.png';
import homeiconblue from '../iconimg/homeiconblue.png';
import insertnew from '../iconimg/insertnew.png';
import addblood from '../iconimg/addblood.png';


 const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  }),
);


const themeRoboto = createTheme({  
  typography: {
    fontFamily: 
      '"Roboto Slab", serif', 
      fontSize: 10       

  },
});


const themeChanga = createTheme({  
  typography: {
    fontFamily: 
      '"Changa", sans-serif', 
      fontSize: 10,
       

  },
});



const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

export default function PersistentDrawerRight() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const themeChangaHeader = createTheme({  
    typography: {
      fontFamily: 
        '"Tajawal", sans-serif', 
        fontSize: 16,
        fontWeight:900
         

    },
  });

  const themeChanga = createTheme({  
    typography: {
      fontFamily: 
        '"Tajawal", sans-serif', 
        fontSize: 14,
        fontWeight:900
         

    },
  });

  const [hidepage, sethidepage] = useState(true);
  useEffect(() => {

    if(sessionStorage.getItem("ID")==null || sessionStorage.getItem("ID")==undefined || sessionStorage.getItem("ID")==""){
      sethidepage(true)
    }else{
      sethidepage(false)
    }
 
  }, []);


  return (
    <Box sx={{ display: 'flex'}}>
      <CssBaseline />
      <AppBar  sx={{ background: "#063970" }} position="fixed" open={open}>
        <Toolbar >
        <IconButton
          
          color="inherit"
          aria-label="open drawer"
          edge="end"
          onClick={handleDrawerOpen}
          sx={{ ...(open && { display: 'none' }) }}
        >
          <MenuIcon  />
        </IconButton>
          <Typography variant="h6" noWrap   
          theme={themeChangaHeader} sx={{ flexGrow: 1,fontSize:18,mr:1,mt:0
            , fontFamily: 
            '"Tajawal", sans-serif', 
            fontSize: 20
            ,fontWeight:700 }} component="div">
            أطباء الجزائر
          </Typography>
           
          <a style={{ textDecoration: 'none'}}  href="https://play.google.com/store/apps/details?id=dz.sebrou.docts_guide.app">
              <Avatar
        alt="تطبيق أطباء الجزائر"
        src={appico}
        sx={{ width: 40, height: 40 }}
      />
          </a> 
       
        </Toolbar>
      </AppBar>
      <Main open={open}>
        <DrawerHeader />
      </Main>
      <Drawer
        sx={{
          
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader dir="ltr">
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <CloseIcon  /> : <CloseIcon />}
          </IconButton>
        </DrawerHeader>
        
        <List  >

        <ListItem component={Link} to="/" alignItems="flex-start"  onClick={handleDrawerClose} >
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src={homeiconblue}
           />
        </ListItemAvatar>
        <ListItemText

           primary={<Typography
            theme={themeChanga}
            sx={{  textAlign: 'right',mr:0  }}
             variant="h6" type="body2" style={{ color: '#063970' }}>الصفحة الرئيسية</Typography>}

          secondary={
            <React.Fragment>
              <Typography
              theme={themeRoboto}
              
               variant="h6"
               align="left"
                sx={{ display: 'inline' }}
                component="span"
               // variant="body2"
                color='#063970'
              >
                Page d'accueil
              </Typography >
               
            </React.Fragment>
          }
        />
      </ListItem>

      <Divider variant="inset" component="li" style={{width:'100%'}} />


        <ListItem component={Link} to="/Doc" alignItems="flex-start"  onClick={handleDrawerClose}
        style={{  backgroundColor:'#80808017' }}  >
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src={iconbarleft}
           />
        </ListItemAvatar>
        <ListItemText

           primary={<Typography
            theme={themeChanga}
            sx={{  textAlign: 'right',mr:0  }}
             variant="h6" type="body2" style={{ color: '#191970' }}>أطباء، صيدليات..</Typography>}

          secondary={
            <React.Fragment>
              <Typography
              theme={themeRoboto}
              
               variant="h6"
               align="left"
                sx={{ display: 'inline' }}
                component="span"
               // variant="body2"
                color='#063970'
              >
                ..Médecins, pharmacies

              </Typography >
               
            </React.Fragment>
          }
        />
      </ListItem>

      <Divider variant="inset" component="li" style={{width:'100%'}} />
      
      <ListItem  
        
        component={Link} to="/RegisterDoc" alignItems="flex-start" onClick={handleDrawerClose}

      style={{  justifyContent: "center", display: "flex" }} >

      <Grid   
        container
        spacing={0}
        direction="row"
        alignItems="center"
        style={{width: "fit-content"}}
        justifyContent="center">

        
        <ListItemAvatar   style={{ justifyContent: "center", display: "flex" }}>
          <Avatar alt="Remy Sharp" src={insertnew}
          sx={{ width: 30, height: 30,  }} />
        </ListItemAvatar>
        <ListItemText
        
        
           primary={<Typography
            theme={themeChanga}
            sx={{  textAlign: 'center',mr:0  }}
             variant="h6" type="body2" style={{ color: '#063970' }}>إضافة معلومات جديدة</Typography>}
        />

 
</Grid>
      </ListItem>

 


      <Divider variant="inset" component="li" style={{width:'100%'}} />
      <ListItem component={Link} to="/Don" alignItems="flex-start" onClick={handleDrawerClose} 
       style={{  backgroundColor:'#80808017' }}
       >
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src={blooddon}
           />
        </ListItemAvatar>
        <ListItemText
        
           primary={<Typography
            theme={themeChanga}
            sx={{  textAlign: 'right',mr:0  }}
             variant="h6" type="body2" style={{ color: '#063970' }}> متبرعين بالدم </Typography>}

          secondary={
            <React.Fragment>
              <Typography
              theme={themeRoboto}
              
               variant="h6"
               align="left"
                sx={{ display: 'inline' }}
                component="span"
               // variant="body2"
                color='#063970'
              >
                Banque de sang
              </Typography >
               
            </React.Fragment>
          }
        />
      </ListItem>

{hidepage? <>    
      <Divider variant="inset" component="li" style={{width:'100%'}} />
      <div style={{display: 'flex', flexDirection: 'row'}}>
      
      <ListItem  
        
        component={Link} to="/RegisterDon" alignItems="flex-start" onClick={handleDrawerClose}

      style={{  justifyContent: "center", display: "flex" }} >

      <Grid   
        container
        spacing={0}
        direction="row"
        alignItems="center"
        style={{width: "fit-content"}}
        justifyContent="center">

        
        <ListItemAvatar   style={{ justifyContent: "center", display: "flex" }}>
          <Avatar alt="Remy Sharp" src={addblood}
          sx={{ width: 30, height: 30,  }} />
        </ListItemAvatar>
        <ListItemText
        
        
           primary={<Typography
            theme={themeChanga}
            sx={{  textAlign: 'center',mr:0  }}
             variant="h6" type="body2" style={{ color: '#063970' }}> متبرع جديد </Typography>}
        />

 
</Grid>
      </ListItem>

 

      <ListItem  
        
        component={Link} to="/loginDon" alignItems="flex-start" onClick={handleDrawerClose}

      style={{  justifyContent: "center", display: "flex",
      borderRight: '0.1em solid #00000029', padding: '0.5em' }} >
 
      <Grid   
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        style={{width: "fit-content"}}
        >

        
        <ListItemAvatar  style={{ justifyContent: "center", display: "flex" }} >
          <Avatar alt="Remy Sharp" src={bloodlogin} 
          sx={{ width: 30, height: 30,  }} />
        </ListItemAvatar>
        <ListItemText
        
        
           primary={<Typography
            theme={themeChanga}
            sx={{  textAlign: 'center',mr:0 }}
             variant="h6" type="body2" style={{ color: '#063970' }}> دخول متبرع  </Typography>}
        />

 
</Grid>
      </ListItem>


 </div>

 </>:<>


 <Divider variant="inset" component="li" style={{width:'100%'}} />
      <div style={{display: 'flex', flexDirection: 'row'}}>
      
      <ListItem  
        
        component={Link} to="/UpdateRegisterDon" alignItems="flex-start" onClick={handleDrawerClose}

      style={{  justifyContent: "center", display: "flex" }} >

      <Grid   
        container
        spacing={0}
        direction="row"
        alignItems="center"
        style={{width: "fit-content"}}
        justifyContent="center">

        
        <ListItemAvatar   style={{ justifyContent: "center", display: "flex" }}>
          <Avatar alt="Remy Sharp" src={addblood}
          sx={{ width: 30, height: 30,  }} />
        </ListItemAvatar>
        <ListItemText
        
        
           primary={<Typography
            theme={themeChanga}
            sx={{  textAlign: 'center',mr:0  }}
             variant="h6" type="body2" style={{ color: '#063970' }}> معلوماتك </Typography>}
        />

 
</Grid>
      </ListItem>

 

      <ListItem  
        
        component={Link} to="/loginDon" alignItems="flex-start" onClick={handleDrawerClose}

      style={{  justifyContent: "center", display: "flex",
      borderRight: '0.1em solid #00000029', padding: '0.5em' }} >
 
      <Grid   
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        style={{width: "fit-content"}}
        >

        
        <ListItemAvatar  style={{ justifyContent: "center", display: "flex" }} >
          <Avatar alt="Remy Sharp" src={logout} 
          sx={{ width: 30, height: 30,  }} />
        </ListItemAvatar>
        <ListItemText
        
        
           primary={<Typography
            theme={themeChanga}
            sx={{  textAlign: 'center',mr:0 }}
             variant="h6" type="body2" style={{ color: '#063970' }}> خروج </Typography>}
        />

 
</Grid>
      </ListItem>


 </div>



 </>} 







      {/* <ListItem  alignItems="flex-start"   >
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src={pharmacy}
           />
        </ListItemAvatar>
        <ListItemText
        
           primary={<Typography
            theme={themeChanga}
            sx={{  textAlign: 'right',mr:0  }}
             variant="h6" type="body2" style={{ color: '#063970' }}> صيدليات </Typography>}

          secondary={
            <React.Fragment>
              <Typography
              theme={themeRoboto}
              
               variant="h6"
               align="left"
                sx={{ display: 'inline' }}
                component="span"
               // variant="body2"
                color='#063970'
              >
                Pharmacies
              </Typography >
               
            </React.Fragment>
          }
        />
      </ListItem>
        */}



 {/* 
<Divider variant="inset" component="li" style={{width:'100%'}} />
      <ListItem component={Link} to="/More" alignItems="flex-start"  onClick={handleDrawerClose}
      style={{  backgroundColor:'#80808017' }}  >
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src={moresearch}
          />
        </ListItemAvatar>
        <ListItemText
        
           primary={<Typography
            theme={themeChanga}
            sx={{  textAlign: 'right',mr:0  }}
             variant="h6" type="body2" style={{ color: '#063970' }}> شاهد المزيد ... </Typography>}

          secondary={
            <React.Fragment>
              <Typography
              theme={themeRoboto}
              
               variant="h6"
               align="left"
                sx={{ display: 'inline' }}
                component="span"
               // variant="body2"
                color='#063970'
              >
                ...Voir plus
              </Typography >
               
            </React.Fragment>
          }
        />
      </ListItem>
*/}


      <Divider variant="inset" component="li" style={{width:'100%'}} />
      <a style={{ textDecoration: 'none'}}  
        href="https://play.google.com/store/apps/details?id=dz.sebrou.docts_guide.app">
          
      <ListItem  alignItems="flex-start"  onClick={handleDrawerClose} >
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src={downapp} sx={{p:'2px'}}
          />
        </ListItemAvatar>
        <ListItemText
        
           primary={<Typography
            theme={themeChanga}
            sx={{  textAlign: 'right',mr:0  }}
             variant="h6" type="body2" style={{ color: '#063970' }}> تحميل التطبيق </Typography>}

          secondary={
            <React.Fragment>
              <Typography
              theme={themeRoboto}
              
               variant="h6"
               align="left"
                sx={{ display: 'inline' }}
                component="span"
               // variant="body2"
                color='#063970'
              >
                Télécharger L'app
              </Typography >
               
            </React.Fragment>
          }
        />
      </ListItem>
           </a>
                

      {/* 
      <Divider variant="inset" component="li" />
      <ListItem  alignItems="flex-start"   >
        <ListItemAvatar>
 
          <Avatar alt="Remy Sharp" src={insertnew}
          ///  sx={{ width: '3rem', height: '3rem'  }}  
       
        
          />
         </ListItemAvatar>
        <ListItemText
        
           primary={<Typography
            theme={themeChanga}
            sx={{  textAlign: 'right',mr:0  }}
             variant="h6" type="body2" style={{ color: '#063970' }}> بيانات جديدة </Typography>}

          secondary={
            <React.Fragment>
              <Typography
              theme={themeRoboto}
              
               variant="h6"
               align="left"
                sx={{ display: 'inline' }}
                component="span"
               // variant="body2"
                color='#063970'
              >
                Nouvelles données
              </Typography >
               
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem  alignItems="flex-start"   >
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src={addblood}
          />
        </ListItemAvatar>
        <ListItemText
        
           primary={<Typography
            theme={themeChanga}
            sx={{  textAlign: 'right',mr:0  }}
             variant="h6" type="body2" style={{ color: '#063970' }}> متبرع جديد </Typography>}

          secondary={
            <React.Fragment>
              <Typography
              theme={themeRoboto}
              
               variant="h6"
               align="left"
                sx={{ display: 'inline' }}
                component="span"
               // variant="body2"
                color='#063970'
              >
                Nouveau donneur
              </Typography >
               
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
*/ }
         
          {/*
          {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
            <ListItem  key={text} disablePadding>
              <ListItemButton dir="rtl">
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> :  
                  
                  <Avatar alt="Remy Sharp" src={iconbarleft} />
                    
          }
                </ListItemIcon>
                <ListItemText     >
                <Typography textAlign="right">
                {text}
                 </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
          */}
        </List>
        {/* 
        <Divider />
        <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        */}
      </Drawer>

 

    </Box>




  );
}
