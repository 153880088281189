import React, { useState,useEffect,useRef }  from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate,useLocation } from 'react-router-dom';
import appico from '../iconimg/appico.png';
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import { MuiThemeProvider } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import { makeStyles,withStyles } from "@material-ui/core/styles";
import Footer from '../containers/Footer';

import ReplayIcon from '@mui/icons-material/Replay';
import FormControl from '@mui/material/FormControl';
import isEmail from 'validator/lib/isEmail';
import InputAdornment from "@mui/material/InputAdornment";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import { IMaskInput } from 'react-imask';
import SearchIcon from '@mui/icons-material/Search';
import {isMobile,browserName} from 'react-device-detect';
import Header from '../containers/Header';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Card from 'react-bootstrap/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CloseIcon from '@mui/icons-material/Close';
import '../containers/amirifont.css'
import { FaMapMarkerAlt,FaPhoneAlt,FaMobileAlt,FaMapSigns, FaEnvelopeOpenText,FaMapMarkedAlt,FaMapPin,FaInfoCircle } from 'react-icons/fa';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import InfoIcon from '@mui/icons-material/Info';


import axios from 'axios';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://AtibaDz.com/">
        Abdelaziz sebrou
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

let sendemailbyemailoneortwo='https://www.doctorsdz.machrou3.com/user/sendemailbyemailone/';

var md5 = require('md5');
let codeemail = (Math.floor(1000 + Math.random() * 9000));

 
  // Create rtl cache
  const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
  });


  const defaultColor = "#ff0000";
  const hoverColor = "#0000ff";
  const focusColor = "#00ff00";

  const themetextfieldnew = createTheme({
    overrides: {
      MuiOutlinedInput: {
        root: {
          "&:hover $notchedOutline": {
            borderColor: hoverColor
          },
          "&$focused $notchedOutline": {
            borderColor: focusColor
          }
        },
        notchedOutline: {
          borderColor: defaultColor
        }
      }
    },
    fieldset: {
      fontFamily: 
        '"Changa", sans-serif', 
        fontSize: 45,
         

    } 
  });



  
const textmaskcodeemailCustom = React.forwardRef(function textmaskcodeemailCustom(props, ref) {
  const { onChange, ...other } = props;

 return (
      <>       
      
       <CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>

<Box >

<Grid container >
     

      <Grid item xs={12}>

    <IMaskInput
    
      {...other}
      mask="0000"
      ///value=""
      unmask={false} // true|false|'typed'
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
      // input props also available
      placeholder='أدخل رمز التحقق هنا'   
        />


</Grid>
    </Grid>

</Box>
</MuiThemeProvider>


</CacheProvider>


</>


  );
});







export default function ResetPwDon() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState('');
   // const history = useHistory();
    const navigate = useNavigate();

    const refemail = useRef(null);
    const refcodeemail = useRef(null);
    const refsearchbtn = useRef(null);
    const [name, setName] = useState('');
    const refform = useRef(null);
    
    

    const [valueemail, setValueemail] = useState('');
    const [isValidemail, setIsValidemail] = useState(false);
    const [dirty, setDirty] = useState(false);
    const [emailverificated, setemailverificated] = React.useState(false);

    const [emailsended, setemailsended] = React.useState(false);
    const [errorsendingemail, seterrorsendingemail] = React.useState(false);
    const refpassword1 = useRef(null);
    const refpassword2 = useRef(null);
    const ismobref = useRef(null);

    const [OpenDialoglink, setOpenDialoglink] = useState(false);

    const handleClickOpenDialoglink = () => {
      setOpenDialoglink(true);
      };
      
      
      const handleClickCloseDialoglink = () => {
        if(ismobref.current){
          ismobref.current.click();
        }
      setOpenDialoglink(false);
      };




    const [FailedAuth, setFailedAuth] = useState(false);


    useEffect(() => {
      if(emailsended && !emailverificated){

        if(refsearchbtn.current){
          //alert('click')
          refsearchbtn.current.click();
        }

      }    
     
   }, [emailsended]);


/*
   useEffect(() => {
    alert('test'+isValidemail)
    if(( codeemailsent ||  emailsended  )){
      
     
    }    
   
 }, [codeemailsent]);*/



   
 useEffect(() => {

  
  if(isEmail(valueemail)) {
    setIsValidemail(true);  
    console.log(valueemail+"   yes email");            
 } else {
    setIsValidemail(false); 
    console.log(valueemail+"   not email");                         
 }

  
}, [valueemail]);


   const hostn=window.location.hostname;
   const full = window.location.protocol + '//' + window.location.host;
   
   const ismoblinkred='intent:'+full+'/ResetPwDon#Intent;package=com.android.chrome;end';
   const [valuelink, setvaluelink] = useState(ismoblinkred);
   
   function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }


   let query = useQuery();
 const [codeemailsent, setcodeemailsent] = useState(false);
 const [codeemailforchecking, setcodeemailforchecking] = useState(md5(codeemail));

 
   useEffect(() => {


    const valueemail_get = query.get('valueemail');
    if(valueemail_get !=null){
      //alert('email exist'+valueemail_get)
      setValueemail(valueemail_get);
    }
    
    

    const codeemailsent_get = query.get('codeemailsent');
     
    const codeemail_get = query.get('codeemailcrypt');
     
    if(codeemail_get !=null && codeemail_get.length==32 && codeemailsent_get =="true" ){
      
      setcodeemailforchecking(codeemail_get);
      //alert(codeemailforchecking)
      setcodeemailsent(true)
      
    }else{
      setcodeemailforchecking(md5(codeemail));
      setcodeemailsent(false)
    }

     
    
    
       
    if (isMobile && (browserName !=="Chrome" || browserName=="Facebook" || browserName=="" || browserName==undefined || browserName=="undefined" )) {
     if(ismobref.current){
        ismobref.current.click();
        handleClickOpenDialoglink();
        
       
 }
   }

     
   }, []);




/*
   useEffect(() => {
     
     window.addEventListener("beforeunload", alertUser);
     return () => {
       window.removeEventListener("beforeunload", alertUser);
     };
   }, []);

   const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

    
   */




    




    const [valuesPassword, setValuesPassword] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
      });

      const handleChangePassword = (prop) => (event) => {
        setValuesPassword({ ...valuesPassword, [prop]: event.target.value });
      };
    
      const handleClickShowPassword = () => {
        setValuesPassword({
          ...valuesPassword,
          showPassword: !valuesPassword.showPassword,
        });
      };
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

      
      const handleChangecodeemail = (event) => {
        
        if(md5(Number(event.target.value)) == codeemailforchecking){
          ///alert('verificated succussfully');
          setemailverificated(true);
        }
   
        setValuescodeemail({
          ...valuescodeemail,
          [event.target.name]: event.target.value,
        });
  
        
      };

      const handleClickcodeemail = (event) => {
        event.preventDefault();
      };


      const [valuesPasswordRe, setValuesPasswordRe] = React.useState({
        amount: '',
        PasswordRe: '',
        weight: '',
        weightRange: '',
        showPasswordRe: false,
      });
    
    
      const handleChangePasswordRe = (prop) => (event) => {
        setValuesPasswordRe({ ...valuesPasswordRe, [prop]: event.target.value });
      };
    
      const handleClickShowPasswordRe = () => {
        setValuesPasswordRe({
          ...valuesPasswordRe,
          showPasswordRe: !valuesPasswordRe.showPasswordRe,
        });
      };
    
      const handleMouseDownPasswordRe = (event) => {
        event.preventDefault();
      };

      const [OpenDialog, setOpenDialog] = useState(false);


      const handleClickOpenDialog = () => {
        setOpenDialog(true);
        };
        
        
        const handleClickCloseDialog = () => {
        setOpenDialog(false);
        };
    


      const onsubmitclicked = async (e) => {

        e.preventDefault();


        let axiosConfig = {
            headers: {
                //'Content-Type' : 'application/json; charset=UTF-8',
                ///application/x-www-form-urlencoded
                ///'Content-Type' : 'application/x-www-form-urlencoded',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Accept': 'Token',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "POST",
                "Access-Control-Allow-Headers": "*",
                "Access-Control-Max-Age": "86400",
  
             }
          };
  
       
        try {
  
            const params = new URLSearchParams();
           
            params.append('Email_doct', valueemail);   
            params.append('password', valuesPassword.password);      
  
            await axios.post('https://www.doctorsdz.machrou3.com/user/resetpwdon/'
            , params,axiosConfig)
            .then(response => {
                    
              if(response.data.savedsucces){
                navigate('/LoginDon');
               /// handleClickOpenDialog();
                
                }else{
                    alert('لم يتم تسجيل المعلومات حاول مرة')
                }
                   ///alert('response '+response.data.Namef)
                 //handleClickOpenDialog();

                console.log(response)
            })
            //navigate('/dashboard');
  
        } catch (error) {
            
            if (error.response) {
                
                console.log(error.response)
                return 
            }
  
        }
  
  
  
       
    }


      const ResendEmailClick = async (e) => {

        e.preventDefault();

        /// alert(codeemail+' now')
          setemailverificated(false);
          setemailsended(false);
          seterrorsendingemail(false)
          setDirty(false)

          if(sendemailbyemailoneortwo =='https://www.doctorsdz.machrou3.com/user/sendemailbyemailone/'){
            sendemailbyemailoneortwo='https://www.doctorsdz.machrou3.com/user/sendemailbyemailtwo/';
          }
          else{
            sendemailbyemailoneortwo='https://www.doctorsdz.machrou3.com/user/sendemailbyemailone/';
          }


        let axiosConfig = {
            headers: {
                //'Content-Type' : 'application/json; charset=UTF-8',
                ///application/x-www-form-urlencoded
                ///'Content-Type' : 'application/x-www-form-urlencoded',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Accept': 'Token',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "POST",
                "Access-Control-Allow-Headers": "*",
                "Access-Control-Max-Age": "86400",
 
             }
          };

       
        try {

         /// alert('code sent:'+codeemail+' md5 is:'+md5(codeemail))
            const params = new URLSearchParams();
            params.append('codeemail', codeemail);
            params.append('emailtosend', valueemail);
           
            await axios.post(sendemailbyemailoneortwo
            , params,axiosConfig)
            .then(response => {
                  
                 if(codeemail == response.data.codeemail){
                  //alert('email sent')
                  refsearchbtn.current.click();
                  setemailsended(true);
                  //alert('resp '+response.data.emailtosend)
                  

                 }
                  
                console.log(response)
            })
            //navigate('/dashboard');
 
        } catch (error) {
            
            if (error.response) {
                seterrorsendingemail(true)
                console.log(error.response)
                return 
            }

        }

 

       
    }


  




/*
const onemailblur = event => {
  const val = event.target.value;   
   
  if(isValidemail){
  setDirty(true);
  }

   
    
 }

 useEffect(() => {
  console.log('diry changed='+dirty)
  if(dirty && isValidemail){
     ResendEmailClick();  
  }
  
}, [dirty]);

*/
 


useEffect(() => {
  console.log('isValidemail '+isValidemail+ 'emailverificated ' +emailverificated + 'emailsended '+emailsended )
  setemailsended(false);
 
}, [isValidemail]);


const handleChangeemail = event => {
    const val = event.target.value;                
         
    
         
    setValueemail(val); 
    
    
    /////handleChangeemail(val, isValidemail);
 }

 const [valuescodeemail, setValuescodeemail] = React.useState({
  textmaskcodeemail: '',
  numberformat: '1320',
});





    const Auth = async (e) => {

      e.preventDefault();

        let axiosConfig = {
            headers: {
                //'Content-Type' : 'application/json; charset=UTF-8',
                ///application/x-www-form-urlencoded
                ///'Content-Type' : 'application/x-www-form-urlencoded',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Accept': 'Token',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "POST",
                "Access-Control-Allow-Headers": "*",
                "Access-Control-Max-Age": "86400",
 
                
             }
          };

         
        try {


            const params = new URLSearchParams();
            params.append('Email_doct', valueemail);
            params.append('password', valuesPassword.password);
           

            await axios.post('https://www.doctorsdz.machrou3.com/user/checkemaildonifexist/'
            , params,axiosConfig)
            .then(response => {
                 
                console.log(response.data)
                ///checklogindonexist
                //alert((response.data.checklogindonexist))
                if(response.data.checkemaildonifexistv){
                    setFailedAuth(false)
                       ResendEmailClick(e); 
                    
                    
                }else{
                    setFailedAuth(true)
                }
            })
            
 
        } catch (error) {
            
            if (error.response) {
                console.log(error.response)
                return 
            }

        }

 

    }


    const handleSubmit = (e) => {
      //alert('valueemail');
      e.preventDefault();

      console.log(`Form submitted, ${name}`);    

  }
  /*
  const handleSubmit = (event) => {
    
    event.preventDefault();
     alert('valueemail');
    if(!isEmail(valueemail)){
        refemail.current.focus();
        return;
    }  
     
    
    
        
    //

  };*/




  
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
        // Default transform is "translate(14px, 20px) scale(1)""
        // This lines up the label with the initial cursor position in the input
        // after changing its padding-left.
        transform: "translate(34px, 20px) scale(1);",
        fontFamily: 
        '"Changa", sans-serif',
              fontSize: 25,
              direction: "rtl"
      }
    },
    inputRoot: {
      
      color: "#191970",

      
      // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        fontFamily: '"Tajawal", sans-serif', 
        fontWeight:700,
        color: "#191970",
        // Default left padding is 6px
        paddingLeft: 26
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "#add8e6"
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "red"
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "purple"
      }
    }
  }));

  const classes = useStyles();
  



  return (
    <>
    
    <a style={{ display:'none' }} href={valuelink}
 
 ref={ismobref}
 
 target="_blank"></a>

<Header />

 



<form inputRef={refform}   >

  
   




    <Container component="main" maxWidth="md">
 
    <Grid
    container
    spacing={0}
    direction="column"
   justifyContent="flex-start"
   alignItems="center"
    style={{ minHeight: '100vh' }}
    >
    
    <div className="container" style={{marginTop:'-30px'}}>
               
               <div className="row text-center">

              
           

        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, width:50, height:50, border: '2px solid #448a9a' }} src={appico}>
             
          </Avatar>
          <Typography 
          sx={{ color: "#00000", fontFamily: 
          '"Tajawal", sans-serif' 
          ,fontWeight:900,
          fontSize:16,color: "#191970", }}
          >
            إستعادة كلمة المرور <br></br>
            (متبرع بالدم)
          </Typography>
          <Box component="form"  noValidate sx={{ mt: 1 }}>







{/* Email */ }
<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box sx={{ mt: 2 }}>
                  

<Grid container >
       

        <Grid item xs={12}>

        <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth variant="outlined"
       
       
       >

                <TextField    
                
                sx={{
                 
                   //direction:'ltr',
                   
                  '& .MuiFormHelperText-root': {
                    fontFamily: '"Tajawal", sans-serif',
                     fontSize:'14px',
                     color: '#b31d3e',
                     fontWeight:700
                      
                 },
                  '& .MuiFormLabel-root': {
                    fontFamily: '"Tajawal", sans-serif',
                     fontSize:'18px',
                     color: '#191970',
                     dir: 'rtl',
                      
                 },
                  '& .MuiInputBase-input': {
                     fontFamily: '"Tajawal", sans-serif',
                      fontSize:'22px',
                      color: '#191970',
                      fontWeight:700,
                      //text-align: right,
                      
                       
      
                                },
                  '& label.Mui-focused': {
                    fontSize:'16px',
                    color: '#b31d3e',
                    fontWeight:700
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#add8e6',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize:'16px',
                      borderColor: '#add8e6',
                    borderWidth: "2px",
                     
                    },
                    '&:hover fieldset': {
                      borderColor: 'purple',
                    },
                    '&.Mui-focused fieldset': {
                      
                      borderColor: 'purple',
                    },
                  } 
                }}

                      inputRef={refemail}
                     error={isValidemail === false}  
                     label="البريد الإلكتروني"
                     helperText={isValidemail === false ? "أدخل عنوان بريد إلكتروني صالح": <>

                     
                      
                     {(isValidemail === true && emailverificated === false && emailsended === true) ? 
                      "تم إرسال رمز التحقق إلى بريدك الإلكتروني": 
                      
                      <>
                       
                       {emailverificated? "تم التحقق من بريدك الإلكتروني بنجاح" : <> </>
                       }

                      </>}
                     
                       

                      </>}  

                    ///onBlur={(e) => onemailblur(e)}
                    onKeyPress={(ev) => {
                      //console.log(`Pressed keyCode ${ev.key}`);
                     if (ev.key === 'Enter') {
                      if(isValidemail){
                        Auth(ev);
                      }
                       
                       ev.target.blur();///to hide keyboard
                       console.log("enter")
                       // Do code here
                       //console.log(JSON.stringify(ev.target.value));
                       //this.onSearchClickbtn();
                       ev.preventDefault();
                     }
                   }}

                  ///  id={props.fieldName}                    
                   /// label={props.label}
                  //  name={props.fieldName}                    
                    variant="outlined" 
                    size={'small'}
                   // helperText={props.helperText}
                    value={valueemail}
                    name="valueemail"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => handleChangeemail(e)}

                    InputProps={{
                      endAdornment: (
                        <InputAdornment>


                            {emailverificated? <>
                              <CheckCircleIcon sx={{ color: "green" }} />
                              </>: <>
                              
                              {(isValidemail==true && (emailsended || errorsendingemail)) ? <>
                              
                                <IconButton 
                          onClick={(e) => {
                            e.preventDefault();
                            Auth(e);
                             
                          }}
                           
          
                          >
                          <ReplayIcon sx={{ color: "#191970" }} />  
                          </IconButton>
                              
                              </>: <></> }
                              
                              
                              
                              </>}
                          

                        </InputAdornment>
                      )
                    }}
          

                    
                     
                    disabled={emailverificated}


                />
                
            </FormControl>


            </Box>
</Grid>
 
</Grid>
 </Box>

</MuiThemeProvider>


</CacheProvider>




{((isValidemail === true)  && codeemailsent) || ((isValidemail === true) && emailsended  ) ? 

<>


{/* Code verification Email */ }
<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box >

<Grid container >
       

        <Grid item xs={12}>

        <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth variant="outlined"
       
       
       >

       {/*  <InputLabel id="demo-simple-select-label"
        sx={{ color: "#b31d3e", fontFamily: 
        '"Tajawal", sans-serif', 
        fontSize: 16,
        color:'green'
        ,fontWeight:700 }}
        >أدخل الرمز الذي وصلك في البريد الإلكتروني</InputLabel>
 */}

        <Input  
        label="أدخل الرمز الذي وصلك في البريد الإلكتروني"
        helperText="إضغط على السهم الدائري لإعادة إرسال الرمز (تصفح صندوق الواردات لديك بما فيهم البريد المزعج )"
        fullWidth 
        dir="ltr"  
        sx={{
           
             //direction:'ltr',
             
            '& .MuiFormHelperText-root': {
              fontFamily: '"Tajawal", sans-serif',
               fontSize:'14px',
               color: '#b31d3e',
               fontWeight:700
                
           },
            '& .MuiFormLabel-root': {
              fontFamily: '"Tajawal", sans-serif',
               fontSize:'18px',
               color: '#191970',
               dir: 'rtl',
                
           },
            '& .MuiInputBase-input': {
               fontFamily: '"Tajawal", sans-serif',
                fontSize:'22px',
                color: '#191970',
                fontWeight:700,
                //text-align: right,
                
                 

                          },
            '& label.Mui-focused': {
              fontSize:'16px',
              color: '#b31d3e',
              fontWeight:700
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: '#add8e6',
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                fontFamily: '"Tajawal", sans-serif',
                fontSize:'16px',
                borderColor: '#add8e6',
              borderWidth: "2px",
               
              },
              '&:hover fieldset': {
                borderColor: 'purple',
              },
              '&.Mui-focused fieldset': {
                
                borderColor: 'purple',
              },
            } 
          }}

          hidden={emailverificated}
          inputRef={refcodeemail}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          value={valuescodeemail.textmaskcodeemail}
          onChange={handleChangecodeemail}
          onClick={(e) => handleClickcodeemail(e)}
          onFocus={(e)=> {
            ///alert('onfoucs')
            e.preventDefault();
          }}
          name="textmaskcodeemail"
           id="formatted-text-mask-input"
          inputComponent={textmaskcodeemailCustom}
          autoFocus={true} 
          
          
          />
          
          </FormControl>
          </Box>
</Grid>
 
</Grid>
 </Box>

</MuiThemeProvider>


</CacheProvider>



       
       

</>

: 


<>



</>



}




{emailverificated? <>


{/* Password 01 */ }
<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box sx={{ mt: 2 }}>

<Grid container >
       

        <Grid item xs={12}>

        <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth variant="outlined"
       
       
       > 


 
          <InputLabel 
          sx={{ color: "#b31d3e", fontFamily: 
          '"Tajawal", sans-serif', 
          fontSize: 16
          ,fontWeight:700 }}
          htmlFor="outlined-adornment-password">كلمة المرور</InputLabel>
          <OutlinedInput
           fullWidth 
           dir="rtl"  
           sx={{
                
                //direction:'ltr',
                
               '& .MuiFormHelperText-root': {
                 fontFamily: '"Tajawal", sans-serif',
                  fontSize:'14px',
                  color: '#b31d3e',
                  fontWeight:700
                   
              },
               '& .MuiFormLabel-root': {
                 fontFamily: '"Tajawal", sans-serif',
                  fontSize:'18px',
                  color: '#191970',
                  dir: 'rtl',
                   
              },
               '& .MuiInputBase-input': {
                  fontFamily: '"Tajawal", sans-serif',
                   fontSize:'22px',
                   color: '#191970',
                   fontWeight:700,
                   //text-align: right,
                   
                    
   
                             },
               '& label.Mui-focused': {
                 fontSize:'16px',
                 color: '#b31d3e',
                 fontWeight:700
               },
               '& .MuiInput-underline:after': {
                 borderBottomColor: '#add8e6',
               },
               '& .MuiOutlinedInput-root': {
                 '& fieldset': {
                   fontFamily: '"Tajawal", sans-serif',
                   fontSize:'16px',
                   borderColor: '#add8e6',
                 borderWidth: "2px",
                  
                 },
                 '&:hover fieldset': {
                   borderColor: 'purple',
                 },
                 '&.Mui-focused fieldset': {
                   
                   borderColor: 'purple',
                 },
               } 
             }}
  
             inputRef={refpassword1}
            id="outlined-adornment-password"
            type={valuesPassword.showPassword ? 'text' : 'password'}
            value={valuesPassword.password}
            onChange={handleChangePassword('password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {valuesPassword.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="كلمة المرور"
          />
       </FormControl>
          </Box>
</Grid>
 
</Grid>
 </Box>

</MuiThemeProvider>


</CacheProvider>






{/* Password Renter */ }
<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box sx={{ mt: 2 }}>

<Grid container >
       

        <Grid item xs={12}>

        <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth variant="outlined"
       
       
       > 


 
          <InputLabel 
          sx={{ color: "#b31d3e", fontFamily: 
          '"Tajawal", sans-serif', 
          fontSize: 16
          ,fontWeight:700 }}
          htmlFor="outlined-adornment-password">اعادة كلمة المرور</InputLabel>
          <OutlinedInput
             
           fullWidth 
           dir="rtl"  
           sx={{
                
                //direction:'ltr',
                
               '& .MuiFormHelperText-root': {
                 fontFamily: '"Tajawal", sans-serif',
                  fontSize:'14px',
                  color: '#b31d3e',
                  fontWeight:700
                   
              },
               '& .MuiFormLabel-root': {
                 fontFamily: '"Tajawal", sans-serif',
                  fontSize:'18px',
                  color: '#191970',
                  dir: 'rtl',
                   
              },
               '& .MuiInputBase-input': {
                  fontFamily: '"Tajawal", sans-serif',
                   fontSize:'22px',
                   color: '#191970',
                   fontWeight:700,
                   //text-align: right,
                   
                    
   
                             },
               '& label.Mui-focused': {
                 fontSize:'16px',
                 color: '#b31d3e',
                 fontWeight:700
               },
               '& .MuiInput-underline:after': {
                 borderBottomColor: '#add8e6',
               },
               '& .MuiOutlinedInput-root': {
                 '& fieldset': {
                   fontFamily: '"Tajawal", sans-serif',
                   fontSize:'16px',
                   borderColor: '#add8e6',
                 borderWidth: "2px",
                  
                 },
                 '&:hover fieldset': {
                   borderColor: 'purple',
                 },
                 '&.Mui-focused fieldset': {
                   
                   borderColor: 'purple',
                 },
               } 
             }}
  
             inputRef={refpassword2}
            id="outlined-adornment-PasswordRe"
            type={valuesPasswordRe.showPasswordRe ? 'text' : 'Password'}
            value={valuesPasswordRe.PasswordRe}
            onChange={handleChangePasswordRe('PasswordRe')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPasswordRe}
                  onMouseDown={handleMouseDownPasswordRe}
                  edge="end"
                >
                  {valuesPasswordRe.showPasswordRe ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="اعادة كلمة المرور"
          />

        {valuesPasswordRe.PasswordRe !== valuesPassword.password? <>
        
          <FormHelperText 
           sx={{ color: "#b31d3e", fontFamily: 
           '"Tajawal", sans-serif', 
           fontSize: 14
           ,fontWeight:700 }}
           error id="accountId-error">
            يجب أن تدخل نفس كلمة السر التي أدخلتها أعلاه
            </FormHelperText>
              
        
        </>: <></>}
          


       </FormControl>
          </Box>
</Grid>
 
</Grid>
 </Box>

</MuiThemeProvider>


</CacheProvider>


</> : <></> }





{FailedAuth? <>
        
  <CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
            <Grid spacing={0}
  direction="column"
  alignItems="center"
  justifyContent="center"
  dir="rtl" container sx={{mt:0,}}>


        <FormHelperText 
         sx={{ color: "#b31d3e", fontFamily: 
         '"Tajawal", sans-serif', 
         fontSize: 14,
         mt:2
         ,fontWeight:700 }}
         error id="accountId-error">
          هذا البريد الإلكتروني غير مسجل لدينا
          </FormHelperText>
            
          </Grid>

</MuiThemeProvider>


</CacheProvider>



      </>: <></>}
        


      


 

           {/* Submit Button */ }
<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box sx={{ mt: 2 }}>

<Grid container >
       

        <Grid item xs={12}>

        <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth variant="outlined"
       
       
       > 

      <div>

       
      {emailverificated? <>
      

        <Button
              fullWidth
              

              onClick={(e)=>{
                e.preventDefault();
            
                if(valuesPassword.password.length==0 ){
                  refpassword1.current.focus();
                  return
                }
            
                if(valuesPasswordRe.PasswordRe.length===0){
                  refpassword2.current.focus();
                  return
                }
                 
                if(valuesPassword.password != valuesPasswordRe.PasswordRe){
                  refpassword2.current.focus();
                  return
                }
            
            
                onsubmitclicked(e)
            
                 
              }}


              variant="contained"
              
              endIcon={<LockOpenIcon sx={{ml:'5px', dir:'rtl',transform:'scaleX(-1)'}} />}>
    <Typography  sx={{ color: "#FFFFFF", fontFamily: 
           '"Tajawal", sans-serif', 
           fontSize: 22
           ,fontWeight:900 }}>
           تغيير كلمة السر    
    </Typography>
              
            </Button>
      
      
      </>:<> 

      <Button
              fullWidth
              //handleSubmit={handleSubmit}
              
              /*handleSubmit={(e)=>{
                if(refsearchbtn.current){
                  alert('click')
                  refsearchbtn.current.click();
                }
              }}*/
              disabled={isValidemail? false:true}
              hidden={isValidemail && codeemailsent ? true:false}
               
              onClick={(e)=>{
                ///alert('click hhh')
                ///refsearchbtn.current.click();
                Auth(e)
              }}

               
             
              variant="contained"
              
                
            startIcon={<SearchIcon sx={{ml:'0px',width:30, height:30, dir:'rtl'}} />}>
    <Typography  sx={{ color: "#FFFFFF", fontFamily: 
           '"Tajawal", sans-serif', 
           fontSize: 22
           ,fontWeight:900 }}>
           بحث    
    </Typography>
              
            </Button>



      <Button
      hidden
              fullWidth
              //handleSubmit={handleSubmit}
              
              /*handleSubmit={(e)=>{
                if(refsearchbtn.current){
                  alert('click')
                  refsearchbtn.current.click();
                }
              }}*/
              disabled={isValidemail? false:true}
               
               

              type="submit"
             ref={refsearchbtn}
              variant="contained"
              
                
            startIcon={<SearchIcon sx={{ml:'0px',width:30, height:30, dir:'rtl'}} />}>
    <Typography  sx={{ color: "#FFFFFF", fontFamily: 
           '"Tajawal", sans-serif', 
           fontSize: 22
           ,fontWeight:900 }}>
           بحث    
    </Typography>
              
            </Button>

            </>}

            </div>


      </FormControl>
          </Box>
</Grid>
 
</Grid>
 </Box>

</MuiThemeProvider>


</CacheProvider>


<TextField
  name="codeemailcrypt"
  value={md5(codeemail)}
 hidden
/>

<TextField
  name="codeemailsent"
  value={emailsended? true:false}
  hidden
/>



 
<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
            <Grid spacing={0}
  direction="column"
  alignItems="center"
  justifyContent="center"
  dir="rtl" container sx={{mt:2,}}>

              <Grid item >
                <Link href="/LoginDon" variant="body2"
                sx={{ fontFamily: 
                '"Tajawal", sans-serif', 
                fontSize: 12
                ,fontWeight:900 }}>
                  تسجيل الدخول
                </Link>
              </Grid>
              <Grid item sx={{mt:1,}}>
                <Link href="/RegisterDon"  variant="body2"
                sx={{ fontFamily: 
                  '"Tajawal", sans-serif', 
                  fontSize: 12
                  ,fontWeight:900 }}>
                  {"هل تريد إنشاء حساب جديد؟"}
                </Link>
              </Grid>
            </Grid>

            </MuiThemeProvider>


</CacheProvider>



          </Box>
        </Box>
         
     
     
        </div>

</div>


   
   </Grid>

   </Container>

</form>

   <Footer />






    
   <Dialog
       PaperProps={{
        style: {
          backgroundColor: '#add8e6',
          boxShadow: 'none',
        },
      }}
       //fullScreen={mediumScreen}
        open={OpenDialoglink}
        onClose={handleClickCloseDialoglink}
        aria-labelledby="responsive-dialog-title"
      >

<Card  style={{}} sx={{ maxWidth: 345  }}>

  {/* <p className="h5 font-weight-bold text-primary" id={showhidvalueteldate}><FaCalendarAlt/> تاريخ آخر تبرع: {props.daydon+'/'+props.monthdon+'/'+props.yeardon}</p>
 */ }
<CardHeader
        avatar={
          
            <InfoIcon sx={{color:'#2196F3',width: 50, height: 50,ml:2}}></InfoIcon>
         
        }
        action={
         
           
        <IconButton  onClick={handleClickCloseDialoglink} aria-label="settings" sx={{ ml:-1,mt:-1  }}>
                    
        <CloseIcon  /> 

        </IconButton>
        } 
        title={
          <>
          <Box sx={{ ml:2, mt:0, display: 'flex', alignItems: 'flex-end', justifyContent: 'center'}}>

          <Typography sx={{ color:'#191970', fontFamily: '"Tajawal", sans-serif', fontSize: 14,fontWeight:900}} >
          من الأفضل إستعمال متصفح كروم Chrome 
          </Typography>
            </Box>
            
            </>
        
        }
        subheader=""
      />


       


</Card>

      </Dialog>
 





   </>
  );
}