import React, { useState,useEffect }  from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import PaginationBoot from "react-js-pagination";
 import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Header from '../containers/Header';
import Footer from '../containers/Footer';


import usePagination from "./PaginationCustom";
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

import { MuiThemeProvider } from "@material-ui/core/styles";


import CardListDocState from '../components/CardListDocState';
import SearchBoxDoc from '../components/SearchBoxDoc';
import ScrollDoc from '../components/ScrollDoc';
import './AppDoc.css';
import Select from 'react-select';
 import Modal from 'react-bootstrap/Modal';
//require("react-bootstrap/ModalHeader")
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import appico from '../iconimg/appico.png';
import playstoredownload from '../iconimg/playstoredownload.png';
import Spinner from 'react-bootstrap/Spinner';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
 
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './amirifont.css';
import { styled } from "@mui/material/styles";
import MuiAutocomplete from "@mui/material/Autocomplete";
 import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
 
import { makeStyles,withStyles } from "@material-ui/core/styles";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

 
import CssBaseline from "@material-ui/core/CssBaseline";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import { StylesProvider, jssPreset } from '@mui/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import AlertTitle from '@mui/material/AlertTitle';

import FormControl from '@mui/material/FormControl';
 import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { useDebounce } from 'use-debounce';

import { debounce } from "debounce";
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ClearIcon from '@mui/icons-material/Clear';
import { blue } from '@mui/material/colors';
import SendIcon from '@mui/icons-material/Send';
 
let options_Sp = [];
let options_Wly = [];
  
let robots=[];
let splistsCard=[];
let Wlylists=[];
 let BldOnly=[];
let searchtxtvalue='';
let selectSpValue=0;
let selectWlyValue=0;
let selectBldValue=0;
 
let Bldlists=[];
let BldlistsCard=[];


 
let onclickloading=false;




const useStylesButtonSearch = makeStyles((theme) => ({
  button: {
    fontFamily: '"Changa", sans-serif',
    fontSize: 18,
    direction: "rtl",
    width: '100%', // Default width for small screens and below

    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
      width: 'auto', // Width for screens greater than or equal to small (sm) breakpoint

    },
    [theme.breakpoints.up('md')]: {
      fontSize: 24,
      width: '10em', // Approximate width for screens greater than or equal to medium (md) breakpoint

    }
    ,
    [theme.breakpoints.down('sm')]: {
      width: '80%', // Approximate width for screens smaller than the small (sm) breakpoint (mobile)
    },
    marginBottom: theme.spacing(2), // Add margin space (adjust as needed)

  },
}));


export default function DocState() {
   
  const [value, setValue] = useState('');
  const [searchvalue, setsearchvalue] = useState('');
  
  const [firstloadingline, setfirstloadingline] = useState(true);
  const [loadingline, setloadingline] = useState(true);

  const [show, setshow] = useState(false);
  const [showLinearProgress, setshowLinearProgress] = useState(false);

  

  const [valuebld, setvaluebld] = useState({value: 0, label: 'جميع البلديات'+' (Toutes Les Communes)',indexvalue:-1});

  
  const [loading, setLoading] = useState(true);

  const [text1, settext1] = useState('');
  const [showhidvalue, setshowhidvalue] = useState('HideBld');
  //const [robots, setrobots] = useState([]);

    
  const [setCurrentPage, setsetCurrentPage] = useState(1);

  
  const [postsPerPage, setpostsPerPage] = useState(10);
   
  const [currentPosts, setcurrentPosts] = useState([]);
  //const [BldlistsCard, setBldlistsCard] = useState([]);
  //const [splistsCard, setsplistsCard] = useState([]);
  const [filteredRobots, setfilteredRobots] = useState([]);

  
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
        // Default transform is "translate(14px, 20px) scale(1)""
        // This lines up the label with the initial cursor position in the input
        // after changing its padding-left.
        transform: "translate(34px, 20px) scale(1);",
        fontFamily: 
        '"Changa", sans-serif',
              fontSize: 25,
              direction: "rtl"
      }
    },
    inputRoot: {
      
      color: "#191970",

      
      // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        fontFamily: '"Tajawal", sans-serif', 
        fontWeight:700,
        color: "#191970",
        // Default left padding is 6px
        paddingLeft: 26
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "#add8e6"
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "red"
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "purple"
      }
    }
  }));

  const classes = useStyles();
  



  //const [splists, setsplists] = useState([]);
  //const [Wlylists, setWlylists] = useState([]);
   //const [Bldlists, setBldlists] = useState([]);
  //const [BldlistsCard, setBldlistsCard] = useState([]);
  //const [splistsCard, setsplistsCard] = useState([]);
  
  const [options_Bld, setoptions_Bld] = useState([]);
  const [resetbldauto, setresetbldauto] = useState('');


  let selectedSpOption=null;
  let selectedWlyOption=null;
  let selectedBldOption=null;
 
  let splists=[];
   let Wlylists=[];
 
   
   const PER_PAGE = 10;

  /*const count = Math.ceil(filteredRobots.length / PER_PAGE);
  let _DATA = usePagination(filteredRobots, PER_PAGE);*/


  const defaultColor = "#ff0000";
  const hoverColor = "#0000ff";
  const focusColor = "#00ff00";

  const themetextfieldnew = createTheme({
    overrides: {
      MuiOutlinedInput: {
        root: {
          "&:hover $notchedOutline": {
            borderColor: hoverColor
          },
          "&$focused $notchedOutline": {
            borderColor: focusColor
          }
        },
        notchedOutline: {
          borderColor: defaultColor
        }
      }
    },
    fieldset: {
      fontFamily: 
        '"Changa", sans-serif', 
        fontSize: 45,
         

    } 
  });

    

const getDefaultState = () => {
    settext1('');
  }

  const clear = () => {
    // return the initial state
    getDefaultState();
  }



  const onSpChanged = (event, values) => {
    if (values) {
      selectSpValue = values.value;
    } else {
      selectSpValue = 0; // default value
    }
  
    console.log(selectSpValue);

    
  }


  

  
  const handlePageChange = pageNumber => {
    //console.log(`active page is ${pageNumber}`);
    console.log(`active page is ${pageNumber}` + 'filteredRobots.length ='+filteredRobots.length);

    // Get current posts
    const indexOfLastPost = pageNumber * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
      setcurrentPosts(filteredRobots.slice(indexOfFirstPost, indexOfLastPost));
      setsetCurrentPage(pageNumber);

     
  };

  const handleChangePagination = (e, pageNumber) => {
    console.log(`active page is ${pageNumber}` + 'filteredRobots.length ='+filteredRobots.length);
    
    // Get current posts
    const indexOfLastPost = pageNumber * PER_PAGE;
    const indexOfFirstPost = indexOfLastPost - PER_PAGE;
      setcurrentPosts(filteredRobots.slice(indexOfFirstPost, indexOfLastPost));
      setsetCurrentPage(pageNumber);

   };


  const onwlyChanged = (event, values) => {
   
    if (values) {
      console.log('selectedWlyOption value= ' + values.value); 
      selectWlyValue = values.value;
      selectedWlyOption = values;
      if(selectedWlyOption.value==0){
        setshowhidvalue('HideBld');
      }
      else{
        setshowhidvalue('ShowBld');
  
      }
    }else{
      return;
    }

    
    
    
    {BldOnly = Bldlists.filter(Bldlists =>{
      

      return Bldlists.MatWilaya ==selectedWlyOption.value;
    })}

    console.log('BldOnly value= '+BldOnly.length)

    /////////////Baladia///////////////////
{setoptions_Bld (BldOnly.sort(function(a, b){
if(Number(a.ID) < Number(b.ID)) return -1;  // Ascending Order
if(Number(a.ID) > Number(b.ID)) return 1;   // Descending Order  
return 0; // Default
}).map(function (Bldlists,i) {
return { value: Bldlists.ID, label: (i+1)+'- ' + Bldlists.NOM+' ('+Bldlists.Nomfr+')',indexvalue:i};
}))}

setoptions_Bld((prevArr) => ([ {value: 0, label: 'جميع البلديات'+' (Toutes Les Communes)',indexvalue:-1},...prevArr]));

selectBldValue=0;
setvaluebld({value: 0, label: 'جميع البلديات'+' (Toutes Les Communes)',indexvalue:-1});
 
  }


  

  const ValidationTextField = styled(TextField)({
    '& input:valid + fieldset': {
      borderColor: 'blue',
      borderWidth: 2,
    },
    '& input:invalid + fieldset': {
      borderColor: 'red',
      borderWidth: 2,
    },
    '& input:valid:focus + fieldset': {
      borderLeftWidth: 6,
      padding: '4px !important', // override inline-style
    },
  });




  const onbldChanged = (event, values) => {
    if (values) {
      selectBldValue=values.value;
      setvaluebld(values)
      console.log('values.value onbldChanged'+values)
    }
   
  }


     useEffect(() => {
     //// alert("test");
      ////alert( sessionStorage.getItem("ID") );
    // Logging the event that component is mount on first render
     console.log('useEffect is mounted');
      
     searchtxtvalue="";
     selectSpValue=0;
     selectWlyValue=0;
     selectBldValue=0;
     

   fetch('https://doctorsdz.machrou3.com/user/getBaladiya4appdoc')
   .then(response=> response.json())
   .then(getBaladiya4appdoc => {
    
   Wlylists=getBaladiya4appdoc;
   Bldlists=getBaladiya4appdoc;
   BldlistsCard=getBaladiya4appdoc;


   /////////////Wilayets///////////////////

const WlyOnly = Wlylists.filter(Wlylists =>{
  return Wlylists.wlycaptal !==null;
  })
  
  options_Wly =WlyOnly.sort(function(a, b){
  if(Number(a.MatWilaya) < Number(b.MatWilaya)) return -1;  // Ascending Order
  if(Number(a.MatWilaya) > Number(b.MatWilaya)) return 1;   // Descending Order  
  return 0; // Default
  }).map(function (Wlylists,i) {
  return { value: Wlylists.MatWilaya, label:  Wlylists.MatWilaya+'- ' + Wlylists.NOM+' ('+Wlylists.Nomfr+')',indexvalue:i};
  }) 
  
  options_Wly.unshift({value: '0', label: 'جميع الولايات'+' (Toutes Les Wilayas)',indexvalue:-1})
  
  
   
   
   }); 

  ////////////

   fetch('https://doctorsdz.machrou3.com/user/getsp4appdoc')
   .then(response=> response.json())
   .then(getsp4appdoc => {
     
    splists=getsp4appdoc;
    splistsCard=getsp4appdoc;
    const newSelect=splists;
    /*
    const newSelect = splists.filter(
      item => (
        
         item.num_sp ==21
|| item.num_sp ==22
|| item.num_sp ==25
|| item.num_sp ==26
|| item.num_sp ==27
|| item.num_sp ==28
|| item.num_sp ==29
|| item.num_sp ==30
|| item.num_sp ==31
|| item.num_sp ==32
|| item.num_sp ==34
|| item.num_sp ==36
|| item.num_sp ==40
|| item.num_sp ==42
|| item.num_sp ==44
|| item.num_sp ==52
|| item.num_sp ==53
|| item.num_sp ==55
|| item.num_sp ==62
|| item.num_sp ==73
|| item.num_sp ==74
|| item.num_sp ==79
|| item.num_sp ==80
|| item.num_sp ==81
|| item.num_sp ==82
|| item.num_sp ==83
|| item.num_sp ==84
|| item.num_sp ==85
|| item.num_sp ==88
|| item.num_sp ==90
|| item.num_sp ==92
              )
    );*/


options_Sp = newSelect.map(function (splists,i) {
  return { value: splists.num_sp, label: (i+1)+'- ' +splists.name_sp +' ('+splists.name_spfr+')', indexvalue:i, name_sp:splists.name_sp, name_spfr:splists.name_spfr};
  }) 
  //options.push({value: '0', label: 'جميع التخصصات'})
  options_Sp.unshift({value: '0', label: 'جميع التخصصات'+' (Toutes les spécialités) ' , indexvalue:-1, name_sp:"جميع التخصصات", name_spfr:"Toutes les spécialités"})
  
  //console.log(options_Sp);

   
   });

  


 fetch('https://doctorsdz.machrou3.com/user/getinfoAppDoc')
   .then(response=> response.json())
   .then(getinfoAppDoc => {
    
    
   const robotsAAA=getinfoAppDoc;
  //// const newSelectmore=robotsAAA;
   /*
   const newSelectmore = robotsAAA.filter(
     item => (
      
      item.Num_sp ==21
      || item.Num_sp ==22
      || item.Num_sp ==25
      || item.Num_sp ==26
      || item.Num_sp ==27
      || item.Num_sp ==28
      || item.Num_sp ==29
      || item.Num_sp ==30
      || item.Num_sp ==31
      || item.Num_sp ==32
      || item.Num_sp ==34
      || item.Num_sp ==36
      || item.Num_sp ==40
      || item.Num_sp ==42
      || item.Num_sp ==44
      || item.Num_sp ==52
      || item.Num_sp ==53
      || item.Num_sp ==55
      || item.Num_sp ==62
      || item.Num_sp ==73
      || item.Num_sp ==74
      || item.Num_sp ==79
      || item.Num_sp ==80
      || item.Num_sp ==81
      || item.Num_sp ==82
      || item.Num_sp ==83
      || item.Num_sp ==84
      || item.Num_sp ==85
      || item.Num_sp ==88
      || item.Num_sp ==90
      || item.Num_sp ==92
      
             )
   );
*/


   robots=robotsAAA;


  })
  .then(response => {
  onSearchClick(true);
    
    })
.catch((response) => {

});

  
  

  

}, []);
/*
useEffect(() => {
   
    alert('loading')
  
  
});
*/
/*
useEffect(() => {
  if (robots.length!=0) {
    alert('loading')
      //onSearchClick();
  }
  alert('loading'+robots.length)
  
}, [loading]);*/





  
  const onSearchChange = (event) => {

    searchtxtvalue=event.target.value;
 
    console.log((searchtxtvalue));
  
   }
/** debounced search input handler */
    //handleSearchChange = (debounce(this.onSearchChange, 200));

 
    const handleSearchChange = (event) => {
      return AwesomeDebouncePromise(onSearchChange(event), 200);
    }

    function onSearchClick(first=false){
 
      //////////
if(first){// show on first load only
  console.log('changing to false firstloadingline');
  setfirstloadingline(true);
  searchtxtvalue="";
  selectSpValue=0;
  selectWlyValue=0;
  selectBldValue=0;

  setfilteredRobots(robots);
   
 }

 //////////
if(!first){// if not first load
  setfirstloadingline(false);

  onclickloading=true;
  
 

       console.log('onSearchClick');
       
       console.log('robots.lenght'+robots.length);
       console.log('splists.lenght'+splists.length);
       console.log('Wlylists.lenght'+Wlylists.length);
       console.log('Bldlists.lenght'+Bldlists.length);

       console.log(selectSpValue);
       console.log(selectWlyValue);
       console.log(selectBldValue);
       console.log(searchtxtvalue);
   
         
   /* setselectSpValue(values.value);
    setsetCurrentPage(1);
    setselectedSpOption(values);*/

/*
        const filteredRobotsAA=  robots.filter(robotsa =>{
   
          //All sp and All wly
         if(selectSpValue==0 && selectWlyValue==0){
           console.log('return robots'+robots.length)
           //return (robotsa.Tel1_doct ==='0');
          // return robots;
           return (robotsa.Name_doct.toLowerCase().includes(searchtxtvalue.toLowerCase())) ||
                  (robotsa.Adress_doct.toLowerCase().includes(searchtxtvalue.toLowerCase())) ||
                  (robotsa.Tel1_doct.toLowerCase().includes((searchtxtvalue.toLowerCase()))) ||
                  (robotsa.Tel2_doct.toLowerCase().includes((searchtxtvalue.toLowerCase()))) ||
                  (robotsa.Email_doct.toLowerCase().includes(searchtxtvalue.toLowerCase())) ||
                  (robotsa.description_doct.toLowerCase().includes(searchtxtvalue.toLowerCase())) 
            ;
         }*/



         const filteredRobotsAA = robots.filter(robotsa => {
          // All sp and All wly
          if (selectSpValue == 0 && selectWlyValue == 0) {
              // Check if robotsa is not null or undefined
              if (robotsa) {
                  // Check if any of the properties include the search text (case-insensitive)
                  return (robotsa.Name_doct && robotsa.Name_doct.toLowerCase().includes(searchtxtvalue.toLowerCase())) ||
                      (robotsa.Adress_doct && robotsa.Adress_doct.toLowerCase().includes(searchtxtvalue.toLowerCase())) ||
                      (robotsa.Tel1_doct && robotsa.Tel1_doct.toLowerCase().includes(searchtxtvalue.toLowerCase())) ||
                      (robotsa.Tel2_doct && robotsa.Tel2_doct.toLowerCase().includes(searchtxtvalue.toLowerCase())) ||
                      (robotsa.Email_doct && robotsa.Email_doct.toLowerCase().includes(searchtxtvalue.toLowerCase())) ||
                      (robotsa.description_doct && robotsa.description_doct.toLowerCase().includes(searchtxtvalue.toLowerCase()));
              }  else {
                // Return false if robotsa is null or undefined
                return false;
            }
          }
         
         //All sp and one wly
         else if(selectSpValue==0 && selectWlyValue != 0){
           // Check if robotsa is not null or undefined
           if (robotsa) {

           //All sp And one wly And One Bld
            if(selectBldValue!=0){
             return (robotsa.Name_doct.toLowerCase().includes(searchtxtvalue.toLowerCase())) && robotsa.idbaladiya ===selectBldValue.toString() ;
            }
            else{
             //All sp and one wly
             return (robotsa.Name_doct.toLowerCase().includes(searchtxtvalue.toLowerCase())) && robotsa.Wilaya_doct ===selectWlyValue.toString() ;
   
            }

          }  else {
            // Return false if robotsa is null or undefined
            return false;
        }
         }
         //one sp and All wly
         else if(selectSpValue != 0 && selectWlyValue == 0){
           return (robotsa.Name_doct.toLowerCase().includes(searchtxtvalue.toLowerCase())) && robotsa.Num_sp ===selectSpValue.toString() ;
         }
         //one sp and one wly
         else if(selectSpValue != 0 && selectWlyValue != 0){
   
           // Check if robotsa is not null or undefined
           if (robotsa) {

           //one sp and one wly and one bld
           if(selectBldValue!=0){
             return (robotsa.Name_doct.toLowerCase().includes(searchtxtvalue.toLowerCase())) && robotsa.Num_sp ===selectSpValue.toString() && robotsa.idbaladiya ===selectBldValue.toString() ;
   
           }else{
             return (robotsa.Name_doct.toLowerCase().includes(searchtxtvalue.toLowerCase())) && robotsa.Num_sp ===selectSpValue.toString() && robotsa.Wilaya_doct ===selectWlyValue.toString() ;
           }
   
          }  else {
            // Return false if robotsa is null or undefined
            return false;
        }
        
         }
   
          
       })
   

       
   
       console.log('filteredRobots.length'+filteredRobotsAA.length)
   
        // Get current posts
    const indexOfLastPost = 1 * PER_PAGE;
    const indexOfFirstPost = indexOfLastPost - PER_PAGE;
      setcurrentPosts(filteredRobotsAA.slice(indexOfFirstPost, indexOfLastPost));
      setfilteredRobots(filteredRobotsAA);
      setsetCurrentPage(1);
      




 //////////////////////

////////////////////
    }


  };

  useEffect(() => {
     
    if(filteredRobots.length >0  && !onclickloading){
      setloadingline(false);
      //alert('loading first')
    }

    if(onclickloading){
      onclickloading=false;
      setloadingline(false);
      //alert('loading onclick')
    }

    
    
  }, [filteredRobots]);

  
  

 
     const themeChanga = createTheme({  
       typography: {
         fontFamily: 
           '"Changa", sans-serif', 
           fontSize: 12,
            
   
       },
       Button: {
        fontFamily: 
          '"Changa", sans-serif', 
          fontSize: 18,
           
  
      }
     });

     const themetextfield = createTheme({  
      typography: {
        fontFamily: 
          '"Changa", sans-serif', 
          fontSize: 12,
           
  
      } 
    });
   
      
   
     const themeautosp = createTheme({
       components: {
         MuiAutocomplete: {
           styleOverrides: {
             root: {
               '& label': {
                 fontFamily: 
           '"Changa", sans-serif',
                 fontSize: 14,
                 direction: "rtl"
                
               },
             },
             input: {
               fontFamily: 
           '"Changa", sans-serif',
               fontSize: 16,
               color: "blue"
             },
             listbox: {
               fontFamily: 
           '"Changa", sans-serif',
               //fontSize: 30,
               color: "black",
               //paddingTop: 30
             },
   
   
           },
         },
       },
     });
   
    
      const AutocompleteSp = styled(MuiAutocomplete)({
       ".MuiAutocomplete-inputRoot": {
         color: "red",
         
         fontFamily: '"Changa", sans-serif', 
         backgroundColor: "#f2f2f2",
         "& .MuiInputAdornment-root": {
           color: "blue"
         },
         "& .MuiOutlinedInput-notchedOutline": {
           borderWidth: "2px",
           borderColor: "blue"
         },
         "&:hover .MuiOutlinedInput-notchedOutline": {
           borderWidth: "2px",
           borderColor: "blue"
         },
         "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
           borderWidth: "2px",
           borderColor: "blue"
         }
       }
     });
    
     const AutocompleteWl = styled(MuiAutocomplete)({
      ".MuiAutocomplete-inputRoot": {
        color: "red",
        
        fontFamily: '"Changa", sans-serif', 
        backgroundColor: "#f2f2f2",
        "& .MuiInputAdornment-root": {
          color: "blue"
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderWidth: "2px",
          borderColor: "blue"
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderWidth: "2px",
          borderColor: "blue"
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderWidth: "2px",
          borderColor: "blue"
        }
      }
    });

    const AutocompleteBld = styled(MuiAutocomplete)({
      ".MuiAutocomplete-inputRoot": {
        color: "red",
        
        fontFamily: '"Changa", sans-serif', 
        backgroundColor: "#f2f2f2",
        "& .MuiInputAdornment-root": {
          color: "blue"
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderWidth: "2px",
          borderColor: "blue"
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderWidth: "2px",
          borderColor: "blue"
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderWidth: "2px",
          borderColor: "blue"
        }
      }
    });
   

    
       const classesbuttonsearch = useStylesButtonSearch();
    
      const handleSearch = () => {
        // Implement your search functionality here
        console.log('Performing search...');
      };

      

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'blue',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'blue',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'red',
    },
    '&:hover fieldset': {
      borderColor: 'yellow',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'blue',
    },
  },
});


      
     

    /*
     const CssTextField = styled(TextField)({
       
       '& label.Mui-focused': {
         color: 'blue',
       }
       ,
      
       '& .MuiFormLabel-root': {
         color: 'blue'
       },
      
       '& .MuiInput-underline:after': {
         borderBottomColor: 'blue',
       },
       '& .MuiOutlinedInput-root': {
         fontFamily: '"Changa", sans-serif', 
            ///backgroundColor: "#f2f2f2",
         '& fieldset': {
           
           borderWidth: "2px",
           borderColor: 'blue',
         },
         '&:hover fieldset': {
           borderColor: 'blue',
         },
         '&.Mui-focused fieldset': {
           borderColor: 'blue',
         },
       },
        
     });
   
   */
   
   
    /* const useStyles = makeStyles({
       option: {
         "&:hover": {
           backgroundColor: "cyan !important"
         }
       }
     });
     const styles = useStyles;*/
   
    
   
    
   const theme = createTheme({
     direction: 'rtl', // Both here and <body dir="rtl">
   });
   // Create rtl cache
   const cacheRtl = createCache({
     key: 'muirtl',
     stylisPlugins: [prefixer, rtlPlugin],
   });

   


   return(
     
    <>
      <Header />

      <Grid
  container
  spacing={0}
  direction="column"
  alignItems="center"
  style={{ minHeight: '100vh', marginTop: '10px' }} // Added marginTop to set the top margin to 10px
>

      
 
     


    { !( robots.length || splists.length || Wlylists.length || Bldlists.length || options_Sp.length || options_Wly.length) ?  
    
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <CircularProgress />
      {/*<Box sx={{ width: '100%',mt:2 }} > 
      
      <LinearProgress />
      </Box>
      */}

      </div>
 
    : 
 
    (
      <>
 <div className="container" style={{marginTop:'-30px'}}>
           
           <div className="row text-center">


        {/* Autocomplement Specialite */}
{/* <ThemeProvider theme={themeautosp}></ThemeProvider> */}
  <CacheProvider value={cacheRtl}>

          <Autocomplete
            noOptionsText={'حدث الصفحة مرة أخرى'}
           classes={classes}
          id="combo-box-demo"
         //value={options_Sp[0]}
         onChange={onSpChanged}
         helperText="جرب أكتب الأحرف الأولى للتخصص في خانة التخصصات "

           ///placeholder="Search a country (start with a)"

         defaultValue={options_Sp[0]}
      //disablePortal

       /*classes={{ //option: styles.option }}*/ 

      options={options_Sp}
      renderOption={(props, option) => {
      const { label, indexvalue,name_sp,name_spfr } = option;

      return (
        indexvalue % 2 == 0 ? 

        <span {...props}   style={{ backgroundColor: "#add8e6",color: "#191970", fontFamily: 
        '"Tajawal", sans-serif', 
        fontSize: 18
        ,fontWeight:700 }}>

          {(indexvalue  == -1 ?
          <>
            {'- ' +name_sp} <br/> {name_spfr}
             </>
            :
            <>
            {(indexvalue+1)+'- ' +name_sp} <br/> {name_spfr}
            </>
            )}
            
          
        </span>
        :

        <span {...props}   style={{ backgroundColor: "#FFFFFF",color: "#191970", fontFamily: 
        '"Tajawal", sans-serif', 
        fontSize: 18
        ,fontWeight:700 }}
        >
          
          {(indexvalue  == -1 ?
          <>
            {'- ' +name_sp} <br/> {name_spfr}
             </>
            :
            <>
            {(indexvalue+1)+'- ' +name_sp} <br/> {name_spfr}
            </>
            )}


        </span>

      );
    }} 

    
    
      //sx={{ width: 300 }}
      renderInput={(params) => <Box display="flex" justifyContent="center">  <TextField
        helperText="جرب أكتب الأحرف الأولى للتخصص في خانة التخصصات "
      
      sx={{
                
        '& .MuiFormHelperText-root': {
          fontFamily: '"Tajawal", sans-serif',
           fontSize:'12px',
           color: '#b31d3e',
           fontWeight:700
            
       }
      }}
      
      
      fullWidth  {...params} label="التخصصات"  /> </Box>  }
      />
 
  
 </CacheProvider>
 

 {/* 
<Autocomplete   
  disablePortal
 
  id="combo-box-demo2"
  onChange={onwlyChanged}
  options={options_Wly}
  sx={{ width: 300 }}
  renderInput={(params) => <TextField {...params} label="wily" />}
/>
*/}

 {/* Autocomplement Wilayat  */}
  
 <CacheProvider value={cacheRtl}>

 
      <Autocomplete
      noOptionsText={'حدث الصفحة مرة أخرى'}
      classes={classes}
     id="combo-box-demo"
  onChange={onwlyChanged}
  options={options_Wly}

      sx={{ mt: 2 }}
     defaultValue={options_Wly[0]}
  //disablePortal
 /* classes={{
    option: styles.option
  }}*/
  renderOption={(props, option) => {
  const { label, indexvalue } = option;

  return (
    indexvalue % 2 == 0 ? 

    <span {...props}   style={{ backgroundColor: "#add8e6",color: "#191970", fontFamily: 
    '"Tajawal", sans-serif', 
    fontSize: 18
    ,fontWeight:700 }}>

      {(indexvalue  == -1 ?
      <>
        {'- ' +label} 
         </>
        :
        <>
        {label} 
        </>
        )}
        
      
    </span>
    :

    <span {...props}   style={{ backgroundColor: "#FFFFFF",color: "#191970", fontFamily: 
    '"Tajawal", sans-serif', 
    fontSize: 18
    ,fontWeight:700 }}>
      
      {(indexvalue  == -1 ?
      <>
        {'- ' +label} 
         </>
        :
        <>
        {label} 
        </>
        )}


    </span>

  );
}} 



  //sx={{ width: 300 }}
  renderInput={(params) => <Box display="flex" justifyContent="center">  <TextField fullWidth  {...params} label="الولايات"  /> </Box>  }
  />

  

 </CacheProvider>

  
{showhidvalue == 'ShowBld' ?  //Show or hide
<>

{/*
<Autocomplete
  disablePortal
  value={options_Bld[0]}
  id="combo-box-demo3"
  options={options_Bld}
  defaultValue={options_Bld[0]}

  sx={{ width: 300 }}
  renderInput={(params) => <TextField {...params} label="Movie" />}
/>*/ }


 {/* Autocomplement Baladia */ }
  
 <CacheProvider value={cacheRtl}>

 
   
      <Autocomplete
      noOptionsText={'إختر الولاية'}
      classes={classes}
      value={valuebld}
  id="combo-box-demo"
  options={options_Bld}
  defaultValue={options_Bld[0]}
 /* onChange={(_, newValue) => {
    setvaluebld(newValue)
  }}*/
      sx={{ mt: 2 }}
    // value={selectedBldOption}
     onChange={onbldChanged}
   //disablePortal
  /*classes={{
    option: styles.option
  }}*/
  renderOption={(props, option) => {
  const { label, indexvalue } = option;

  return (
    indexvalue % 2 == 0 ? 

    <span {...props}   style={{ backgroundColor: "#add8e6",color: "#191970", fontFamily: 
    '"Tajawal", sans-serif', 
    fontSize: 18
    ,fontWeight:700 }}>

      {(indexvalue  == -1 ?
      <>
        {'- ' +label} 
         </>
        :
        <>
        {label} 
        </>
        )}
        
      
    </span>
    :

    <span {...props}   style={{ backgroundColor: "#FFFFFF",color: "#191970", fontFamily: 
    '"Tajawal", sans-serif', 
    fontSize: 18
    ,fontWeight:700 }}>
      
      {(indexvalue  == -1 ?
      <>
        {'- ' +label} 
         </>
        :
        <>
        {label} 
        </>
        )}


    </span>

  );
}} 



  //sx={{ width: 300 }}
  renderInput={(params) => <Box display="flex" justifyContent="center">  <TextField fullWidth  {...params} label="البلديات"  /> </Box>  }
  />

 
 </CacheProvider>

</>//////////// close show or hide
:
<>
</> }

{/*
<TextField
      variant="outlined"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      InputProps={{
        endAdornment: value ? (
          <IconButton size="small" onClick={() => setValue("")}>
            <ClearIcon />
          </IconButton>
        ) : undefined
      }}
    />

    */}
 


<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box >

<Grid container >
       

        <Grid item xs={12}>
        
<TextField fullWidth  

sx={{
  mt: 2,
  mb: 2,
   
  '& .MuiFormHelperText-root': {
    fontFamily: '"Tajawal", sans-serif',
     fontSize:'14px',
     color: '#b31d3e',
     fontWeight:700
      
 },
  '& .MuiFormLabel-root': {
    fontFamily: '"Tajawal", sans-serif',
     fontSize:'16px',
     color: '#191970'
 },
  '& .MuiInputBase-input': {
     fontFamily: '"Tajawal", sans-serif',
      fontSize:'18px',
      color: '#191970',
      fontWeight:700
  },
  '& label.Mui-focused': {
    fontSize:'16px',
    color: '#b31d3e',
    fontWeight:700
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#add8e6',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      fontFamily: '"Tajawal", sans-serif',
      fontSize:'16px',
      borderColor: '#add8e6',
    borderWidth: "2px",
     
    },
    '&:hover fieldset': {
      borderColor: 'purple',
    },
    '&.Mui-focused fieldset': {
      
      borderColor: 'purple',
    },
  } 
}}
         //required
        variant="outlined"
        //defaultValue="Success"
        //id="validation-outlined-input"
        helperText="يمكنك ترك الخانة فارغة و الضغط على بحث."
        label="إسم، عنوان، هاتف.."
        type="search"
        onChange={(newValue) => {
          onSearchChange(newValue);
    
          ////handleSearchChange(newValue);
        }}
       // inputProps={{style: {fontSize: 40}}} // font size of input text
        //InputLabelProps={{style: {fontSize: 40}}} // font size of input label

        
              onKeyPress={(ev) => {
          //console.log(`Pressed keyCode ${ev.key}`);
         if (ev.key === 'Enter') {
           onSearchClick();
           ev.target.blur();///to hide keyboard
           console.log("enter")
           // Do code here
           //console.log(JSON.stringify(ev.target.value));
           //this.onSearchClickbtn();
           ev.preventDefault();
         }
       }}

       InputProps={{
        endAdornment: (
          <InputAdornment>
            <IconButton 
            onClick={() => {
              onSearchClick();
            }}
            >
            <SearchIcon sx={{ color: "#191970" }} />  
            </IconButton>
          </InputAdornment>
        )
      }}

        

      />

   
</Grid>
      </Grid>

</Box>
</MuiThemeProvider>


</CacheProvider>


 
  
<Box >

<Grid container >
       

        <Grid item xs={12}>

        <Button
      variant="contained"
      onClick={() => {
        onSearchClick();
      }}
      className={classesbuttonsearch.button}
    >
      بحث <SearchIcon />
    </Button>


      </Grid>
      </Grid>

</Box>
 

 

<CacheProvider value={cacheRtl}>

 
<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
{/*
<ValidationTextField  fullWidth sx={{ mt: 2 }}  
     helperText="كلمة البحث ليست إجبارية"
     type="search"
     variant="outlined"
      //id="TextFieldsearch"
     //value={text1}
   //defaultValue={searchtxtvalue}
     onChange={(newValue) => {
      onSearchChange(newValue);

      ////handleSearchChange(newValue);
    }}
  onKeyPress={(ev) => {
     //console.log(`Pressed keyCode ${ev.key}`);
    if (ev.key === 'Enter') {
      onSearchClick();
      ev.target.blur();///to hide keyboard
      console.log("enter")
      // Do code here
      //console.log(JSON.stringify(ev.target.value));
      //this.onSearchClickbtn();
      ev.preventDefault();
    }
  }}

  label="أكتب إسم، عنوان، هاتف...أو أتركها فارغة"

  InputProps={{
    endAdornment: (
      <InputAdornment>
        <IconButton 
        onClick={() => {
          onSearchClick();
        }}
        >
            <SearchIcon sx={{ color: "blue" }} />  
          
  
 
        </IconButton>
      </InputAdornment>
    )
  }}
/>

*/}

</Box>
<div style={{display: 'flex', justifyContent: 'center'}}>

   

      </div>

{
  /* 
      <div style={{display: 'flex', justifyContent: 'center'}}>
     {showLinearProgress && <Box sx={{ width: '100%',mt:2 }} >
      <LinearProgress />
    </Box>}
     </div> 
*/
}
       
 
{loadingline ? <>
      <Box sx={{ width: '100%',mt:2 }} > 
      <CircularProgress />
      </Box>
     </>:<></>}

        {(filteredRobots.length==0 || firstloadingline) && <>
        
          <Alert sx={{ mt: 1,mb:1, width:'100%',p:2,fontSize:14 }} severity="info">
        <AlertTitle><strong> اكتب الحروف الأولى من الكلمة التي تبحث عنها في خانة البحث أو اترك الخانة فارغة، تأكد من اختيار التخصص والولاية ثم اضغط على زر البحث.
          </strong></AlertTitle>
          </Alert>

        </>}
      

 
</CacheProvider>



 


            <CardListDocState 
             robots={currentPosts}
             BldlistsCard={BldlistsCard}
             splistsCard={splistsCard} />
           

           
          <div style={{ display: "flex", justifyContent: "center",direction:"ltr" }}>

          
    {/*
   
   <Pagination
   sx={{ mt:2 }}
   color="primary"
  count={10}
  renderItem={(item) => (
    <PaginationItem
      components={{
        last: (props) => <button {...props}>Last</button>,
        next: (props) => <button {...props}>Next</button>,
        first: (props) => <button {...props}>First</button>,
        previous: (props) => <button {...props}>Previous</button>
      }}
      {...item}
    />
  )}
  showFirstButton
  showLastButton
/>
   
   
  
     */ }
             {!firstloadingline && <> 

          <Pagination 
         // showLastButton
          variant="text"
          //size="medium"
         // sx={{ mt:3,mb:4 }}
          large="small"
          //sx={{ display: 'flex', alignItems: 'flex-end' }}
          color="primary"
          count={Math.ceil(filteredRobots.length / PER_PAGE)}
          onChange={handleChangePagination}
          page={setCurrentPage}
          //defaultPage={setCurrentPage}
          shape="circular"
          siblingCount={0}
          
sx={{
  mt:3,mb:4,
   
  '& .MuiButtonBase-root': {
     fontSize:'18px',
     color: '#191970',
     fontWeight:700
      
 },
  '& .Mui-selected': {
     color: 'white',
     fontWeight:700
 },
  '& .Mui-disabled': {
    color: '#191970',
    fontWeight:700
  }
  ,
  '& .MuiPaginationItem-icon': {
    fontSize:'30px',
    color: '#191970',
    fontWeight:700
  }
  
}}
          
          //showFirstButton
           />
  
  </>}
   

          {/*  <PaginationBoot 
          
          itemClass="page-item"
          linkClass="page-link"
          totalItemsCount={filteredRobots.length}
          onChange={handlePageChange}
          activePage={setCurrentPage}
          forceSelected={setCurrentPage}
          itemsCountPerPage={15}
          pageRangeDisplayed={4}
          prevPageText="‹"
          nextPageText="›"
          firstPageText="«"
          lastPageText="»"
           
/> 

*/ }


</div>


</div>
</div>


</>
    )








    
    }

<Grid item xs={3}>
    
    </Grid>   
      </Grid> 
     
<Footer />


 </>
  )
}

 
