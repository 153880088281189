import React, { useState,useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link,useLocation } from "react-router-dom";
import Container from '@mui/material/Container';
import Footer from '../containers/Footer';


import {
  AppBar,
  Button,
  Tab,
  Tabs,
  Grid,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

 import homeiconblue from '../iconimg/homeiconblue.png';
import insertnew from '../iconimg/insertnew.png';
import addblood from '../iconimg/addblood.png';
import bloodlogin from '../iconimg/bloodlogin.png';
import logout from '../iconimg/logout.png';




 
import DocState from '../containers/DocState';
import AppBlood from '../containers/AppBlood';


import appico from '../iconimg/appico.png';
import downapp from '../iconimg/downapp.png';


import iconbarleft from '../iconimg/iconbarleft.png';
import pharmacy from '../iconimg/pharmacy.png';
import blooddon from '../iconimg/blooddon.png';
import moresearch from '../iconimg/moresearch.png';
 
 


import Box from '@mui/material/Box';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
 
import { createTheme, ThemeProvider } from '@mui/material/styles';

import AddBusinessRoundedIcon from "@mui/icons-material/AddBusinessRounded";
import DrawerComp from "./Drawer";



import './amirifont.css';



const Header = () => {
  const [value, setValue] = useState(null); //select default tab
  const [value2, setValue2] = useState(0);

 /* const usePathname = () => {
    const location = useLocation();
    return location.pathname;
  }*/

  const openMyWindow = () => {
    //window.open("/Doc", "Popup","toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30")
    ///window.open("/Doc", '_system'); 
  }


  const [hidepage, sethidepage] = useState(true);

  const location = useLocation();
  console.log(location.pathname);

  useEffect(() => {
    if(sessionStorage.getItem("ID")==null || sessionStorage.getItem("ID")==undefined || sessionStorage.getItem("ID")==""){
      sethidepage(true)
    }else{
      sethidepage(false)
    }

    ////////let path = props.location.pathname;
    let path = location.pathname;
   
    ///alert('location.pathname'+location.pathname);
    console.log('location.pathname'+location.pathname);
     
    if (path === "/Don" && value !== 1) {
      setValue(1);
    }
    else if ((path === "/Doc" || path === "/") && value !== 0) setValue(0);
    ///else if (path === "/More" && value !== 2) setValue(2);
    else if (path === "/RegisterDoc" && value !== 3) setValue(3);
    else if (path === "/RegisterDon" && value !== 4) setValue(4);
    else if (path === "/UpdateRegisterDon" && value !== 4) {
      //alert('location.pathname'+location.pathname);
      setValue(4);
    }
    else if (path === "/loginDon" && value !== 5) setValue(5);

    
   
  }, [value,]);


   
  const handleChangeTabs1 = (event, newValue) => {
    setValue(newValue);
  };


  const theme = useTheme();
  console.log(theme);
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  console.log(isMatch);

   
  const themeAmiri = createTheme({  
    typography: {
      fontFamily: 
        '"Amiri", serif', 
        fontSize: 12,
         

    },
  });

  const themeRoboto = createTheme({  
    typography: {
      fontFamily: 
        '"Roboto Slab", serif', 
        fontSize: 12,
         

    },
  });

 
  const themeChanga = createTheme({  
    typography: {
      fontFamily: 
        '"Tajawal", sans-serif', 
        fontSize: 16
         

    },
  });

  
  return (
      ///select first tab

    <>
          {isMatch ? (
            <>
              <Box
              sx={{mb:'20px'}}
              display="flex"
              justifyContent="center"
              alignItems="center" >
              <DrawerComp />
              
              </Box> 
                        
                        
 {(location.pathname=="/" || location.pathname=="" || location.pathname==null) ? (<> 
                        
                        
            <Box
            sx={{
          
              mt:'-45px'
              
            }}
  display="flex"
  justifyContent="center"
  alignItems="center"
  
>
             

            <List     sx={{ width: '100%',  bgcolor: 'background.paper'
 }}>
      <ListItem  component={Link} to="/Doc" alignItems="flex-start"  
             >
            
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src={iconbarleft}
          sx={{ width: 65, height: 65 }} />
        </ListItemAvatar>
        <ListItemText

           primary={<Typography
            theme={themeChanga}
            sx={{  textAlign: 'right',mr:2  }}
              style={{ color: '#063970',fontWeight:900,fontSize:22 }}>أطباء، صيدليات..</Typography>}

          secondary={
            <React.Fragment>
              <Typography
              theme={themeRoboto}
              
               variant="h6"
               align="left"
                sx={{ display: 'inline',fontWeight:900,fontSize:18 }}
                component="span"
               // variant="body2"
                color='#063970'
              >
                ..Médecins, pharmacies
              </Typography >
               
            </React.Fragment>
          }
        />
      </ListItem>
      
      <Divider variant="inset" component="li" style={{width:'100%'}} />

      <ListItem  component={Link} to="/RegisterDoc"  alignItems="flex-start"
       
        style={{  justifyContent: "center", display: "flex", backgroundColor:'#80808017' }} >
  
        <Grid   
          container
          spacing={0}
          direction="row"
          alignItems="center"
          style={{width: "fit-content"}}
          justifyContent="center">
  
          
          <ListItemAvatar   style={{ justifyContent: "center", display: "flex" }}>
            <Avatar alt="Remy Sharp" src={insertnew}
            sx={{ width: 30, height: 30,  }} />
          </ListItemAvatar>
          <ListItemText
          
          
             primary={<Typography
              theme={themeChanga}
              sx={{  textAlign: 'right',mr:-1 }}
               variant="h5" type="body2" style={{ color: '#063970',fontWeight:900,fontSize:18 }}>إضافة معلومات جديدة</Typography>}
          />
  
   
  </Grid>
        </ListItem>
  
   
        <Divider variant="inset" component="li" style={{width:'100%'}} />



      <ListItem component={Link} to="/Don" alignItems="flex-start"
        >
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src={blooddon}
          sx={{ width: 65, height: 65 }} />
        </ListItemAvatar>
        <ListItemText
        
           primary={<Typography
            theme={themeChanga}
            sx={{  textAlign: 'right',mr:2  }}
             variant="h5" type="body2" style={{ color: '#063970',fontWeight:900,fontSize:22 }}> متبرعين بالدم </Typography>}

          secondary={
            <React.Fragment>
              <Typography
              theme={themeRoboto}
              
               variant="h6"
               align="left"
                sx={{ display: 'inline',fontWeight:900,fontSize:18 }}
                component="span"
               // variant="body2"
                color='#063970'
              >
                Banque de sang
              </Typography >
               
            </React.Fragment>
          }
        />
      </ListItem>




{hidepage? <>


      <Divider variant="inset" component="li" style={{width:'100%'}} />

      <div style={{display: 'flex', flexDirection: 'row'}}>
      
      <ListItem  component={Link} to="/RegisterDon"  alignItems="flex-start"
        
         style={{  justifyContent: "center", display: "flex", backgroundColor:'#80808017' }} >

      <Grid   
        container
        spacing={0}
        direction="row"
        alignItems="center"
        style={{width: "fit-content"}}
        justifyContent="center">

        
        <ListItemAvatar   style={{ justifyContent: "center", display: "flex" }}>
          <Avatar alt="Remy Sharp" src={addblood}
          sx={{ width: 30, height: 30,  }} />
        </ListItemAvatar>
        <ListItemText
        
        
           primary={<Typography
            theme={themeChanga}
            sx={{  textAlign: 'right',mr:-1 }}
             variant="h5" type="body2" style={{ color: '#063970',fontWeight:900,fontSize:18 }}> متبرع جديد </Typography>}
        />

 
</Grid>
      </ListItem>

 

      <ListItem maxWidth={true} component={Link} to="/loginDon"  alignItems="flex-start"
 
      style={{  justifyContent: "center", display: "flex", backgroundColor:'#80808017' ,
      borderRight: '0.1em solid #00000029', padding: '0.5em' }} >
 
      <Grid   
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        style={{width: "fit-content"}}
        >

        
        <ListItemAvatar   >
          <Avatar alt="Remy Sharp" src={bloodlogin}
          sx={{ width: 30, height: 30,  }} />
        </ListItemAvatar>
        <ListItemText

 
           primary={<Typography
            theme={themeChanga}
            sx={{  textAlign: 'right',mr:-1 }}
             variant="h5" type="body2" style={{ color: '#063970',fontWeight:900,fontSize:18 }}> دخول المتبرع </Typography>}
        />

 
</Grid>
      </ListItem>


 </div>



 </>:<>




 <Divider variant="inset" component="li" style={{width:'100%'}} />

<div style={{display: 'flex', flexDirection: 'row'}}>

<ListItem  component={Link} to="/UpdateRegisterDon"  alignItems="flex-start"
  
style={{  justifyContent: "center", display: "flex", backgroundColor:'#80808017' }} >

<Grid   
  container
  spacing={0}
  direction="row"
  alignItems="center"
  style={{width: "fit-content"}}
  justifyContent="center">

  
  <ListItemAvatar   style={{ justifyContent: "center", display: "flex" }}>
    <Avatar alt="Remy Sharp" src={addblood}
    sx={{ width: 30, height: 30,  }} />
  </ListItemAvatar>
  <ListItemText
  
  
     primary={<Typography
      theme={themeChanga}
      sx={{  textAlign: 'right',mr:-1 }}
       variant="h5" type="body2" style={{ color: '#063970',fontWeight:900,fontSize:18 }}> معلوماتك </Typography>}
  />


</Grid>
</ListItem>



<ListItem maxWidth={true} component={Link} to="/loginDon"  alignItems="flex-start"
  
style={{  justifyContent: "center", display: "flex", backgroundColor:'#80808017',
borderRight: '0.1em solid #00000029', padding: '0.5em' }} >

<Grid   
  container
  direction="row"
  justifyContent="center"
  alignItems="stretch"
  style={{width: "fit-content"}}
  >

  
  <ListItemAvatar   >
    <Avatar alt="Remy Sharp" src={logout}
    sx={{ width: 30, height: 30,  }} />
  </ListItemAvatar>
  <ListItemText
  
  
     primary={<Typography
      theme={themeChanga}
      sx={{  textAlign: 'right',mr:-1 }}
       variant="h5" type="body2" style={{ color: '#063970',fontWeight:900,fontSize:18 }}> خروج  </Typography>}
  />


</Grid>
</ListItem>


</div>


</>}



 <Divider variant="inset" component="li" style={{width:'100%'}} />

     {/*  <ListItem  alignItems="flex-start"   >
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src={pharmacy}
          sx={{ width: 65, height: 65 }} />
        </ListItemAvatar>
        <ListItemText
        
           primary={<Typography
            theme={themeChanga}
            sx={{  textAlign: 'right',mr:2  }}
             variant="h5" type="body2" style={{ color: '#063970',fontWeight:900,fontSize:22 }}> صيدليات </Typography>}

          secondary={
            <React.Fragment>
              <Typography
              theme={themeRoboto}
              
               variant="h6"
               align="left"
                sx={{ display: 'inline',fontWeight:900,fontSize:18 }}
                component="span"
               // variant="body2"
                color='#063970'
              >
                Pharmacies
              </Typography >
               
            </React.Fragment>
          }
        />
      </ListItem>
      */}

   {/*   
<Divider variant="inset" component="li" style={{width:'100%'}} />
      <ListItem component={Link} to="/More" alignItems="flex-start" 
         >
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src={moresearch}
          sx={{ width: 65, height: 65 }} />
        </ListItemAvatar>
        <ListItemText
        
           primary={<Typography
            theme={themeChanga}
            sx={{  textAlign: 'right',mr:2  }}
             variant="h5" type="body2" style={{ color: '#063970',fontWeight:900,fontSize:22 }}> شاهد المزيد ... </Typography>}

          secondary={
            <React.Fragment>
              <Typography
              theme={themeRoboto}
              
               variant="h6"
               align="left"
                sx={{ display: 'inline',fontWeight:900,fontSize:18 }}
                component="span"
               // variant="body2"
                color='#063970'
              >
                ...Voir plus
              </Typography >
               
            </React.Fragment>
          }
        />
      </ListItem>
*/} 


      <Divider variant="inset" component="li" style={{width:'100%'}} />
      <a style={{ textDecoration: 'none'}}  
        href="https://play.google.com/store/apps/details?id=dz.sebrou.docts_guide.app">
      <ListItem  alignItems="flex-start" 
      style={{  backgroundColor:'#80808017' }}
       >
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src={downapp}
          sx={{ width: 65, height: 65,p:'2px' }} />
        </ListItemAvatar>
        <ListItemText
        
           primary={<Typography
            theme={themeChanga}
            sx={{  textAlign: 'right',mr:2  }}
             variant="h5" type="body2" style={{ color: '#063970',fontWeight:900,fontSize:22 }}> تحميل التطبيق </Typography>}

          secondary={
            <React.Fragment>
              <Typography
              theme={themeRoboto}
              
               variant="h6"
               align="left"
                sx={{ display: 'inline',fontWeight:900,fontSize:18 }}
                component="span"
               // variant="body2"
                color='#063970'
              >
                Télécharger l'application
              </Typography >
               
            </React.Fragment>
          }
        />
      </ListItem>
      </a>
      <Divider variant="inset" component="li" style={{width:'100%'}} />
          


    </List>

    </Box>
<br></br>
<br></br>
<br></br>
    <Footer />

                        
    </>)
                        
                        :(<></>)}







            </>
          ) : (
            <>
<Box
            sx={{
          
              mt:20
              
            }}
  display="flex"
  justifyContent="center"
  alignItems="center"
  
>



<React.Fragment>
      <AppBar sx={{ background: "#063970" }}>
        <Toolbar>

        <Grid  sx={{  mr:2,pl:0 }} xs={2} container alignItems='center' justify='center' alignContent='center'>
        

  <Grid item xs={12} align='center'>
  <a style={{ textDecoration: 'none'}}  
        href="https://play.google.com/store/apps/details?id=dz.sebrou.docts_guide.app">

    <Avatar
        alt="تطبيق أطباء الجزائر"
        src={appico}
        sx={{ width: 30, height: 30 }}
      />
       </a>
    </Grid>
    <Grid item xs={12} align='center'>
    <a style={{ textDecoration: 'none'}}  
        href="https://play.google.com/store/apps/details?id=dz.sebrou.docts_guide.app">
    <Typography  variant="h6" noWrap sx={{ color:'white',mt:'3px'
    , fontFamily: 
    '"Tajawal", sans-serif', 
    fontSize: 16
    ,fontWeight:700 }}  // flexGrow: 1,
          theme={themeChanga} component="div" >
             أطباء الجزائر
          </Typography>
          </a>
    </Grid>
   
    </Grid>
   


         
        <Grid   container alignItems='center' justifyContent='center' alignContent='center'>

        {hidepage? <>      
              <Tabs
                
                variant="fullWidth" //variant="scrollable" //"fullWidth"
                sx={{ flexGrow: 1, mr:0,pl:0 }}
               // indicatorColor="primary"
               TabIndicatorProps={{
                style: {
                  backgroundColor: "white"
                 }
                }}
                textColor="inherit"
                value={value}
                //onChange={(e, value) => setValue(value)}
                
                onChange={handleChangeTabs1}
 
              >
              {/*  <Tab  
               component={Link} to="/" 
               style={{minWidth:'auto'}}
               icon={<Avatar alt="Remy Sharp" src={homeiconblue}  sx={{textAlign: 'right', mr:0, pr:0, width: 30, height: 30 }}
               />}
               label={<Typography
                theme={themeChanga}
                sx={{  textAlign: 'right',mr:0  ,fontSize: 12  }}
                   type="body2" style={{ color: '#ffffff' }}>الرئيسية</Typography>} 
               />
*/}
              <Tab 
              key={"1"}
              component={Link} to="/"
               style={{minWidth:'auto'}}
               icon={<Avatar alt="Remy Sharp" src={iconbarleft}  sx={{textAlign: 'right', mr:0, pr:0, width: 30, height: 30 }}
               />}
               label={<Typography
                theme={themeChanga}
                sx={{  textAlign: 'right',mr:0  , fontFamily: 
                '"Tajawal", sans-serif', 
                fontSize: 16
                ,fontWeight:700  }}
                   type="body2" style={{ color: '#ffffff' }}>أطباء، صيدليات..</Typography>} 
               />

              <Tab
              key={"2"} 
              component={Link} to="/Don"  
               style={{minWidth:'auto'}}
               icon={<Avatar alt="Remy Sharp" src={blooddon}  sx={{textAlign: 'right', mr:0, pr:0, width: 30, height: 30 }}
               />}
               label={<Typography
                theme={themeChanga}
                sx={{  textAlign: 'right',mr:0  , fontFamily: 
                '"Tajawal", sans-serif', 
                fontSize: 16
                ,fontWeight:700  }}
                   type="body2" style={{ color: '#ffffff' }}>المتبرعين بالدم</Typography>} 
               />
{/* 
                <Tab 
                key={"3"}  
               style={{minWidth:'auto'}}
               icon={<Avatar alt="Remy Sharp" src={pharmacy}  sx={{textAlign: 'right', mr:0, pr:0, width: 30, height: 30 }}
               />}
               label={<Typography
                theme={themeChanga}
                sx={{  textAlign: 'right',mr:0  ,fontSize: 12  }}
                   type="body2" style={{ color: '#ffffff' }}>الصيدليات</Typography>} 
               />
*/ }
{/*
                <Tab  
                key={"3"} 
                component={Link} to="/More" 
               style={{minWidth:'auto'}}
               icon={<Avatar alt="Remy Sharp" src={moresearch}  sx={{textAlign: 'right', mr:0, pr:0, width: 30, height: 30 }}
               />}
               label={<Typography
                theme={themeChanga}
                sx={{  textAlign: 'right',mr:0  , fontFamily: 
                '"Tajawal", sans-serif', 
                fontSize: 16
                ,fontWeight:700  }}
                   type="body2" style={{ color: '#ffffff' }}>شاهد المزيد...</Typography>} 
               />

               */}
<Tab  
                key={"4"} 
                component={Link} to="/RegisterDoc" 
               style={{minWidth:'auto'}}
               icon={<Avatar alt="Remy Sharp" src={insertnew}  sx={{textAlign: 'right', mr:0, pr:0, width: 30, height: 30 }}
               />}
               label={<Typography
                theme={themeChanga}
                sx={{  textAlign: 'right',mr:0  , fontFamily: 
                '"Tajawal", sans-serif', 
                fontSize: 16
                ,fontWeight:700  }}
                   type="body2" style={{ color: '#ffffff' }}>معلومات جديدة</Typography>} 
               />


              <Tab 
              component={Link} to="/RegisterDon" 
              key={"5"}
               style={{minWidth:'auto'}}
               icon={<Avatar alt="Remy Sharp" src={addblood}  sx={{textAlign: 'right', mr:0, pr:0, width: 30, height: 30 }}
               />}
               label={<Typography
                theme={themeChanga}
                sx={{  textAlign: 'right',mr:0 
                , fontFamily: 
                '"Tajawal", sans-serif', 
                fontSize: 16
                ,fontWeight:700  }}
                   type="body2" style={{ color: '#ffffff' }}>متبرع جديد</Typography>} 
               />

<Tab 
              component={Link} to="/loginDon" 
              key={"6"}
                
               icon={<Avatar alt="Remy Sharp" src={bloodlogin}  sx={{textAlign: 'right', mr:0, pr:0, width: 30, height: 30 }}
               />}
               label={<Typography
                theme={themeChanga}
                sx={{  textAlign: 'right',mr:0 
                , fontFamily: 
                '"Tajawal", sans-serif', 
                fontSize: 16
                ,fontWeight:700  }}
                   type="body2" style={{ color: '#ffffff' }}> دخول المتبرع </Typography>} 
               />

</Tabs>





</>:<>

                      <Tabs
                
                variant="fullWidth" //variant="scrollable" //"fullWidth"
                sx={{ flexGrow: 1, mr:0,pl:0 }}
               // indicatorColor="primary"
               TabIndicatorProps={{
                style: {
                  backgroundColor: "white"
                 }
                }}
                textColor="inherit"
                value={value}
                //onChange={(e, value) => setValue(value)}
                
                onChange={handleChangeTabs1}
 
              >
              {/*  <Tab  
               component={Link} to="/" 
               style={{minWidth:'auto'}}
               icon={<Avatar alt="Remy Sharp" src={homeiconblue}  sx={{textAlign: 'right', mr:0, pr:0, width: 30, height: 30 }}
               />}
               label={<Typography
                theme={themeChanga}
                sx={{  textAlign: 'right',mr:0  ,fontSize: 12  }}
                   type="body2" style={{ color: '#ffffff' }}>الرئيسية</Typography>} 
               />
*/}
              <Tab 
              key={"1"}
              component={Link} to="/"
               style={{minWidth:'auto'}}
               icon={<Avatar alt="Remy Sharp" src={iconbarleft}  sx={{textAlign: 'right', mr:0, pr:0, width: 30, height: 30 }}
               />}
               label={<Typography
                theme={themeChanga}
                sx={{  textAlign: 'right',mr:0  , fontFamily: 
                '"Tajawal", sans-serif', 
                fontSize: 16
                ,fontWeight:700  }}
                   type="body2" style={{ color: '#ffffff' }}>أطباء، صيدليات..</Typography>} 
               />

              <Tab
              key={"2"} 
              component={Link} to="/Don"  
               style={{minWidth:'auto'}}
               icon={<Avatar alt="Remy Sharp" src={blooddon}  sx={{textAlign: 'right', mr:0, pr:0, width: 30, height: 30 }}
               />}
               label={<Typography
                theme={themeChanga}
                sx={{  textAlign: 'right',mr:0  , fontFamily: 
                '"Tajawal", sans-serif', 
                fontSize: 16
                ,fontWeight:700  }}
                   type="body2" style={{ color: '#ffffff' }}>المتبرعين بالدم</Typography>} 
               />
{/* 
                <Tab 
                key={"3"}  
               style={{minWidth:'auto'}}
               icon={<Avatar alt="Remy Sharp" src={pharmacy}  sx={{textAlign: 'right', mr:0, pr:0, width: 30, height: 30 }}
               />}
               label={<Typography
                theme={themeChanga}
                sx={{  textAlign: 'right',mr:0  ,fontSize: 12  }}
                   type="body2" style={{ color: '#ffffff' }}>الصيدليات</Typography>} 
               />
*/ }
{/* 
                <Tab  
                key={"3"} 
                component={Link} to="/More" 
               style={{minWidth:'auto'}}
               icon={<Avatar alt="Remy Sharp" src={moresearch}  sx={{textAlign: 'right', mr:0, pr:0, width: 30, height: 30 }}
               />}
               label={<Typography
                theme={themeChanga}
                sx={{  textAlign: 'right',mr:0  , fontFamily: 
                '"Tajawal", sans-serif', 
                fontSize: 16
                ,fontWeight:700  }}
                   type="body2" style={{ color: '#ffffff' }}>شاهد المزيد...</Typography>} 
               />
               
*/}
<Tab  
                key={"4"} 
                component={Link} to="/RegisterDoc" 
               style={{minWidth:'auto'}}
               icon={<Avatar alt="Remy Sharp" src={insertnew}  sx={{textAlign: 'right', mr:0, pr:0, width: 30, height: 30 }}
               />}
               label={<Typography
                theme={themeChanga}
                sx={{  textAlign: 'right',mr:0  , fontFamily: 
                '"Tajawal", sans-serif', 
                fontSize: 16
                ,fontWeight:700  }}
                   type="body2" style={{ color: '#ffffff' }}>معلومات جديدة</Typography>} 
               />


              <Tab 
              component={Link} to="/UpdateRegisterDon" 
              key={"5"}
               style={{minWidth:'auto'}}
               icon={<Avatar alt="Remy Sharp" src={addblood}  sx={{textAlign: 'right', mr:0, pr:0, width: 30, height: 30 }}
               />}
               label={<Typography
                theme={themeChanga}
                sx={{  textAlign: 'right',mr:0 
                , fontFamily: 
                '"Tajawal", sans-serif', 
                fontSize: 16
                ,fontWeight:700  }}
                   type="body2" style={{ color: '#ffffff' }}> معلوماتك </Typography>} 
               />

<Tab 
              component={Link} to="/loginDon" 
              key={"6"}
                
               icon={<Avatar alt="Remy Sharp" src={logout}  sx={{textAlign: 'right', mr:0, pr:0, width: 30, height: 30 }}
               />}
               label={<Typography
                theme={themeChanga}
                sx={{  textAlign: 'right',mr:0 
                , fontFamily: 
                '"Tajawal", sans-serif', 
                fontSize: 16
                ,fontWeight:700  }}
                   type="body2" style={{ color: '#ffffff' }}> خروج </Typography>} 
               />

</Tabs>

</>}
 


             
               
               
               


               </Grid>


                

 


              </Toolbar>
      </AppBar>
    </React.Fragment>

    </Box>
            {/* 
            <React.Fragment>
      <AppBar sx={{ background: "#063970" }}>
        <Toolbar>
          <AddBusinessRoundedIcon sx={{ transform: "scale(2)" }} />
          <Typography sx={{ fontSize: "2rem", paddingLeft: "5%" }}>
                Shoppee
              </Typography>
              <Tabs
                sx={{ marginLeft: "auto" }}
                indicatorColor="secondary"
                textColor="inherit"
                value={value}
                onChange={(e, value) => setValue(value)}
              >
                <Tab label="Products" />
                <Tab label="Services" />
                <Tab label="About Us" />
                <Tab label="Contact" />
              </Tabs>
              <Button sx={{ marginLeft: "auto" }} variant="contained">
                Login
              </Button>
              <Button sx={{ marginLeft: "10px" }} variant="contained">
                SignUp
              </Button>
              </Toolbar>
      </AppBar>
    </React.Fragment>

    */}



            </>
          )}

          
          </>
  );
};

export default Header;

