import React, { useState,useEffect, useRef }  from 'react';
import Header from '../containers/Header';
import Footer from '../containers/Footer';
import { CacheProvider } from "@emotion/react";
import { prefixer } from "stylis";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from "@material-ui/core/styles";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useNavigate ,useLocation} from 'react-router-dom';
import { MuiThemeProvider } from "@material-ui/core/styles";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import FormHelperText from '@mui/material/FormHelperText';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import isEmail from 'validator/lib/isEmail';
import MapPicker from 'react-google-map-picker'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';

import SaveIcon from '@mui/icons-material/Save';
import { Typography } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from '@mui/icons-material/Send';
import axios from "axios";

import Card from 'react-bootstrap/Card';
import Avatar from '@mui/material/Avatar';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@material-ui/core/IconButton';

 import InputMask from 'react-input-mask';
import { red } from '@mui/material/colors';
import { useSearchParams } from "react-router-dom";

const DefaultLocation = { lat: 29.0760704, lng: 1.33683208};

/////////36.16639343 //////1.33683208
/////29.0760704,2.1429984
const DefaultZoom = 5;


let splists=[];
let Wlylists=[];
let Bldlists=[];
let selectedWlyOption=null;
let BldOnly=[];
let selectBldValue=0;
let selectWlyValue=0;
let userinfos=[];
let valuebldfrominfo=0;
      
 // Create rtl cache
 const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

// Create ltr cache
const cacheLtr = createCache({
  key: 'muiltr',
  stylisPlugins: [prefixer, rtlPlugin],
});

      
  const defaultColor = "#ff0000";
  const hoverColor = "#0000ff";
  const focusColor = "#00ff00";

  const themetextfieldnew = createTheme({
    overrides: {
      MuiOutlinedInput: {
        root: {
          "&:hover $notchedOutline": {
            borderColor: hoverColor
          },
          "&$focused $notchedOutline": {
            borderColor: focusColor
          }
        },
        notchedOutline: {
          borderColor: defaultColor
        }
      }
    },
    fieldset: {
      fontFamily: 
        '"Changa", sans-serif', 
        fontSize: 45,
         

    } 
  });




const textmaskphone1Custom = React.forwardRef(function textmaskphone1Custom(props, ref) {
    const { onChange, ...other } = props;
    
    return (
        <>       

        

         <CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box >

<Grid container >
       

        <Grid item xs={12}>



      <IMaskInput
      
        {...other}
        mask="0000000000"
        ///value=""
        unmask={false} // true|false|'typed'
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
        // input props also available
        placeholder='مثال: 069988776655'
      />


</Grid>
      </Grid>

</Box>
</MuiThemeProvider>


</CacheProvider>


</>


    );
  });
  
 
  textmaskphone1Custom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };


  const textmaskphone2Custom = React.forwardRef(function textmaskphone2Custom(props, ref) {
    const { onChange, ...other } = props;

   return (
        <>       
        
         <CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box >

<Grid container >
       

        <Grid item xs={12}>



      <IMaskInput
      
        {...other}
        mask="0000000000"
        ///value=""
        unmask={false} // true|false|'typed'
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
        // input props also available
        placeholder='مثال: 069988776655'
      />


</Grid>
      </Grid>

</Box>
</MuiThemeProvider>


</CacheProvider>


</>


    );
  });
  


  textmaskphone2Custom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };













export default function UpdateDoc() {

    const [options_Sp, setoptions_Sp] = useState([]);
    const [valuesp, setvaluesp] = useState([]);
    const [options_Wly, setoptions_Wly] = useState([]);
    const [valuewly, setvaluewly] = useState([]);
    const [valuebld, setvaluebld] = useState([]);

    const [options_Bld, setoptions_Bld] = useState([]);
    const [valuefullname, setvaluefullname] = React.useState('');
    const [valueadress, setvalueadress] = React.useState('');
    const [valuemoreinfo, setvaluemoreinfo] = React.useState('');

    const [valueemail, setValueemail] = useState('');
    const [isValidemail, setIsValidemail] = useState(false);

    const refbtnsubmit = useRef(null);

     
     const [valuephone1, setvaluephone1] = React.useState(0);
    const [valuephone2, setvaluephone2] = React.useState(0);




    useEffect(() => {

  
      if(isEmail(valueemail)) {
        setIsValidemail(true);  
        console.log(valueemail+"   yes email");            
     } else {
        setIsValidemail(false); 
        console.log(valueemail+"   not email");                         
     }
    
      
    }, [valueemail]);

    
    const reffullname = useRef(null);
    const refadress = useRef(null);
    const refmoreinfo = useRef(null);

    
    const refphone1 = useRef(null);
    const refphone2 = useRef(null);
    const refemail = useRef(null);

    /*const [valuesphone1, setValuesphone1] = React.useState({
      textmaskphone1: '',
      numberformat: '1320',
    });


    const [valuesphone2, setValuesphone2] = React.useState({
      textmaskphone2: '',
      numberformat: '1320',
    });*/


    
  const handleChangeemail = event => {
    const val = event.target.value;                
    setValueemail(val); 
    
    /////handleChangeemail(val, isValidemail);
 }

    /*const handleChangephone1 = (event) => {
      setValuesphone1({
        ...valuesphone1,
        [event.target.name]: event.target.value,
      });
    };

    const handleChangephone2 = (event) => {
      setValuesphone2({
        ...valuesphone2,
        [event.target.name]: event.target.value,
      });
    };*/

    const ALPHA_NUMERIC_DASH_REGEX = /[a-zA-Zء-ي\s\p{N}]/; ///^[\p{Arabic}\s\p{N}]+$ //////[a-zA-Zء-ي]/
   // const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9-]+$/;

    const onwlyChanged = (event, values) => {
    
        console.log('selectedWlyOption value= '+values.value)
          setvaluewly(values);
     
      }
      const onbldChanged = (event, values) => {
         setvaluebld(values)
        console.log('values.value onbldChanged'+values)
      }




  const onSpChanged = (event, values) => {
    if (values !== undefined && values !== null) {
      setvaluesp(values);
    }
    

    
  }

  function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  
  
   let query = useQuery();


  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
        // Default transform is "translate(14px, 20px) scale(1)""
        // This lines up the label with the initial cursor position in the input
        // after changing its padding-left.
        transform: "translate(34px, 20px) scale(1);",
        fontFamily: 
        '"Changa", sans-serif',
              fontSize: 25,
              direction: "rtl"
      }
    },
    inputRoot: {
      
      color: "#191970",

      
      // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        fontFamily: '"Tajawal", sans-serif', 
        fontWeight:700,
        color: "#191970",
        // Default left padding is 6px
        paddingLeft: 26
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "#add8e6"
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "red"
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "purple"
      }
    }
  }));
    const classes = useStyles();

    // Create rtl cache
   const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
  });

  const Num_doct =query.get('ninfo');

    useEffect(() => {
  
 
      


      ///////////////////////
  
  
      fetch('https://doctorsdz.machrou3.com/user/getinfoAppDoctorbyID/'+Num_doct)
      .then(response=> response.json())
      .then(getinfoAppBlood => {


        ////////begin fetch all info //////////////
       
        userinfos=getinfoAppBlood;
         /// alert(userinfos[0].Name_doct)
        setvaluefullname(userinfos[0].Name_doct);
        setvalueadress(userinfos[0].Adress_doct);
  
        ///"latcor":null,"loncor":null
        if(!(userinfos[0].latcor ==null || userinfos[0].latcor == 'null' || userinfos[0].latcor ==0 || userinfos[0].latcor ==undefined
        || userinfos[0].loncor ==null || userinfos[0].loncor == 'null' || userinfos[0].loncor ==0 || userinfos[0].loncor ==undefined
        )){
           //alert('acctept'+userinfos[0].latcor+'__'+userinfos[0].loncor);
          setDefaultLocation({lat:parseFloat(userinfos[0].latcor),lng:parseFloat(userinfos[0].loncor)});
          ////36.16639343     1.33683208
         /// setDefaultLocation({lat:36.16639343,lng:1.33683208});
          setZoom(18);
          setLocation({lat:parseFloat(userinfos[0].latcor),lng:parseFloat(userinfos[0].loncor)});
          setsavelocation(true);
        }
        
   
        
  
      
      

    
     fetch('https://doctorsdz.machrou3.com/user/getsp4appdoc')
     .then(response=> response.json())
     .then(getsp4appdoc => {
       
      splists=getsp4appdoc;
 
      const newSelect=getsp4appdoc;
      /*
      const newSelect = splists.filter(
        item => (
          
           item.num_sp ==21
  || item.num_sp ==22
  || item.num_sp ==25
  || item.num_sp ==26
  || item.num_sp ==27
  || item.num_sp ==28
  || item.num_sp ==29
  || item.num_sp ==30
  || item.num_sp ==31
  || item.num_sp ==32
  || item.num_sp ==34
  || item.num_sp ==36
  || item.num_sp ==40
  || item.num_sp ==42
  || item.num_sp ==44
  || item.num_sp ==52
  || item.num_sp ==53
  || item.num_sp ==55
  || item.num_sp ==62
  || item.num_sp ==73
  || item.num_sp ==74
  || item.num_sp ==79
  || item.num_sp ==80
  || item.num_sp ==81
  || item.num_sp ==82
  || item.num_sp ==83
  || item.num_sp ==84
  || item.num_sp ==85
  || item.num_sp ==88
  || item.num_sp ==90
  
                )
      );
  */
  
  const options_Splist = newSelect.map(function (splists,i) {
    return { value: splists.num_sp, label: (i+1)+'- ' +splists.name_sp +' ('+splists.name_spfr+')', indexvalue:i, name_sp:splists.name_sp, name_spfr:splists.name_spfr};
    }) 
   
     //console.log(options_Sp);
    setoptions_Sp(options_Splist);
    //setvaluesp(options_Splist[0]);
     

     const  spName = options_Splist.find(splistsaa =>{
      return splistsaa.value ==userinfos[0].Num_sp;
    })
    if( spName !=null){
      
      setvaluesp(spName)
    }



     });
  
    
 


     fetch('https://doctorsdz.machrou3.com/user/getBaladiya4appdoc')
     .then(response=> response.json())
     .then(getBaladiya4appdoc => {
      
     Wlylists=getBaladiya4appdoc;
     Bldlists=getBaladiya4appdoc;
   
  
     /////////////Wilayets///////////////////
  
  const WlyOnly = Wlylists.filter(Wlylists =>{
    return Wlylists.wlycaptal !==null;
    })
    
    const options_Wlylist =WlyOnly.sort(function(a, b){
    if(Number(a.MatWilaya) < Number(b.MatWilaya)) return -1;  // Ascending Order
    if(Number(a.MatWilaya) > Number(b.MatWilaya)) return 1;   // Descending Order  
    return 0; // Default
    }).map(function (Wlylists,i) {
    return { value: Wlylists.MatWilaya, label:  Wlylists.MatWilaya+'- ' + Wlylists.NOM+' ('+Wlylists.Nomfr+')',indexvalue:i};
    }) 
    setoptions_Wly(options_Wlylist);
  
    ///setvaluewly(options_Wlylist[0]);
    

    const  wlyName = options_Wlylist.find(options_Wlylistaa =>{
      return options_Wlylistaa.value ==userinfos[0].Wilaya_doct;
    })
    if( wlyName !=null){
      
      setvaluewly(wlyName)
    }



     
     });



     valuebldfrominfo=userinfos[0].idbaladiya;
      setvaluephone1('0'+userinfos[0].Tel1_doct);
      setvaluephone2('0'+userinfos[0].Tel2_doct);

      setvaluemoreinfo(userinfos[0].description_doct);
      setValueemail(userinfos[0].Email_doct);

      
       
        /*[{"Num_doct":"25","Name_doct":"حبيبة بوعبد الله ","Num_sp":"22","Adress_doct":"الدرعام,الوحدة الصحية دوامي علي","Tel1_doct":"550771433","Tel2_doct":"0","Email_doct":"","Wilaya_doct":"3","description_doct":"","latcor":null,"loncor":null,"idbaladiya":null,"newinfo":"0","created_at":null,"updated_at":null,"iduser":"0"}]
        */
   
      })
      .catch(err => {
       console.error(err)
     })
      
   
   
   
   
   
       //////////end fetch all info////////////
    
       


  
  }, []);





  const navigate = useNavigate();

  /////////////////


  const onsubmitclicked = async (e) => {

       

    let axiosConfig = {
        headers: {
            //'Content-Type' : 'application/json; charset=UTF-8',
            ///application/x-www-form-urlencoded
            ///'Content-Type' : 'application/x-www-form-urlencoded',
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            'Accept': 'Token',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Max-Age": "86400",

         }
      };

    e.preventDefault();
    try {

      /*
      Num_doct
Name_doct
Num_sp
Adress_doct
Tel1_doct
Tel2_doct
Email_doct
Wilaya_doct
description_doct
latcor
loncor
idbaladiya
newinfo

      */
        const params = new URLSearchParams();
        
        params.append('Num_doct', Num_doct);
        params.append('Name_doct', valuefullname);
        params.append('Num_sp', valuesp.value);
        params.append('Tel1_doct', valuephone1);
        params.append('Tel2_doct', valuephone2);
        params.append('Adress_doct', valueadress);
        params.append('description_doct', valuemoreinfo);
        params.append('Email_doct', valueemail);
        params.append('Wilaya_doct', valuewly.value);
        params.append('idbaladiya', valuebld.value);
        params.append('newinfo', 1);
         
        if(savelocation){
        params.append('latcor', location.lat);
        params.append('loncor', location.lng);
        }
        else{
          params.append('latcor', 0);
          params.append('loncor', 0);
        }


        await axios.post('https://www.doctorsdz.machrou3.com/user/updatewebdoctor/'
        , params,axiosConfig)
        .then(response => {
                
          if(response.data.savedsucces){
            handleClickOpenDialog();
                
          }else{
              alert('لم يتم تسجيل المعلومات حاول مرة')
          }
               ///alert('response '+response.data.Namef)
             

              
            console.log(response)
        })
        //navigate('/dashboard');

    } catch (error) {
        
        if (error.response) {
            
            console.log(error.response)
            return 
        }

    }



   
}

  ////////////////////

  



useEffect(() => {
    if (options_Wly.length!=0) {

        ////alert('wly loaded');
      
        selectWlyValue=valuewly.value;
    
        selectedWlyOption=valuewly;


       /* const valuewly_get = query.get('valuewly');
        if(valuewly_get !=null && !isNaN(Number(valuewly_get)) ){
        
        
    
          if(options_Wly.length !=0 ){
           /// alert(valuewly_get) ;
            const  WlyName = options_Wly.find(options_Wly =>{
              return options_Wly.value ==valuewly_get;
            })
            if( WlyName !=null){
             setvaluewly(WlyName)
            }else{
              setvaluewly(options_Wly[0]);
            }
          } 
    
    
        }*/



    {BldOnly = Bldlists.filter(Bldlists =>{
      

      return Bldlists.MatWilaya ==selectedWlyOption.value;
    })}

    console.log('BldOnly value= '+BldOnly.length)

    /////////////Baladia///////////////////
{setoptions_Bld (BldOnly.sort(function(a, b){
if(Number(a.ID) < Number(b.ID)) return -1;  // Ascending Order
if(Number(a.ID) > Number(b.ID)) return 1;   // Descending Order  
return 0; // Default
}).map(function (Bldlists,i) {
return { value: Bldlists.ID, label: (i+1)+'- ' + Bldlists.NOM+' ('+Bldlists.Nomfr+')',indexvalue:i};
}))}

    }
     
    
    
  }, [valuewly]);


  useEffect(() => {

    if (options_Bld.length != 0) {
     
       // alert('options_Bld loaded'+options_Bld[0].label);
        

        ////const valuebld_get = query.get('valuebld');
        const valuebld_get = valuebldfrominfo;
        if(valuebld_get !=null && !isNaN(Number(valuebld_get)) ){
    
          if(options_Bld.length !=0 ){
           /// alert(valuebld_get) ;
            const  bldName = options_Bld.find(options_Bld =>{
              return options_Bld.value ==valuebld_get;
            })
            if( bldName !=null){
              selectBldValue=valuebld_get;
             setvaluebld(bldName)
            }else{
              selectBldValue=0;
              setvaluebld(options_Bld[0]);
            }
          } 
    
    
        }else{
          selectBldValue=0;
        setvaluebld(options_Bld[0]);
        }
    
    
}
    
}, [options_Bld]);




////location picker/////////////

const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);

  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);
  const [savelocation, setsavelocation] = React.useState(false);


  function handleChangeLocation (lat, lng){
    setLocation({lat:lat, lng:lng});
  }
  
  function handleChangeZoom (newZoom){
    setZoom(newZoom);
  }

  function handleResetLocation(){
    setDefaultLocation({ ... DefaultLocation});
    setZoom(DefaultZoom);
  }

  function handleDeleteLocation(){
    setDefaultLocation({ ... DefaultLocation});
    setZoom(DefaultZoom);
    setsavelocation(false);
    setOpen(false);

    //alert(location.lat);
  }

  function handleSaveLocation(){
    setsavelocation(true);
    setOpen(false);
    //alert(location.lat);
  }

////////////////////////


//////////// dialog /////////////

const [open, setOpen] = React.useState(false);
const theme = useTheme();
const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

const handleClickOpen = () => {
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
};

const [OpenDialog, setOpenDialog] = useState(false);


const handleClickOpenDialog = () => {
  setOpenDialog(true);
  };
  
  
  const handleClickCloseDialog = () => {
  setOpenDialog(false);
  navigate('/Doc');
  };


/////////////////////////////
    return (
     
        <>
        
        <Header />

        { !(userinfos.length || splists.length || Wlylists.length || Bldlists.length || options_Sp.length || options_Wly.length) ?  
    
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <CircularProgress />
      </div>
 
    : 
 
    (
      <>

        <form    > 

        <div className="container" style={{marginTop:'-30px'}}>
           
           <div className="row text-center">

           <Typography
           style={{ color: "#191970", fontFamily: 
           '"Tajawal", sans-serif', 
           fontSize: 25
           ,fontWeight:700, marginBottom:'25px'

         }}
            variant="h3" gutterBottom>
           تعديل المعلومات
           
      </Typography>
      <Typography
           style={{ color: "Green", fontFamily: 
           '"Tajawal", sans-serif', 
           fontSize: 16
           ,fontWeight:700, marginBottom:'15px'

         }}
            variant="h3" gutterBottom>
         من فضلك صحح المعلومات الخاطئة و إضغط على إرسال المعلومات وسوف نتحقق منها قبل الموافقة عليها و ظهورها على التطبيق و الموقع الالكتروني.
           
      </Typography>


        {/* Autocomplement Specialite */}
      <CacheProvider value={cacheRtl}>

          <Autocomplete
            noOptionsText={'حدث الصفحة مرة أخرى'}
           classes={classes}
          id="combo-box-demo"
         value={valuesp}
         onChange={onSpChanged}
         helperText="جرب أكتب الأحرف الأولى للتخصص في خانة التخصصات "
      options={options_Sp}
      renderOption={(props, option) => {
      const { label, indexvalue,name_sp,name_spfr } = option;

      return (
        indexvalue % 2 == 0 ? 

        <span {...props}   style={{ backgroundColor: "#add8e6",color: "#191970", fontFamily: 
        '"Tajawal", sans-serif', 
        fontSize: 18
        ,fontWeight:700 }}>

          {(indexvalue  == -1 ?
          <>
            {'- ' +name_sp} <br/> {name_spfr}
             </>
            :
            <>
            {(indexvalue+1)+'- ' +name_sp} <br/> {name_spfr}
            </>
            )}
            
          
        </span>
        :

        <span {...props}   style={{ backgroundColor: "#FFFFFF",color: "#191970", fontFamily: 
        '"Tajawal", sans-serif', 
        fontSize: 18
        ,fontWeight:700 }}
        >
          
          {(indexvalue  == -1 ?
          <>
            {'- ' +name_sp} <br/> {name_spfr}
             </>
            :
            <>
            {(indexvalue+1)+'- ' +name_sp} <br/> {name_spfr}
            </>
            )}


        </span>

      );
    }} 

    
    
      //sx={{ width: 300 }}
      renderInput={(params) => <Box display="flex" justifyContent="center">  <TextField
        helperText="جرب أكتب الأحرف الأولى للتخصص في خانة التخصصات "
      
      sx={{
                
        '& .MuiFormHelperText-root': {
          fontFamily: '"Tajawal", sans-serif',
           fontSize:'12px',
           color: '#b31d3e',
           fontWeight:700
            
       }
      }}
      
      
      fullWidth  {...params} label="التخصصات"  /> </Box>  }
      />
 
  
 </CacheProvider>
 



 
{/* Autocomplement Wilayat  */}
  
<CacheProvider value={cacheRtl}>

 
<Autocomplete

noOptionsText={'حدث الصفحة مرة أخرى'}
classes={classes}
id="combo-box-demo"
onChange={onwlyChanged}
options={options_Wly}
value={valuewly}
sx={{ mt: 2 }}
defaultValue={options_Wly[0]}
//disablePortal
/* classes={{
option: styles.option
}}*/
renderOption={(props, option) => {
const { label, indexvalue } = option;

return (
indexvalue % 2 == 0 ? 

<span {...props}   style={{ backgroundColor: "#add8e6",color: "#191970", fontFamily: 
'"Tajawal", sans-serif', 
fontSize: 18
,fontWeight:700 }}>

{(indexvalue  == -1 ?
<>
  {'- ' +label} 
   </>
  :
  <>
  {label} 
  </>
  )}
  

</span>
:

<span {...props}   style={{ backgroundColor: "#FFFFFF",color: "#191970", fontFamily: 
'"Tajawal", sans-serif', 
fontSize: 18
,fontWeight:700 }}>

{(indexvalue  == -1 ?
<>
  {'- ' +label} 
   </>
  :
  <>
  {label} 
  </>
  )}


</span>

);
}} 



//sx={{ width: 300 }}
renderInput={(params) => <Box display="flex" justifyContent="center">  
<TextField fullWidth 

 {...params} label="الولايات"  /> </Box>  }
/>



</CacheProvider>





{/* Autocomplement Baladia */ }
  
<CacheProvider value={cacheRtl}>

 
   
<Autocomplete

noOptionsText={'إختر الولاية'}
classes={classes}
value={valuebld}
id="combo-box-demo"
options={options_Bld}
defaultValue={options_Bld[0]}
/* onChange={(_, newValue) => {
setvaluebld(newValue)
}}*/
sx={{ mt: 2 }}
// value={selectedBldOption}
onChange={onbldChanged}
//disablePortal
/*classes={{
option: styles.option
}}*/
renderOption={(props, option) => {
const { label, indexvalue } = option;

return (
indexvalue % 2 == 0 ? 

<span {...props}   style={{ backgroundColor: "#add8e6",color: "#191970", fontFamily: 
'"Tajawal", sans-serif', 
fontSize: 18
,fontWeight:700 }}>

{(indexvalue  == -1 ?
<>
  {'- ' +label} 
   </>
  :
  <>
  {label} 
  </>
  )}
  

</span>
:

<span {...props}   style={{ backgroundColor: "#FFFFFF",color: "#191970", fontFamily: 
'"Tajawal", sans-serif', 
fontSize: 18
,fontWeight:700 }}>

{(indexvalue  == -1 ?
<>
  {'- ' +label} 
   </>
  :
  <>
  {label} 
  </>
  )}


</span>

);
}} 



//sx={{ width: 300 }}
renderInput={(params) => <Box display="flex" justifyContent="center">  <TextField fullWidth  {...params} label="البلديات"  /> </Box>  }
/>


</CacheProvider>


 

{/* Full name */ }
  

<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box sx={{ mt: 2 }}>

<Grid container >
       

        <Grid item xs={12}>
 
<TextField fullWidth 
name="valuefullname"  
error={valuefullname.length<4? true: false}
helperText={valuefullname.length<4? "لا تترك خانة الإسم الكامل فارغة": ""}
value={valuefullname}
inputRef={reffullname}
onKeyDown={(event) => {
  if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
    event.preventDefault();
  }
}}
onBlur={(ev)=>{ 
}}
onChange={(ev)=>{
  setvaluefullname(ev.target.value)
}}
sx={{
    
     
    '& .MuiFormHelperText-root': {
      fontFamily: '"Tajawal", sans-serif',
       fontSize:'14px',
       color: '#b31d3e',
       fontWeight:700
        
   },
    '& .MuiFormLabel-root': {
      fontFamily: '"Tajawal", sans-serif',
       fontSize:'16px',
       color: '#191970'
   },
    '& .MuiInputBase-input': {
       fontFamily: '"Tajawal", sans-serif',
        fontSize:'18px',
        color: '#191970',
        fontWeight:700
    },
    '& label.Mui-focused': {
      fontSize:'16px',
      color: '#b31d3e',
      fontWeight:700
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#add8e6',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        fontFamily: '"Tajawal", sans-serif',
        fontSize:'16px',
        borderColor: '#add8e6',
      borderWidth: "2px",
       
      },
      '&:hover fieldset': {
        borderColor: 'purple',
      },
      '&.Mui-focused fieldset': {
        
        borderColor: 'purple',
      },
    } 
  }}
  
  id="outlined-basic" label="الإسم الكامل" variant="outlined" />

   
</Grid>
      </Grid>

</Box>


</MuiThemeProvider>


</CacheProvider>


{/* Adress */ }
  

<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box sx={{ mt: 2 }}>

<Grid container >
       

        <Grid item xs={12}>
        
<TextField fullWidth 
name="valueadress"  
error={valueadress.length<4? true: false}
helperText={valueadress.length<4? "لا تترك خانة العنوان فارغة": ""}
value={valueadress}
inputRef={refadress}
multiline
rows={2}
onChange={(ev)=>{
  setvalueadress(ev.target.value)
}}
onBlur={(ev)=>{ 
 }}
 
sx={{
    
     
    '& .MuiFormHelperText-root': {
      fontFamily: '"Tajawal", sans-serif',
       fontSize:'14px',
       color: '#b31d3e',
       fontWeight:700
        
   },
    '& .MuiFormLabel-root': {
      fontFamily: '"Tajawal", sans-serif',
       fontSize:'16px',
       color: '#191970'
   },
    '& .MuiInputBase-input': {
       fontFamily: '"Tajawal", sans-serif',
        fontSize:'18px',
        color: '#191970',
        fontWeight:700
    },
    '& label.Mui-focused': {
      fontSize:'16px',
      color: '#b31d3e',
      fontWeight:700
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#add8e6',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        fontFamily: '"Tajawal", sans-serif',
        fontSize:'16px',
        borderColor: '#add8e6',
      borderWidth: "2px",
       
      },
      '&:hover fieldset': {
        borderColor: 'purple',
      },
      '&.Mui-focused fieldset': {
        
        borderColor: 'purple',
      },
    } 
  }}
  
  id="outlined-basic" label="العنوان" variant="outlined" />

   
</Grid>
      </Grid>

</Box>


</MuiThemeProvider>


</CacheProvider>



{/* Map Picker */ }
<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box sx={{ mt: 2 }}>
                  

<Grid container >
       

        <Grid item xs={12}>

        <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth variant="outlined">
                   
      {savelocation ? <>
      <Button 
      sx={{fontFamily: '"Tajawal", sans-serif',
      fontSize:'16px',
      fontWeight:700
        }}
         variant="contained" color="success" onClick={handleClickOpen}>
      إضغط هنا من أجل تغيير الموقع على خريطة قوقل
      </Button>

      </> : <>
      
      <Button
      sx={{fontFamily: '"Tajawal", sans-serif',
      fontSize:'16px',

      fontWeight:700
        }}
         variant="contained" onClick={handleClickOpen}>
      إضغط هنا من أجل تحديد الموقع على خريطة قوقل
      </Button>
      
      </>  }

       


       </FormControl>
</Box>
</Grid>
 
</Grid>
 </Box>

</MuiThemeProvider>


</CacheProvider>




{/* phone01 */ }
<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box sx={{ mt: 2 }}>

<Grid container >
       

        <Grid item xs={12}>

        <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth variant="outlined"
       
       
       >

<TextField
          id="filled-number"
          label="الهاتف الأول"
          type="number"
          sx={{
             
            //direction:'ltr',
            
           '& .MuiFormHelperText-root': {
             fontFamily: '"Tajawal", sans-serif',
              fontSize:'14px',
              color: '#b31d3e',
              fontWeight:700
               
          },
           '& .MuiFormLabel-root': {
             fontFamily: '"Tajawal", sans-serif',
              fontSize:'18px',
              color: '#191970',
              dir: 'rtl',
               
          },
           '& .MuiInputBase-input': {
              fontFamily: '"Tajawal", sans-serif',
               fontSize:'22px',
               color: '#191970',
               fontWeight:700,
               //text-align: right,


                         },
           '& label.Mui-focused': {
             fontSize:'16px',
             color: '#b31d3e',
             fontWeight:700
           },
           '& .MuiInput-underline:after': {
             borderBottomColor: '#add8e6',
           },
           '& .MuiOutlinedInput-root': {
             '& fieldset': {
               fontFamily: '"Tajawal", sans-serif',
               fontSize:'16px',
               borderColor: '#add8e6',
             borderWidth: "2px",
              
             },
             '&:hover fieldset': {
               borderColor: 'purple',
             },
             '&.Mui-focused fieldset': {
               
               borderColor: 'purple',
             },
           } 
         }}
 

          InputLabelProps={{
            shrink: true,
          }}
          //variant="filled"
          defaultValue="0"
          fullWidth 
          dir="ltr" 
          value={valuephone1}
          inputRef={refphone1}
          onChange={(ev)=>{
            setvaluephone1(ev.target.value)
          }}

           error={valuephone1==0 
            || valuephone1.length==2 
            || valuephone1.length==4 
            || valuephone1.length==9 
            || valuephone1.length==10 ? false : true }
         
           helperText={valuephone1==0 
            || valuephone1.length==2 
            || valuephone1.length==4 
            || valuephone1.length==9 
            || valuephone1.length==10 ? "":"أدخل رقم هاتف صحيح" }
           onBlur={(ev)=>{ 
            setvaluephone1(ev.target.value);
          }}


        />

 
           


          </FormControl>
          </Box>
</Grid>
 
</Grid>
 </Box>

</MuiThemeProvider>


</CacheProvider>



{/* phone02 */ }
<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box sx={{ mt: 2 }}>

<Grid container >
       

        <Grid item xs={12}>

        <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth variant="outlined"
       
       
       >

<TextField
          id="filled-number"
          label="الهاتف الثاني"
          type="number"
          sx={{
             
            //direction:'ltr',
            
           '& .MuiFormHelperText-root': {
             fontFamily: '"Tajawal", sans-serif',
              fontSize:'14px',
              color: '#b31d3e',
              fontWeight:700
               
          },
           '& .MuiFormLabel-root': {
             fontFamily: '"Tajawal", sans-serif',
              fontSize:'18px',
              color: '#191970',
              dir: 'rtl',
               
          },
           '& .MuiInputBase-input': {
              fontFamily: '"Tajawal", sans-serif',
               fontSize:'22px',
               color: '#191970',
               fontWeight:700,
               //text-align: right,


                         },
           '& label.Mui-focused': {
             fontSize:'16px',
             color: '#b31d3e',
             fontWeight:700
           },
           '& .MuiInput-underline:after': {
             borderBottomColor: '#add8e6',
           },
           '& .MuiOutlinedInput-root': {
             '& fieldset': {
               fontFamily: '"Tajawal", sans-serif',
               fontSize:'16px',
               borderColor: '#add8e6',
             borderWidth: "2px",
              
             },
             '&:hover fieldset': {
               borderColor: 'purple',
             },
             '&.Mui-focused fieldset': {
               
               borderColor: 'purple',
             },
           } 
         }}
 

          InputLabelProps={{
            shrink: true,
          }}
          //variant="filled"
          defaultValue="0"
          fullWidth 
          dir="ltr" 
          value={valuephone2}
          inputRef={refphone2}
          onChange={(ev)=>{
            setvaluephone2(ev.target.value)
          }}

           error={valuephone2==0 
            || valuephone2.length==2 
            || valuephone2.length==4 
            || valuephone2.length==9 
            || valuephone2.length==10 ? false : true }
         
           helperText={valuephone2==0 
            || valuephone2.length==2 
            || valuephone2.length==4 
            || valuephone2.length==9 
            || valuephone2.length==10 ? "":"أدخل رقم هاتف صحيح" }
           onBlur={(ev)=>{ 
            setvaluephone2(ev.target.value);
          }}


        />


          </FormControl>
          </Box>
</Grid>
 
</Grid>
 </Box>

</MuiThemeProvider>


</CacheProvider>



{/* More info */ }
  

<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box sx={{ mt: 2 }}>

<Grid container >
       

        <Grid item xs={12}>
        
<TextField fullWidth 
name="valuemoreinfo"  
///error={valueadress.length<4? true: false}
///helperText={valueadress.length<4? "لا تترك خانة العنوان فارغة": ""}
value={valuemoreinfo}
inputRef={refmoreinfo}
multiline
  rows={4}
  onChange={(ev)=>{ 
    setvaluemoreinfo(ev.target.value)
  }}

sx={{
    
     
    '& .MuiFormHelperText-root': {
      fontFamily: '"Tajawal", sans-serif',
       fontSize:'14px',
       color: '#b31d3e',
       fontWeight:700
        
   },
    '& .MuiFormLabel-root': {
      fontFamily: '"Tajawal", sans-serif',
       fontSize:'16px',
       color: '#191970'
   },
    '& .MuiInputBase-input': {
       fontFamily: '"Tajawal", sans-serif',
        fontSize:'18px',
        color: '#191970',
        fontWeight:700
    },
    '& label.Mui-focused': {
      fontSize:'16px',
      color: '#b31d3e',
      fontWeight:700
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#add8e6',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        fontFamily: '"Tajawal", sans-serif',
        fontSize:'16px',
        borderColor: '#add8e6',
      borderWidth: "2px",
       
      },
      '&:hover fieldset': {
        borderColor: 'purple',
      },
      '&.Mui-focused fieldset': {
        
        borderColor: 'purple',
      },
    } 
  }}
  
  id="outlined-basic" label="معلومات إضافية" variant="outlined" />

   
</Grid>
      </Grid>

</Box>


</MuiThemeProvider>


</CacheProvider>




{/* Email */ }
<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box sx={{ mt: 2 }}>
                  

<Grid container >
       

        <Grid item xs={12}>

        <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth variant="outlined"
       
       
       >

                <TextField    
                
                sx={{
                 
                   //direction:'ltr',
                   
                  '& .MuiFormHelperText-root': {
                    fontFamily: '"Tajawal", sans-serif',
                     fontSize:'14px',
                     color: '#b31d3e',
                     fontWeight:700
                      
                 },
                  '& .MuiFormLabel-root': {
                    fontFamily: '"Tajawal", sans-serif',
                     fontSize:'18px',
                     color: '#191970',
                     dir: 'rtl',
                      
                 },
                  '& .MuiInputBase-input': {
                     fontFamily: '"Tajawal", sans-serif',
                      fontSize:'22px',
                      color: '#191970',
                      fontWeight:700,
                      //text-align: right,
                      
                       
      
                                },
                  '& label.Mui-focused': {
                    fontSize:'16px',
                    color: '#b31d3e',
                    fontWeight:700
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#add8e6',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize:'16px',
                      borderColor: '#add8e6',
                    borderWidth: "2px",
                     
                    },
                    '&:hover fieldset': {
                      borderColor: 'purple',
                    },
                    '&.Mui-focused fieldset': {
                      
                      borderColor: 'purple',
                    },
                  } 
                }}

                      inputRef={refemail}
                     error={isValidemail === false && valueemail.length !=0}  
                     label="البريد الإلكتروني"
                     helperText={isValidemail === false && valueemail.length !=0 ? "أدخل عنوان بريد إلكتروني صالح أو أتركها فارغة": <> </>
                       }

                  

                  ///  id={props.fieldName}                    
                   /// label={props.label}
                  //  name={props.fieldName}                    
                    variant="outlined" 
                    size={'small'}
                   // helperText={props.helperText}
                   name="valueemail"
                    value={valueemail}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => handleChangeemail(e)}

                              
                   
                     


                />
                
            </FormControl>


            </Box>
</Grid>
 
</Grid>
 </Box>

</MuiThemeProvider>


</CacheProvider>







{/* Submit Button */ }
<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box sx={{ mt: 2 }}>

<Grid container >
       

        <Grid item xs={12}>

        <Box sx={{ minWidth: 120 }}>
        

      <FormControl fullWidth variant="outlined"
       
       
       > 

      <div>
     


  <Button 
  
   /*disabled={valuefullname.length<4 || valueadress.length <4
    || (isValidemail === false && valueemail.length !=0)
    || !(valuephone1==0 

      || valuephone1.length==2 
      || valuephone1.length==4 
      || valuephone1.length==9 
      || valuephone1.length==10) 

      || !(valuephone2==0 
      || valuephone2.length==2 
      || valuephone2.length==4 
      || valuephone2.length==9 
      || valuephone2.length==10) ? true:false}   */                              

  onClick={(e)=>{
    e.preventDefault();
  
    if(valuefullname.length<4){
      reffullname.current.focus();
      return
    }

    if(valueadress.length<4){
      refadress.current.focus();
      return
    }

    if(!(valuephone1==0 
      || valuephone1.length==2 
      || valuephone1.length==4 
      || valuephone1.length==9 
      || valuephone1.length==10)){
      refphone1.current.focus();
      return
    }

    if(!(valuephone2==0 
      || valuephone2.length==2 
      || valuephone2.length==4 
      || valuephone2.length==9 
      || valuephone2.length==10)){
      refphone2.current.focus();
      return
    }


    if((!isEmail(valueemail) && valueemail.length >0 )){
      refemail.current.focus();
      return
    }

     
    onsubmitclicked(e);

     
  }}
  

  variant="contained"
   startIcon={<SendIcon sx={{ml:'5px'}} />}>
    <Typography  sx={{ color: "#FFFFFF", fontFamily: 
           '"Tajawal", sans-serif', 
           fontSize: 22
           ,fontWeight:900 }}>
            إرسال المعلومات     
    </Typography>
        
      </Button>

      
      
      <Button
      hidden
              fullWidth
          
              type="submit"
             ref={refbtnsubmit}
              variant="contained"
              
                
            startIcon={<SearchIcon sx={{ml:'0px',width:30, height:30, dir:'rtl'}} />}>
    <Typography  sx={{ color: "#FFFFFF", fontFamily: 
           '"Tajawal", sans-serif', 
           fontSize: 22
           ,fontWeight:900 }}>
           submit button    
    </Typography>
              
            </Button>



      </div>


      </FormControl>
          </Box>
</Grid>
 
</Grid>
 </Box>

</MuiThemeProvider>


</CacheProvider>












        <Footer />
       

</div>
</div>


<div>

{/* Dialog success save */ }
      
<Dialog
       PaperProps={{
        style: {
          backgroundColor: '#add8e6',
          boxShadow: 'none',
        },
      }}
       //fullScreen={mediumScreen}
        open={OpenDialog}
        onClose={handleClickCloseDialog}
        aria-labelledby="responsive-dialog-title"
      >

<Card  style={{}} sx={{ maxWidth: 345  }}>

  {/* <p className="h5 font-weight-bold text-primary" id={showhidvalueteldate}><FaCalendarAlt/> تاريخ آخر تبرع: {props.daydon+'/'+props.monthdon+'/'+props.yeardon}</p>
 */ }
<CardHeader
        avatar={
          
            <CheckCircleIcon sx={{color:'#4BB543',width: 50, height: 50,ml:2}}></CheckCircleIcon>
         
        }
        action={
         
           
        <IconButton  onClick={handleClickCloseDialog} aria-label="settings" sx={{ ml:-1,mt:-1  }}>
                    
        <CloseIcon  /> 

        </IconButton>
        } 
        title={
          <>
          <Box sx={{ ml:2, mt:0, display: 'flex', alignItems: 'flex-end', justifyContent: 'center'}}>

          <Typography sx={{ color:'#191970', fontFamily: '"Tajawal", sans-serif', fontSize: 22,fontWeight:900}} >
          تم تسجيل معلوماتك بنجاح</Typography>
            </Box>
            
            </>
        
        }
        subheader=""
      />


       


</Card>

      </Dialog>
         






{/* Dialog Map */ }

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
        {"ضع العلامة الحمراء على المكان الذي تريد تحديده ثم إضغط على حفظ"}
        </DialogTitle>
        <DialogContent>
        {/*      

            <button onClick={handleResetLocation}>Reset Location</button>
  <label>Latitute:</label><input type='text' value={location.lat} disabled/>
  <label>Longitute:</label><input type='text' value={location.lng} disabled/>
  <label>Zoom:</label><input type='text' value={zoom} disabled/>

  ////AIzaSyBmC6XcoNgPMH6Dr9OMPICiWM6hpn6yCtU
  ///////AIzaSyD07E1VvpsN_0FvsmKAj4nK9GnLq-9jtj8
*/
}
            <MapPicker defaultLocation={defaultLocation}
    zoom={zoom}
    mapTypeId="roadmap"
    style={{height:'700px'}}
    onChangeLocation={handleChangeLocation} 
    onChangeZoom={handleChangeZoom}
    apiKey='AIzaSyBmC6XcoNgPMH6Dr9OMPICiWM6hpn6yCtU'/>
         </DialogContent>
        <DialogActions>
        
        <Grid
  container
  alignItems="center"
  justifyContent="center"
>
       <Box item xs={12}>

        <Button  variant="contained" color="success" onClick={handleSaveLocation} autoFocus>
       <SaveIcon sx={{ ml: '5px' }}/>  حفظ
          </Button>

          <Button   sx={{ mr: '10px' }} variant="contained" color="error" autoFocus onClick={handleDeleteLocation}>
          <DeleteIcon sx={{ ml: '5px' }} /> حذف الإحداثيات 
          </Button>
          
          <Button sx={{ mr: '10px' }} variant="outlined" autoFocus onClick={handleClose}>
            إغلاق
          </Button>

          </Box>
          </Grid>

        </DialogActions>
      </Dialog>
    </div>




    <TextField
    hidden
  name="valuesp"
  value={valuesp.value}
  
/>


<TextField
    hidden
  name="valuewly"
  value={valuewly.value}
  
  
/>

<TextField
hidden
  name="valuebld"
  value={valuebld.value}
  
/>





    </form>

    </>)}

        </>

 
        );

}