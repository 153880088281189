import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
////import "bulma/css/bulma.css";


import Header from './containers/Header';
import HomePage from './containers/HomePage';
import Login from "./containers/Login";
import RegisterDon from "./containers/RegisterDon";


import { BrowserRouter } from 'react-router-dom';
import TextFieldDirection from './containers/TextFieldDirection';


import CustomizedInputs from './containers/CustomizedInputs';
import DocState from './containers/DocState';



import { StyledEngineProvider } from '@mui/material/styles';

  
import registerServiceWorker from './registerServiceWorker';
//import 'tachyons'; 
 

{
/* ReactDOM.render(<HomePage />, document.getElementById('root')); */
}

// registerServiceWorker();

ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>   
      <StyledEngineProvider injectFirst>
        <HomePage />
        </StyledEngineProvider>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
  );


 