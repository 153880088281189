import React, { useState,Fragment } from "react";
 import { BrowserRouter as Router, Routes, Route, Link,useLocation } from "react-router-dom";

import {
  AppBar,
  Button,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";



 
import Doc from '../containers/DocState';
import Don from '../containers/DonState';
import More from '../containers/MoreState';
import Header from '../containers/Header';


import Footer from '../containers/Footer';



import appico from '../iconimg/appico.png';
import iconbarleft from '../iconimg/iconbarleft.png';
import pharmacy from '../iconimg/pharmacy.png';
import blooddon from '../iconimg/blooddon.png';
import moresearch from '../iconimg/moresearch.png';
 
import Dashboard from "../containers/Dashboard";
import Login from "../containers/Login";
import RegisterDon from "../containers/RegisterDon";
import RegisterDoc from "../containers/RegisterDoc";
import PickerMap from "../containers/PickerMap";



import LoginDon from "../containers/LoginDon";
import ResetPwDon from "../containers/ResetPwDon";
import UpdateRegisterDon from "../containers/UpdateRegisterDon";
import UpdateDoc from "../containers/UpdateDoc";
import DeleteAccount from "../containers/DeleteAccount";





import Box from '@mui/material/Box';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
 
import { createTheme, ThemeProvider } from '@mui/material/styles';

import AddBusinessRoundedIcon from "@mui/icons-material/AddBusinessRounded";
import DrawerComp from "./Drawer";



import './amirifont.css';

import { styled } from '@mui/material/styles';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
 
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const HomePage = () => {
  const [value, setValue] = useState();
  const theme = useTheme();
  console.log(theme);
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  console.log(isMatch);

   
  const themeAmiri = createTheme({  
    typography: {
      fontFamily: 
        '"Amiri", serif', 
        fontSize: 12,
         

    },
  });

  const themeRoboto = createTheme({  
    typography: {
      fontFamily: 
        '"Roboto Slab", serif', 
        fontSize: 12,
         

    },
  });


  const themeChanga = createTheme({  
    typography: {
      fontFamily: 
        '"Changa", sans-serif', 
        fontSize: 12,
         

    },
  });

  const location = useLocation();
  console.log('location.pathname'+location.pathname);
  console.log('lisMatch'+isMatch);

  return (
    <>
               

             <Fragment>
            

             
 
      <Routes>
      {(isMatch && (location.pathname=="/" || location.pathname=="" || location.pathname==null)) ? (<>
         
        <Route exact path='/' element={<Header />}></Route>
</>)

:(<>
         <Route exact path='/' element={<Doc/>}></Route>

</>)}
        <Route path="/Doc" element={<Doc/>}></Route>
        <Route path="/Don" element={<Don/>}></Route>
        <Route path="/More" element={<More/>}></Route>
        
        <Route path="/settings/delete_account" element={<DeleteAccount />} />

        <Route path="/loginDon" element={<LoginDon/>}></Route>
        <Route path="/RegisterDon" element={<RegisterDon/>}></Route>
        <Route path="/ResetPwDon" element={<ResetPwDon/>} ></Route>
        <Route path="/UpdateRegisterDon" element={<UpdateRegisterDon/>} ></Route>

        <Route path="/dashboard" element={<Dashboard/>} ></Route>
        <Route path="/RegisterDoc" element={<RegisterDoc/>}></Route>
        <Route path="/UpdateDoc" element={<UpdateDoc/>}></Route>

        <Route path="/PickerMap" element={<PickerMap/>}></Route>

        
      </Routes>
 
     

  

      
      </Fragment>
 
            
            
</>
  );
};

export default HomePage;

