import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
import Modal from 'react-bootstrap/Modal';
import { FaMapMarkerAlt,FaPhoneAlt,FaMobileAlt,FaMapSigns, FaEnvelopeOpenText,FaMapMarkedAlt,FaMapPin,FaInfoCircle } from 'react-icons/fa';
import CloseButton from 'react-bootstrap/CloseButton';
import allergologie from '../iconimg/allergologie.png';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CardActions from '@mui/material/CardActions';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReportIcon from '@mui/icons-material/Report';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { BrowserRouter as Router, Routes, Route, Link,useLocation } from "react-router-dom";
import iconbarleft from '../iconimg/iconbarleft.png';
 import CardContent from '@mui/material/CardContent';
 import EditIcon from '@mui/icons-material/Edit';

import {
    AppBar,
    Button,
    Tab,
    Tabs,
    Grid,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme,
  } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
 
import { styled } from '@mui/material/styles';

 
import React, { useState  } from 'react'
import '../containers/amirifont.css'

import CardHeader from '@mui/material/CardHeader';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';



import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
 
 
 

import PropTypes from 'prop-types';

import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import { purple } from '@mui/material/colors';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};



export default function CardDocState(props)  {

    
  const [Open, setOpen] = useState(false);
  const [showbtn, setshowbtn] = useState(false);
  const [showhidvaluetel1, setshowhidvaluetel1] = useState('HideBld');
  const [showhidvaluetel2, setshowhidvaluetel2] = useState('HideBld');
  const [showhidvalueemail, setshowhidvalueemail] = useState('HideBld');
  const [showhidvalueMAP, setshowhidvalueMAP] = useState('HideBld');
  const [showhidvaluedesc, setshowhidvaluedesc] = useState('HideBld');
 
   

  
  const themeChanga = createTheme({  
    typography: {
      fontFamily: 
        '"Changa", sans-serif', 
        fontSize: 12,
         

    },
  });

  
  const themeAmiri = createTheme({  
    typography: {
      fontFamily: 
        '"Amiri", serif', 
        fontSize: 12,
         

    },
  });

  const themeRoboto = createTheme({  
    typography: {
      fontFamily: 
        '"Roboto Slab", serif', 
        fontSize: 12,
         

    },
  });

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  
const handleClose = () => {
  setshowbtn(false);
  };

const handleShow = () => {
  setshowbtn(true);
  };


const handleClickOpen = () => {
setOpen(true);
};


const handleClickClose = () => {
setOpen(false);
};



let tel1show=props.Tel1_doct;
if(tel1show.length==8 || tel1show.length==9){tel1show=`0${tel1show}`;}
let tel2show=props.Tel2_doct;
if(tel2show.length==8 || tel2show.length==9){tel2show=`0${tel2show}`;}


let PhoneOrMobiletel1=<FaPhoneAlt />;
if(tel1show.length==10){PhoneOrMobiletel1=<FaMobileAlt />;}
let PhoneOrMobiletel2=<FaPhoneAlt />;
if(tel2show.length==10){PhoneOrMobiletel2=<FaMobileAlt />;}



/*
if(tel1show==0 || tel1show ==null ){
setshowhidvaluetel1('HideBld');
}
else{
setshowhidvaluetel1('ShowBld');
}


if(tel2show==0 || tel2show ==null ){
setshowhidvaluetel2('HideBld');
}
else{
setshowhidvaluetel2('ShowBld');
}
////////////
if(props.Email_doct==0 || props.Email_doct ==null || props.Email_doct =='' ){
setshowhidvalueemail('HideBld');
}
else{
setshowhidvalueemail('ShowBld');
}

////////////////

if(props.latcor==0 || props.latcor ==null || props.latcor =='' || props.loncor==0 || props.loncor ==null || props.loncor =='' ){
setshowhidvalueMAP('HideBld');
}
else{
setshowhidvalueMAP('ShowBld');
}

//////////////////////////////
if(props.description_doct==0 || props.description_doct ==null || props.description_doct =='' ){
setshowhidvaluedesc('HideBld');
}
else{
setshowhidvaluedesc('ShowBld');
}
*/




/////////////
var nameicon='appico.png';

if (props.Num_sp == 69) {
nameicon='hospital.png';
} else if (props.Num_sp == 68) {
nameicon='microscop.png';
} else if (props.Num_sp == 70) {
nameicon='himaya.png';
} else if (props.Num_sp == 51) {
nameicon='pharmacy.png';
} else if (props.Num_sp == 72) {
nameicon='glassemaker.png';
} else if (props.Num_sp == 22) {
nameicon='doctormale.png';
} else if (props.Num_sp == 25) {
nameicon='gynecology.png';
} else if (props.Num_sp == 21) {
nameicon='pediatre.png';
} else if (props.Num_sp == 27) {
nameicon='dentist.png';
} else if (props.Num_sp == 29) {
nameicon='ent.png';
} else if (props.Num_sp == 34) {
nameicon='eye.png';
} else if (props.Num_sp == 28) {
nameicon='orthoped.png';
} else if (props.Num_sp == 31) {
nameicon='heart.png';
} else if (props.Num_sp == 26) {
nameicon='intern.png';
} else if (props.Num_sp == 52) {
nameicon='brain.png';
} else if (props.Num_sp == 62) {
nameicon='surgeon.png';
} else if (props.Num_sp == 30) {
nameicon='dermatology.png';
} else if (props.Num_sp == 42) {
nameicon='diabete.png';
} else if (props.Num_sp == 32) {
nameicon='breath.png';
} else if (props.Num_sp == 40) {
nameicon='psycho.png';
} else if (props.Num_sp == 60) {
nameicon='kidney.png';
} else if (props.Num_sp == 36) {
nameicon='digestif.png';
} else if (props.Num_sp == 53) {
nameicon='radiology.png';
} else if (props.Num_sp == 50) {
nameicon='natural.png';
} else if (props.Num_sp == 44) {
nameicon='blooddoc.png';
} else if (props.Num_sp == 55) {
nameicon='anastis.png';
} else if (props.Num_sp == 48) {
nameicon='food.png';
}
else if (props.Num_sp == 73) {
nameicon='orthophony.png';
}
else if (props.Num_sp == 74) {
nameicon='veterinar.png';
}
else if (props.Num_sp == 75) {
nameicon='materielmid.png';
}
else if (props.Num_sp == 76) {
nameicon='assoc.png';
}
else if (props.Num_sp == 77) {
nameicon='homefree.png';
}
else if (props.Num_sp == 78) {
nameicon='ambulance.png';
}
else if (props.Num_sp == 79) {
nameicon='allergologie.png';
}

else if (props.Num_sp == 80) {
nameicon='rehabilitation.png';
}
else if (props.Num_sp == 89) {
nameicon='addict.png';
}
else if (props.Num_sp == 88) {
nameicon='mednucl.png';
}

else if (props.Num_sp == 82) {
nameicon='infermier.png';
}

else if (props.Num_sp == 85) {
nameicon='kidney.png';
}
else if (props.Num_sp == 84) {
nameicon='urologue.png';
}
else if (props.Num_sp == 81) {
nameicon='sagefemme.png';
}

else if (props.Num_sp == 83) {
nameicon='infectious.png';
}

else if (props.Num_sp == 90) {
nameicon='neurolog.png';
}
else if (props.Num_sp == 92) {
  nameicon='esthetique.png';
  }
  else if (props.Num_sp == 91) {
    nameicon='biology.png';
    }
    else if (props.Num_sp == 93) {
      nameicon='massage.png';
      }




//console.log('../iconimg/'+nameicon);
 
    return (
 

      <div>

<Box sx={{mt:0}}>

<Card onClick={handleClickOpen} style={{backgroundColor: "#e9f5f8"}} sx={{ maxWidth: 345  }}>
<CardHeader
        avatar={
          <Avatar  aria-label="recipe"
          src={ require('../iconimg/'+nameicon) }
          sx={{mr:-2, width: 50, height: 50, border: '2px solid rgb(176,196,222) '}}>
            
          </Avatar>
        }
        action={
          <IconButton  onClick={handleClickOpen} aria-label="settings" sx={{ ml:-1  }}>
            
            <ExpandMoreIcon  /> 
           
          </IconButton>
        } 
        title={
          <>
          <Typography sx={{ color:'#191970', fontFamily: '"Tajawal", sans-serif', fontSize: 20,fontWeight:700}} >
            {props.Name_doct}</Typography>
            <Typography  sx={{ color:'purple', fontFamily: '"Tajawal", sans-serif', fontSize: 14,fontWeight:400}} >
             <FaMapPin/> {props.Spname}</Typography>
            </>
        
        }
        subheader={
          <Typography  sx={{ color:'#006400', fontFamily: '"Tajawal", sans-serif', fontSize: 14,fontWeight:700}} >
             <FaMapMarkerAlt /> {props.Bldianame} , {props.Wilayname} </Typography>
        }
      />


</Card>

</Box>



<Dialog
       PaperProps={{
        style: {
          backgroundColor: '#add8e6',
          boxShadow: 'none',
        },
      }}
       //fullScreen={mediumScreen}
        open={Open}
        onClose={handleClickClose}
        aria-labelledby="responsive-dialog-title"
      >

<Card  style={{}} sx={{ maxWidth: 345  }}>
<CardHeader
        avatar={
          <Avatar  aria-label="recipe"
          src={ require('../iconimg/'+nameicon) }
          sx={{mr:-2,ml:1, width: 50, height: 50, border: '2px solid rgb(176,196,222) '}}>
            
          </Avatar>
        }
        action={
         
           
        <IconButton  onClick={handleClickClose} aria-label="settings" sx={{ ml:-1,mt:-1  }}>
                    
        <CloseIcon  /> 

        </IconButton>
        } 
        title={
          <>
          <Box sx={{ mt:0, display: 'flex', alignItems: 'flex-end', justifyContent: 'center'}}>

          <Typography sx={{ color:'#191970', fontFamily: '"Tajawal", sans-serif', fontSize: 18,fontWeight:700}} >
            {props.Name_doct}</Typography>
            </Box>
            <Box sx={{ mt:0, display: 'flex', alignItems: 'flex-end', justifyContent: 'center'}}>

            <Typography  sx={{ color:'purple', fontFamily: '"Tajawal", sans-serif', fontSize: 14,fontWeight:400}} >
             <FaMapPin/> {props.Spname}</Typography>
             </Box>
            </>
        
        }
        subheader=""
      />

<CardContent sx={{ mt:-3  }}>
<Box sx={{ mt:0, display: 'flex', alignItems: 'flex-end', justifyContent: 'center'}}>
<Typography  sx={{ color:'#006400', fontFamily: '"Tajawal", sans-serif', fontSize: 14,fontWeight:700}} >
             <FaMapMarkerAlt /> {props.Bldianame} , {props.Wilayname} </Typography>
</Box>

             <Box sx={{ mt:1, display: 'flex', alignItems: 'flex-end', justifyContent: 'center'}}>

        <Typography color="text.secondary" sx={{ fontFamily: '"Tajawal", sans-serif', fontSize: 18,fontWeight:700 }}  >
        <FaMapPin/> {props.Adress_doct} 
        </Typography>
        </Box>
 
          {!(props.latcor==0 || props.latcor ==null || props.latcor =='' || props.loncor==0 || props.loncor ==null || props.loncor =='')
          ?<>
          
          <Box sx={{ mt:1, display: 'flex', alignItems: 'flex-end', justifyContent: 'center'}}>
            <Button variant="contained" color="primary" sx={{mr:2,underline:'none'}} >
             <a style={{ textDecoration: 'none',color:'white', fontFamily: '"Tajawal", sans-serif', fontSize: 16,fontWeight:700 }} href={'https://maps.google.com/?q='+props.latcor+','+props.loncor}> الخريطة <FaMapMarkedAlt/>
              </a>           
            </Button>
            </Box>
          </>:<></>}
        
         {/* {()?<></>:<></>} */} 
        
        {!(tel1show==0 || tel1show ==null)?<>
        

        <Box sx={{mt:2, display: 'flex', alignItems: 'flex-end', justifyContent: 'center'}}>
             
             <Typography color="text.secondary" sx={{ fontFamily: '"Tajawal", sans-serif', fontSize: 22,fontWeight:700 }}  >
             {tel1show}
              </Typography>
             <Button variant="outlined" color="success" sx={{mr:2}} >
             <a style={{ textDecoration: 'none',color:'green', fontFamily: '"Tajawal", sans-serif', fontSize: 16,fontWeight:700 }} href={'tel:'+tel1show}>إتصل الآن  {PhoneOrMobiletel1}  
              </a>
             
            </Button>
              
            </Box>
            </>:<></>}  

            {!(tel2show==0 || tel2show ==null)?<>
            <Box sx={{mt:2, display: 'flex', alignItems: 'flex-end', justifyContent: 'center'}}>
             
             <Typography color="text.secondary" sx={{ fontFamily: '"Tajawal", sans-serif', fontSize: 22,fontWeight:700 }}  >
             {tel2show}
              </Typography>
             <Button variant="outlined"  sx={{mr:2}} >
             <a style={{ textDecoration: 'none', fontFamily: '"Tajawal", sans-serif', fontSize: 16,fontWeight:700 }} href={'tel:'+tel2show}>إتصل الآن  {PhoneOrMobiletel2}  
              </a>
             
            </Button>
              
            </Box>
            </>:<></>} 
           

            
            {!(props.Email_doct=='' || props.Email_doct ==null)?<>
            <Box sx={{ mt:2, display: 'flex', alignItems: 'flex-end', justifyContent: 'center'}}>
            <Typography color="text.secondary" sx={{ fontFamily: '"Tajawal", sans-serif', fontSize: 18,fontWeight:700 }}  >
            {props.Email_doct} <FaEnvelopeOpenText/>
            </Typography>
            </Box>
            </>:<></>}

            {!(props.description_doct=='' || props.description_doct ==null)?<>
            <Box sx={{ mt:2, display: 'flex', alignItems: 'flex-end', justifyContent: 'center'}}>
            <Typography color="text.secondary" sx={{ fontFamily: '"Tajawal", sans-serif', fontSize: 18,fontWeight:700 }}  >
            <FaMapPin/> {props.description_doct}  
            </Typography>
            </Box>

            </>:<></>}

            <Divider variant="inset" component="li" style={{marginTop:'15px',width:'100%',listStyleType:'none'}} />

            <Box sx={{ mt:1, display: 'flex', alignItems: 'flex-end', justifyContent: 'center'}}>

            <Button  
             variant="contained" color="primary" sx={{mr:2,underline:'none'}} >
             <a style={{ textDecoration: 'none',color:'white', fontFamily: '"Tajawal", sans-serif', fontSize: 16,fontWeight:700 }} href={'/updateDoc?ninfo='+props.Num_doct}> تصحيح المعلومات <EditIcon/>
              </a>           
            </Button>

            <Button  
              variant="contained" color="error" sx={{mr:2,underline:'none'}}>
              <a style={{ textDecoration: 'none', color:'white', fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight:700 }} href={'https://www.doctorsdz.machrou3.com/user/reportdoc/'+props.Num_doct}>
              إبلاغ للحذف <ReportIcon/>
              </a>           
          </Button>

            </Box>



      </CardContent>
       


</Card>

      </Dialog>
         



        {/* 
        <ListItem component={Link} to="/Doc" alignItems="flex-start"   >
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src={iconbarleft}
          sx={{ width: 65, height: 65 }} />
        </ListItemAvatar>
        <ListItemText

           primary={<Typography
            theme={themeChanga}
            sx={{  textAlign: 'right',mr:2  }}
             variant="h5" type="body2" style={{ color: '#063970' }}>{props.Name_doct}</Typography>}

          secondary={
            <React.Fragment>
              <Typography
              theme={themeRoboto}
              
               variant="h6"
               align="left"
                sx={{ display: 'inline' }}
                component="span"
               // variant="body2"
                color='#063970'
              >
                Les médecins
              </Typography >
               
            </React.Fragment>
          }
        />
      </ListItem>



     
    <Card border="primary" className='mt-2 mb-2 '>
      <Card.Body>
        
      <div className="container-fluid">
  <div className="row">
  
    <div className="col">
    <Card.Title>
    <p className="h2 font-weight-bold ">{props.Name_doct}</p>
    </Card.Title>
        <Card.Text>
        <p className="h6 font-weight-bold text-muted "><FaMapPin/> {props.Spname}</p>
        <p className="h4"><FaMapSigns/> {props.Adress_doct} </p>
    <p className="h6 text-success"><span><FaMapMarkerAlt /> {props.Bldianame} , {props.Wilayname} </span> </p>
   
        </Card.Text>
    </div>
    <div className="col-3">
    <div className="text-center">
      
    <Image 
    className='img-fluid' 
    src={ require('../iconimg/'+nameicon) } />

    </div>

    </div>
  </div>
  <div className="row">
  <Button variant="primary" ><p className="h4 font-weight-bold "> <FaInfoCircle/> المزيد... </p></Button>
  </div>
  </div>
     
        
      </Card.Body>
    </Card>

    */ }
 
   {/* 
 <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button>
      <BootstrapDialog
        onClose={handleClickClose}
        aria-labelledby="customized-dialog-title"
        open={Open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClickClose}>
          Modal title
          
          
        </BootstrapDialogTitle>
        <Box display="flex" alignItems="center" dir="rtl">
        <Box flexGrow={1} >{props.Name_doct}
        </Box>
                <Box>
                    <IconButton onClick={handleClickClose}>
                          <CloseIcon />
                    </IconButton>
                    
                </Box>
               
          </Box>
        <DialogContent dividers>
          <Typography gutterBottom>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
            dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
            consectetur ac, vestibulum at eros.
          </Typography>
          <Typography gutterBottom>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
            Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
          </Typography>
          <Typography gutterBottom>
            Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus
            magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec
            ullamcorper nulla non metus auctor fringilla.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClickClose}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>

*/ }





 

    

      

{/*

    <ThemeProvider dir="rtl">


   


 
    <Modal
        show={this.state.showbtn}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      >
        

        <Modal.Header  >
          <Modal.Title id="contained-modal-title-vcenter"><p className="h2 font-weight-bold ">{props.Name_doct}</p></Modal.Title>
           <span aria-hidden="true"><CloseButton onClick={handleClose}></CloseButton></span>
        
        </Modal.Header>
        <Modal.Body>
  
        <p className="h6 font-weight-bold text-muted "><FaMapPin/> {props.Spname}</p>
        <p className="h4"><FaMapSigns/> {props.Adress_doct} </p>
    <p className="h6 text-success"><span><FaMapMarkerAlt /> {props.Bldianame} , {props.Wilayname} </span> </p>
    <p className="h5 text-secondary" id={this.state.showhidvaluedesc}><FaMapPin/> {props.description_doct} </p>

    <p className="h4" id={this.state.showhidvaluetel1}> {tel1show} <a className="btn btn-outline-primary" href={'tel:'+tel1show}>إتصل الآن  {PhoneOrMobiletel1} </a>   </p>
    <p className="h4" id={this.state.showhidvaluetel2}> {tel2show} <a className="btn btn-outline-success" href={'tel:'+tel2show}>إتصل الآن  {PhoneOrMobiletel2} </a>   </p>    
    <p className="h5" id={this.state.showhidvalueemail}> {props.Email_doct} <FaEnvelopeOpenText/> </p>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            إغلاق
          </Button>
          <a className="btn btn-primary" id={this.state.showhidvalueMAP} href={'https://maps.google.com/?q='+props.latcor+','+props.loncor}> الخريطة <FaMapMarkedAlt/> </a>   
        </Modal.Footer>
      </Modal>

      </ThemeProvider>

*/}



    </div>



    )
  }
 



/* 
 <img alt='robots' src={`https://robohash.org/${Num_doct}?size=200x200`} />
      <div>
        <h2>{Name_doct}</h2>
        <p>{Email_doct}</p>
      </div>
      */