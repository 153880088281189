import React, { useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState('');
   // const history = useHistory();
    const navigate = useNavigate();

    const Auth = async (e) => {

        let axiosConfig = {
            headers: {
                //'Content-Type' : 'application/json; charset=UTF-8',
                ///application/x-www-form-urlencoded
                ///'Content-Type' : 'application/x-www-form-urlencoded',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Accept': 'Token',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "POST",
                "Access-Control-Allow-Headers": "*",
                "Access-Control-Max-Age": "86400",
 
                
             }
          };

        e.preventDefault();
        try {

            var details = {
                emaila: email,
                email: email,
                body: email
            };
            
         /*   var formBody = [];
            for (var property in details) {
              var encodedKey = encodeURIComponent(property);
              var encodedValue = encodeURIComponent(details[property]);
              formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");*/

            const formBody = Object.keys(details).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(details[key])).join('&');

            const params = new URLSearchParams();
            params.append('firstName', 'paul');
            params.append('lastName', 'fred');
           // const json = JSON.stringify({ name: 'deven' });


      ///https://www.doctorsdz.machrou3.com/user/logincheckappdoc/
      ////https://jsonplaceholder.typicode.com/posts
      ////https://doc.machrou3.com/testappdoc
      ///https://cfpa-bounoura.dz/test/user/logincheckappdoc
            await axios.post('https://cfpa-bounoura.dz/test/user/logincheckappdoc/'
            , params,axiosConfig)
            .then(response => {
                 
                console.log(response.data)
                alert(JSON.parse(JSON.stringify(response.data.msg)))
            })
            //navigate('/dashboard');
 
        } catch (error) {
            
            if (error.response) {
                console.log(error.response)
                return 
            }

        }

 

       
    }

    return (
        <section className="hero has-background-grey-light is-fullheight is-fullwidth">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-4-desktop">
                            <form onSubmit={Auth}  className="box">
                                <p className="has-text-centered">{msg}</p>
                                <div className="field mt-5">
                                    <label className="label">Email or Username</label>
                                    <div className="controls">
                                        <input type="text" className="input" placeholder="Username" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <label className="label">Password</label>
                                    <div className="controls">
                                        <input type="password" className="input" placeholder="******" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <button className="button is-success is-fullwidth">Login</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>

 

    )
}

export default Login
