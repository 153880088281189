import React, { useState,useEffect, useRef }  from 'react';

import axios from "axios";
import { useNavigate ,useLocation} from 'react-router-dom';
import Header from '../containers/Header';
import Footer from '../containers/Footer';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { CacheProvider } from "@emotion/react";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import rtlPlugin from "stylis-plugin-rtl";
import { makeStyles,withStyles } from "@material-ui/core/styles";
import FormControl from '@mui/material/FormControl';
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import IconButton from '@material-ui/core/IconButton';
import ReplayIcon from '@mui/icons-material/Replay';
import Container from '@mui/material/Container';
import SaveIcon from '@mui/icons-material/Save';
import InfoIcon from '@mui/icons-material/Info';
import {isMobile,browserName} from 'react-device-detect';
import CircularProgress from '@mui/material/CircularProgress';

import SearchIcon from "@mui/icons-material/Search";

import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';

import Input from '@mui/material/Input';
 
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
 import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import isEmail from 'validator/lib/isEmail';
import InputAdornment from "@mui/material/InputAdornment";
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Card from 'react-bootstrap/Card';
import Avatar from '@mui/material/Avatar';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CloseIcon from '@mui/icons-material/Close';
import '../containers/amirifont.css'
import { FaMapMarkerAlt,FaPhoneAlt,FaMobileAlt,FaMapSigns, FaEnvelopeOpenText,FaMapMarkedAlt,FaMapPin,FaInfoCircle } from 'react-icons/fa';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';


const options_Sp = [ //sp mean  blood typ
  { value: 1, label: '+A' },
  { value: 2, label: '-A' },
  { value: 3, label: '+B' },
  { value: 4, label: '-B' },
  { value: 5, label: '+AB' },
  { value: 6, label: '-AB' },
  { value: 7, label: '+O' },
  { value: 8, label: '-O' }
]


let options_Wly = [];
var md5 = require('md5');
let codeemail = (Math.floor(1000 + Math.random() * 9000));




let Wlylists=[];
 let BldOnly=[];
let searchtxtvalue='';
let selectSpValue=0;
let selectWlyValue=0;
let selectBldValue=0;
 
let Bldlists=[];
let BldlistsCard=[];
let selectedWlyOption=null;


let sendemailbyemailoneortwo='https://www.doctorsdz.machrou3.com/user/sendemailbyemailone/';


  // Create rtl cache
  const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
  });

  // Create ltr cache
  const cacheLtr = createCache({
    key: 'muiltr',
    stylisPlugins: [prefixer, rtlPlugin],
  });


  const defaultColor = "#ff0000";
  const hoverColor = "#0000ff";
  const focusColor = "#00ff00";

  const themetextfieldnew = createTheme({
    overrides: {
      MuiOutlinedInput: {
        root: {
          "&:hover $notchedOutline": {
            borderColor: hoverColor
          },
          "&$focused $notchedOutline": {
            borderColor: focusColor
          }
        },
        notchedOutline: {
          borderColor: defaultColor
        }
      }
    },
    fieldset: {
      fontFamily: 
        '"Changa", sans-serif', 
        fontSize: 45,
         

    } 
  });




const textmaskphone1Custom = React.forwardRef(function textmaskphone1Custom(props, ref) {
    const { onChange, ...other } = props;
    
    return (
        <>       

        

         <CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box >

<Grid container >
       

        <Grid item xs={12}>



      <IMaskInput
      
        {...other}
        mask="0000000000"
        ///value=""
        unmask={false} // true|false|'typed'
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
        // input props also available
        placeholder='مثال: 069988776655'
      />


</Grid>
      </Grid>

</Box>
</MuiThemeProvider>


</CacheProvider>


</>


    );
  });
  
 
  textmaskphone1Custom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };


  const textmaskphone2Custom = React.forwardRef(function textmaskphone2Custom(props, ref) {
    const { onChange, ...other } = props;

   return (
        <>       
        
         <CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box >

<Grid container >
       

        <Grid item xs={12}>



      <IMaskInput
      
        {...other}
        mask="0000000000"
        ///value=""
        unmask={false} // true|false|'typed'
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
        // input props also available
        placeholder='مثال: 069988776655'
      />


</Grid>
      </Grid>

</Box>
</MuiThemeProvider>


</CacheProvider>


</>


    );
  });
  


  textmaskphone2Custom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };



  const textmaskcodeemailCustom = React.forwardRef(function textmaskcodeemailCustom(props, ref) {
    const { onChange, ...other } = props;

   return (
        <>       
        
         <CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box >

<Grid container >
       

        <Grid item xs={12}>

      <IMaskInput
      
        {...other}
        mask="0000"
        ///value=""
        unmask={false} // true|false|'typed'
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
        // input props also available
        placeholder='أدخل رمز التحقق هنا'      />


</Grid>
      </Grid>

</Box>
</MuiThemeProvider>


</CacheProvider>


</>


    );
  });
  


  textmaskcodeemailCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };






const RegisterDon = () => {
  var relativeTime = require('dayjs/plugin/relativeTime')

  dayjs.extend(relativeTime)

  const [OpenDialog, setOpenDialog] = useState(false);

  
  //const [valueDate, setValueDate] = React.useState(dayjs('2014-08-18T21:11:54'));
  const [valueDate, setValueDate] = React.useState(dayjs());
  //const [valueDate, setValueDate] = React.useState(dayjs('1999-01-01'));

  const [emailverificated, setemailverificated] = React.useState(false);

  const [emailsended, setemailsended] = React.useState(false);
  const [errorsendingemail, seterrorsendingemail] = React.useState(false);

  const [valuefullname, setvaluefullname] = React.useState('');

  
  
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
    };
    
    
    const handleClickCloseDialog = () => {
    setOpenDialog(false);
    };




  const handleChangeDate = (newValue) => {
    setValueDate(newValue);
  };




  function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  
  
   let query = useQuery();
  const [codeemailsent, setcodeemailsent] = useState(false);
  const [codeemailforchecking, setcodeemailforchecking] = useState(md5(codeemail));
  

  




  const ismobref = useRef(null);

  const [OpenDialoglink, setOpenDialoglink] = useState(false);
   const refbtnsubmit = useRef(null);

  
  const handleClickOpenDialoglink = () => {
    setOpenDialoglink(true);
    };
    
    
    const handleClickCloseDialoglink = () => {
      if(ismobref.current){
        ismobref.current.click();
      }
    setOpenDialoglink(false);
    };

  const hostn=window.location.hostname;
  const full = window.location.protocol + '//' + window.location.host;
  
  const ismoblinkred='intent:'+full+'/registerdon#Intent;package=com.android.chrome;end';
  const [valuelink, setvaluelink] = useState(ismoblinkred);
  
   useEffect(() => {
    
    //    setValueDate(dayjs().set('date', userinfos[0].daydon).set('month', userinfos[0].monthdon-1).set('year', userinfos[0].yeardon))

    
    const valueDateGet_get = query.get('valueDateGet');
    if(valueDateGet_get !=null && !isNaN(Number(valueDateGet_get)) ){

      var date = new Date(parseInt(valueDateGet_get));

///var fdate = (date.getMonth() + 1)+'/'+ date.getDate()  +'/'+date.getFullYear()

        //alert(date.getDate() )
      setValueDate(dayjs().set('date', date.getDate()).set('month', date.getMonth()).set('year', date.getFullYear()))

       
    }




    const textmaskphone1_get = query.get('textmaskphone1');
    if(textmaskphone1_get !=null && !isNaN(Number(textmaskphone1_get)) ){
      setValuesphone1({
        ...valuesphone1,
        textmaskphone1: '0'+textmaskphone1_get,
      });
    }

    const textmaskphone2_get = query.get('textmaskphone2');
    if(textmaskphone2_get !=null && !isNaN(Number(textmaskphone2_get)) ){
      setValuesphone2({
        ...valuesphone2,
        textmaskphone2: '0'+textmaskphone2_get,
      });
    }



    const valuefullname_get = query.get('valuefullname');
    if(valuefullname_get !=null){
      setvaluefullname(valuefullname_get);
    }
    

    const valueemail_get = query.get('valueemail');
    if(valueemail_get !=null){
      //alert('email exist'+valueemail_get)
      setValueemail(valueemail_get);
    }
    
    

    const codeemailsent_get = query.get('codeemailsent');
     
    const codeemail_get = query.get('codeemailcrypt');
     
    if(codeemail_get !=null && codeemail_get.length==32 && codeemailsent_get =="true" ){
      
      setcodeemailforchecking(codeemail_get);
      //alert(codeemailforchecking)
      setcodeemailsent(true)
      
    }else{
      setcodeemailforchecking(md5(codeemail));
      setcodeemailsent(false)
    }

    const bloodtype_get = query.get('bloodtype');
    if(bloodtype_get !=null && !isNaN(Number(bloodtype_get)) ){
      //alert(bloodtype_get)
      setbloodtype(bloodtype_get);
    }


   
 


      //alert('ismobile: '+browserName);
    
    if (isMobile && (browserName !=="Chrome" || browserName=="Facebook" || browserName=="" || browserName==undefined || browserName=="undefined" )) {
      if(ismobref.current){
        ismobref.current.click();
        ///////alert(ismoblinkred);
        ////setvaluelink(ismoblinkred)
        handleClickOpenDialoglink();
  }
    }
  
  }, []);
   






 
  const ALPHA_NUMERIC_DASH_REGEX = /[a-zA-Zء-ي\s\p{N}]/; ///^[\p{Arabic}\s\p{N}]+$ //////[a-zA-Zء-ي]/




  const [valuesPassword, setValuesPassword] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const [valuesPasswordRe, setValuesPasswordRe] = React.useState({
    amount: '',
    PasswordRe: '',
    weight: '',
    weightRange: '',
    showPasswordRe: false,
  });


  const handleChangePasswordRe = (prop) => (event) => {
    setValuesPasswordRe({ ...valuesPasswordRe, [prop]: event.target.value });
  };

  const handleClickShowPasswordRe = () => {
    setValuesPasswordRe({
      ...valuesPasswordRe,
      showPasswordRe: !valuesPasswordRe.showPasswordRe,
    });
  };

  const handleMouseDownPasswordRe = (event) => {
    event.preventDefault();
  };


  /////////////////


  const handleChangePassword = (prop) => (event) => {
    setValuesPassword({ ...valuesPassword, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValuesPassword({
      ...valuesPassword,
      showPassword: !valuesPassword.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };



    const [valuesphone1, setValuesphone1] = React.useState({
        textmaskphone1: '',
        numberformat: '1320',
      });


      const [valuesphone2, setValuesphone2] = React.useState({
        textmaskphone2: '',
        numberformat: '1320',
      });

      const [valuescodeemail, setValuescodeemail] = React.useState({
        textmaskcodeemail: '',
        numberformat: '1320',
      });
    
    
      const handleChangephone1 = (event) => {
        setValuesphone1({
          ...valuesphone1,
          [event.target.name]: event.target.value,
        });
      };

      const handleChangephone2 = (event) => {
        setValuesphone2({
          ...valuesphone2,
          [event.target.name]: event.target.value,
        });
      };

      
      const handleClickcodeemail = (event) => {
        event.preventDefault();
      };

      const handleChangecodeemail = (event) => {

        if(md5(Number(event.target.value)) == codeemailforchecking){
          ///alert('verificated succussfully');
          setemailverificated(true);
        }
   
        setValuescodeemail({
          ...valuescodeemail,
          [event.target.name]: event.target.value,
        });
  

         
      };

       

      

      const ResendEmailClick = async (e) => {
        e.preventDefault();

        /// alert(codeemail+' now')
          setemailverificated(false);
          setemailsended(false);
          seterrorsendingemail(false)
          //setDirty(false)

          if(sendemailbyemailoneortwo =='https://www.doctorsdz.machrou3.com/user/sendemailbyemailone/'){
            sendemailbyemailoneortwo='https://www.doctorsdz.machrou3.com/user/sendemailbyemailtwo/';
          }
          else{
            sendemailbyemailoneortwo='https://www.doctorsdz.machrou3.com/user/sendemailbyemailone/';
          }


        let axiosConfig = {
            headers: {
                //'Content-Type' : 'application/json; charset=UTF-8',
                ///application/x-www-form-urlencoded
                ///'Content-Type' : 'application/x-www-form-urlencoded',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Accept': 'Token',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "POST",
                "Access-Control-Allow-Headers": "*",
                "Access-Control-Max-Age": "86400",
 
             }
          };

       // 
        try {

            const params = new URLSearchParams();
            params.append('codeemail', codeemail);
            params.append('emailtosend', valueemail);
           
            await axios.post(sendemailbyemailoneortwo
            , params,axiosConfig)
            .then(response => {
                  
                 if(codeemail == response.data.codeemail){
                  
                  setemailsended(true);
                  //alert('resp '+response.data.emailtosend)
                  

                 }
                  
                console.log(response)
            })
            //navigate('/dashboard');
 
        } catch (error) {
            
            if (error.response) {
                seterrorsendingemail(true)
                console.log(error.response)
                return 
            }

        }

 

       
    }






      







    const onsubmitclicked = async (e) => {

       


      let axiosConfig = {
          headers: {
              //'Content-Type' : 'application/json; charset=UTF-8',
              ///application/x-www-form-urlencoded
              ///'Content-Type' : 'application/x-www-form-urlencoded',
              'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
              'Accept': 'Token',
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "POST",
              "Access-Control-Allow-Headers": "*",
              "Access-Control-Max-Age": "86400",

           }
        };

      e.preventDefault();
      try {

          const params = new URLSearchParams();
          params.append('Namef', valuefullname);
          params.append('bloodtype', bloodtype);
          params.append('Tel1', valuesphone1.textmaskphone1);

          params.append('Tel2', valuesphone2.textmaskphone2);
          params.append('Wilaya', valuewly.value);
          params.append('idbaladiya', valuebld.value);
          params.append('daydon', valueDate.get('date'));
          params.append('monthdon', valueDate.get('month')+1);
          params.append('yeardon', valueDate.get('year'));
          params.append('Email_doct', valueemail);   
          params.append('password', valuesPassword.password);      

 /*
    refphone1
refphone2
refemail
refcodeemail
refpassword1
refpassword2
  ///alert(valueDate.get('year'))
      
     dayjs().get('year')
     dayjs().get('month') // start 0
     dayjs().get('date')
      

     
  /*  alert('bloodtype:'+bloodtype+'  valuewly:'+valuewly.value+' valuebld'+valuebld.value+' valuefullname'+valuefullname
    +'valuesphone1.textmaskphone1: '+valuesphone1.textmaskphone1+' valuesphone1.textmaskphone2: '+valuesphone2.textmaskphone2+' valueemail: '+valueemail+' valuesPassword.password:'+valuesPassword.password);
*/

          await axios.post('https://www.doctorsdz.machrou3.com/user/insertorupdatedonuser/'
          , params,axiosConfig)
          .then(response => {
                  
            if(response.data.savedsucces){
                  navigate('/LoginDon');
                 /// handleClickOpenDialog();
                  
            }else{
                alert('لم يتم تسجيل المعلومات حاول مرة')
            }
                 ///alert('response '+response.data.Namef)
               

                
              console.log(response)
          })
          //navigate('/dashboard');

      } catch (error) {
          
          if (error.response) {
              
              console.log(error.response)
              return 
          }

      }



     
  }















    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');
    const [msg, setMsg] = useState('');
    

    const [showhidvalue, setshowhidvalue] = useState('HideBld');

    const [bloodtype, setbloodtype] = React.useState(options_Sp[0].value);

    const [options_Bld, setoptions_Bld] = useState([]);


    const [valuebld, setvaluebld] = useState([]);

  const [valuewly, setvaluewly] = useState([]);

  const [phoneV, setphoneV] = useState(0);

  const phone = useRef('phone');

  const reffullname = useRef(null);

  const refphone1 = useRef(null);
  const refphone2 = useRef(null);
  const refemail = useRef(null);
  const refcodeemail = useRef(null);
  const refpassword1 = useRef(null);
  const refpassword2 = useRef(null);



const onChangePhone = (data) => {
    setphoneV(data.target.value)
  }



  

  const [valueemail, setValueemail] = useState('');
  const [isValidemail, setIsValidemail] = useState(false);
  ///const [dirty, setDirty] = useState(false);

  useEffect(() => {

  
  if(isEmail(valueemail)) {
    setIsValidemail(true);  
    console.log(valueemail+"   yes email");            
 } else {
    setIsValidemail(false); 
    console.log(valueemail+"   not email");                         
 }

  
}, [valueemail]);



  const handleChangeemail = event => {
    const val = event.target.value;                
    setValueemail(val); 
    
    /////handleChangeemail(val, isValidemail);
 }

 const onemailblur = event => {
  //alert('onblur')
  event.preventDefault();
  const val = event.target.value;   
   
  if(isValidemail){

    ResendEmailClick(event);  
 }

  /*if(isValidemail){
  setDirty(true);
  }*/

   
    
 }
/*
 useEffect((e) => {
  console.log('diry changed='+dirty)
  
  
}, [dirty]);*/

/*
useEffect(() => {
  window.addEventListener("beforeunload", alertUser);
  return () => {
    window.removeEventListener("beforeunload", alertUser);
  };
}, []);
const alertUser = (e) => {
  e.preventDefault();
  e.returnValue = "";
};
*/


  useEffect(() => {
     if(emailsended && !emailverificated){

      if(refbtnsubmit.current){
        refbtnsubmit.current.click();
      }

      if(refcodeemail.current){
        refcodeemail.current.focus();
        return
      } 
     } 
   
    
  }, [emailsended]);

useEffect(() => {
  console.log('isValidemail '+isValidemail+ 'emailverificated ' +emailverificated + 'emailsended '+emailsended )
  setemailsended(false);
 
}, [isValidemail]);





  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
        // Default transform is "translate(14px, 20px) scale(1)""
        // This lines up the label with the initial cursor position in the input
        // after changing its padding-left.
        transform: "translate(34px, 20px) scale(1);",
        fontFamily: 
        '"Changa", sans-serif',
              fontSize: 25,
              direction: "rtl"
      }
    },
    inputRoot: {
      
      color: "#191970",

      
      // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        fontFamily: '"Tajawal", sans-serif', 
        fontWeight:700,
        color: "#191970",
        // Default left padding is 6px
        paddingLeft: 26
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "#add8e6"
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "red"
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "purple"
      }
    }
  }));

  const classes = useStyles();
  
  const useStylesSelect = makeStyles(() => ({
    customselect: {
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "#add8e6",
      },
      '& .MuiSelect-select': {
        fontFamily: '"Tajawal", sans-serif', 
        fontWeight:700,
        color: "#191970",
        // Default left padding is 6px
        paddingLeft: 26
      }
    },


  }));

  const classesSelect = useStylesSelect();


  const onSpChanged = (event, values) => {
    //settags(values);
     selectSpValue=event.target.value;
  console.log(event.target.value);
  setbloodtype(event.target.value);
  //alert(event.target.value)
  //onSearchClick();
    
  }




  const onwlyChanged = (event, values) => {
    
    
    console.log('selectedWlyOption value= '+values.value)
    selectWlyValue=values.value;
     setvaluewly(values);
     selectedWlyOption=values;
///alert("wly changed")
    ///onSearchClick();

  }


  const onbldChanged = (event, values) => {
    selectBldValue=values.value;
    setvaluebld(values)
    console.log('values.value onbldChanged'+values)
    ///onSearchClick();
  }


     useEffect(() => {
    // Logging the event that component is mount on first render
     console.log('useEffect is mounted');
      
     selectSpValue=0;
     selectWlyValue=0;
     selectBldValue=0;
        
 
   fetch('https://doctorsdz.machrou3.com/user/getBaladiya4appdoc')
   .then(response=> response.json())
   .then(getBaladiya4appdoc => {
    
   Wlylists=getBaladiya4appdoc;
   Bldlists=getBaladiya4appdoc;
   BldlistsCard=getBaladiya4appdoc;


   /////////////Wilayets///////////////////

const WlyOnly = Wlylists.filter(Wlylists =>{
  return Wlylists.wlycaptal !==null;
  })
  
  options_Wly =WlyOnly.sort(function(a, b){
  if(Number(a.MatWilaya) < Number(b.MatWilaya)) return -1;  // Ascending Order
  if(Number(a.MatWilaya) > Number(b.MatWilaya)) return 1;   // Descending Order  
  return 0; // Default
  }).map(function (Wlylists,i) {
  return { value: Wlylists.MatWilaya, label:  Wlylists.MatWilaya+'- ' + Wlylists.NOM+' ('+Wlylists.Nomfr+')',indexvalue:i};
  }) 
  
  setvaluewly(options_Wly[0]);
  
   
   });
  

}, []);


useEffect(() => {
    if (options_Wly.length!=0) {

        ////alert('wly loaded');
      
        selectWlyValue=valuewly.value;
    
        selectedWlyOption=valuewly;


        const valuewly_get = query.get('valuewly');
        if(valuewly_get !=null && !isNaN(Number(valuewly_get)) ){
        
        
    
          if(options_Wly.length !=0 ){
           /// alert(valuewly_get) ;
            const  WlyName = options_Wly.find(options_Wly =>{
              return options_Wly.value ==valuewly_get;
            })
            if( WlyName !=null){
             setvaluewly(WlyName)
            }else{
              setvaluewly(options_Wly[0]);
            }
          } 
    
    
        }



    {BldOnly = Bldlists.filter(Bldlists =>{
      

      return Bldlists.MatWilaya ==selectedWlyOption.value;
    })}

    console.log('BldOnly value= '+BldOnly.length)

    /////////////Baladia///////////////////
{setoptions_Bld (BldOnly.sort(function(a, b){
if(Number(a.ID) < Number(b.ID)) return -1;  // Ascending Order
if(Number(a.ID) > Number(b.ID)) return 1;   // Descending Order  
return 0; // Default
}).map(function (Bldlists,i) {
return { value: Bldlists.ID, label: (i+1)+'- ' + Bldlists.NOM+' ('+Bldlists.Nomfr+')',indexvalue:i};
}))}

    }
     
    
    
  }, [valuewly]);


  useEffect(() => {

    if (options_Bld.length != 0) {
     
       // alert('options_Bld loaded'+options_Bld[0].label);
        

        const valuebld_get = query.get('valuebld');
        if(valuebld_get !=null && !isNaN(Number(valuebld_get)) ){
    
          if(options_Bld.length !=0 ){
           /// alert(valuebld_get) ;
            const  bldName = options_Bld.find(options_Bld =>{
              return options_Bld.value ==valuebld_get;
            })
            if( bldName !=null){
              selectBldValue=valuebld_get;
             setvaluebld(bldName)
            }else{
              selectBldValue=0;
              setvaluebld(options_Bld[0]);
            }
          } 
    
    
        }else{
          selectBldValue=0;
        setvaluebld(options_Bld[0]);
        }
    
    
}
    
}, [options_Bld]);




 


    ///const history = useHistory();
    const navigate = useNavigate();
    ///navigate('/home');


    const Register = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://doctorsdz.machrou3.com/user/singup_don_appdoc', {
                name: name,
                email: email,
                password: password,
                confPassword: confPassword
            }).then(response => {
                setMsg(response.data.msg);
                ///console.log(JSON.parse(JSON.stringify(response)));
                alert(JSON.parse(JSON.stringify(response.data.msg)))
                ///JSON.parse(JSON.stringify(response));
            });
            //history.push("/");
            //navigate('/');
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }

    return (


        <>

<a style={{ display:'none' }} href={valuelink}
 
 ref={ismobref}
 
 target="_blank"></a>


        <Header />

        <form    >



        { !(Wlylists.length || Bldlists.length || options_Sp.length || options_Wly.length) ?  
    <>
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <CircularProgress />
      {/*<Box sx={{ width: '100%',mt:2 }} > 
      
      <LinearProgress />
      </Box>
      */}

      </div>
 
      </>

      :

      <>


        <Container component="main" maxWidth="md">

<Grid
container
spacing={0}
direction="column"
alignItems="center"
justifyContent="center"
style={{ minHeight: '100vh' }}
>

<div className="container" style={{marginTop:'-30px'}}>
           
           <div className="row text-center">


           
           <CacheProvider value={cacheRtl}>

           <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth variant="outlined"
       
       classes={{ root: classesSelect.customselect }}
       >
 
        <InputLabel id="demo-simple-select-label"
        sx={{ color: "#b31d3e", fontFamily: 
        '"Tajawal", sans-serif', 
        fontSize: 16
        ,fontWeight:700 }}
        >زمرة الدم</InputLabel>
        <Select
          name='bloodtype'
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={bloodtype}
          label="زمرة الدم"
          onChange={onSpChanged}
          
          classes={{ root: classesSelect.customselect }}
        >
   
  
  {options_Sp.map((u,i) =>

i % 2 == 0 ? 
      
<MenuItem key={i} sx={{ backgroundColor: "#add8e6",color: "#191970", fontFamily: 
'"Tajawal", sans-serif', 
fontSize: 18
,fontWeight:700 }} value={u.value} primaryText={u.label}>
{u.label}
</MenuItem>
      
            
          :
           
          <MenuItem key={i} sx={{ backgroundColor: "#FFFFFF",color: "#191970", fontFamily: 
          '"Tajawal", sans-serif', 
          fontSize: 18
          ,fontWeight:700 }} value={u.value} primaryText={u.label}>
          {u.label}
          </MenuItem>

           
          )}
       
       
    
        
         
        </Select>
      </FormControl>
    </Box>



</CacheProvider>






{/* Autocomplement Wilayat  */}
  
<CacheProvider value={cacheRtl}>

 
<Autocomplete

noOptionsText={'حدث الصفحة مرة أخرى'}
classes={classes}
id="combo-box-demo"
onChange={onwlyChanged}
options={options_Wly}
value={valuewly}
sx={{ mt: 2 }}
defaultValue={options_Wly[0]}
//disablePortal
/* classes={{
option: styles.option
}}*/
renderOption={(props, option) => {
const { label, indexvalue } = option;

return (
indexvalue % 2 == 0 ? 

<span {...props}   style={{ backgroundColor: "#add8e6",color: "#191970", fontFamily: 
'"Tajawal", sans-serif', 
fontSize: 18
,fontWeight:700 }}>

{(indexvalue  == -1 ?
<>
  {'- ' +label} 
   </>
  :
  <>
  {label} 
  </>
  )}
  

</span>
:

<span {...props}   style={{ backgroundColor: "#FFFFFF",color: "#191970", fontFamily: 
'"Tajawal", sans-serif', 
fontSize: 18
,fontWeight:700 }}>

{(indexvalue  == -1 ?
<>
  {'- ' +label} 
   </>
  :
  <>
  {label} 
  </>
  )}


</span>

);
}} 



//sx={{ width: 300 }}
renderInput={(params) => <Box display="flex" justifyContent="center">  
<TextField fullWidth 

 {...params} label="الولايات"  /> </Box>  }
/>



</CacheProvider>





{/* Autocomplement Baladia */ }
  
<CacheProvider value={cacheRtl}>

 
   
<Autocomplete

noOptionsText={'إختر الولاية'}
classes={classes}
value={valuebld}
id="combo-box-demo"
options={options_Bld}
defaultValue={options_Bld[0]}
/* onChange={(_, newValue) => {
setvaluebld(newValue)
}}*/
sx={{ mt: 2 }}
// value={selectedBldOption}
onChange={onbldChanged}
//disablePortal
/*classes={{
option: styles.option
}}*/
renderOption={(props, option) => {
const { label, indexvalue } = option;

return (
indexvalue % 2 == 0 ? 

<span {...props}   style={{ backgroundColor: "#add8e6",color: "#191970", fontFamily: 
'"Tajawal", sans-serif', 
fontSize: 18
,fontWeight:700 }}>

{(indexvalue  == -1 ?
<>
  {'- ' +label} 
   </>
  :
  <>
  {label} 
  </>
  )}
  

</span>
:

<span {...props}   style={{ backgroundColor: "#FFFFFF",color: "#191970", fontFamily: 
'"Tajawal", sans-serif', 
fontSize: 18
,fontWeight:700 }}>

{(indexvalue  == -1 ?
<>
  {'- ' +label} 
   </>
  :
  <>
  {label} 
  </>
  )}


</span>

);
}} 



//sx={{ width: 300 }}
renderInput={(params) => <Box display="flex" justifyContent="center">  <TextField fullWidth  {...params} label="البلديات"  /> </Box>  }
/>


</CacheProvider>

{/* Full name */ }
  

<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box sx={{ mt: 2 }}>

<Grid container >
       

        <Grid item xs={12}>
        
<TextField fullWidth 
name="valuefullname"  
error={valuefullname.length<4? true: false}
helperText={valuefullname.length<4? "لا تترك خانة الإسم الكامل فارغة": ""}
value={valuefullname}
inputRef={reffullname}
onChange={(ev)=>{
  
    const value = ev.target.value;
    if (value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(value)) {
      return;
    }
  setvaluefullname(ev.target.value)
}}
sx={{
    
     
    '& .MuiFormHelperText-root': {
      fontFamily: '"Tajawal", sans-serif',
       fontSize:'14px',
       color: '#b31d3e',
       fontWeight:700
        
   },
    '& .MuiFormLabel-root': {
      fontFamily: '"Tajawal", sans-serif',
       fontSize:'16px',
       color: '#191970'
   },
    '& .MuiInputBase-input': {
       fontFamily: '"Tajawal", sans-serif',
        fontSize:'18px',
        color: '#191970',
        fontWeight:700
    },
    '& label.Mui-focused': {
      fontSize:'16px',
      color: '#b31d3e',
      fontWeight:700
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#add8e6',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        fontFamily: '"Tajawal", sans-serif',
        fontSize:'16px',
        borderColor: '#add8e6',
      borderWidth: "2px",
       
      },
      '&:hover fieldset': {
        borderColor: 'purple',
      },
      '&.Mui-focused fieldset': {
        
        borderColor: 'purple',
      },
    } 
  }}
  
  id="outlined-basic" label="الإسم الكامل" variant="outlined" />

   
</Grid>
      </Grid>

</Box>


</MuiThemeProvider>


</CacheProvider>



{/* phone01 */ }
<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box sx={{ mt: 2 }}>

<Grid container >
       

        <Grid item xs={12}>

        <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth variant="outlined"
       
       
       >

        <InputLabel id="demo-simple-select-label"
        sx={{ color: "#b31d3e", fontFamily: 
        '"Tajawal", sans-serif', 
        fontSize: 16
        ,fontWeight:700 }}
        >هاتف 01  (إجباري) </InputLabel>

 
        <Input  
        fullWidth 
        dir="ltr"  
        sx={{
             
             //direction:'ltr',
             
            '& .MuiFormHelperText-root': {
              fontFamily: '"Tajawal", sans-serif',
               fontSize:'14px',
               color: '#b31d3e',
               fontWeight:700
                
           },
            '& .MuiFormLabel-root': {
              fontFamily: '"Tajawal", sans-serif',
               fontSize:'18px',
               color: '#191970',
               dir: 'rtl',
                
           },
            '& .MuiInputBase-input': {
               fontFamily: '"Tajawal", sans-serif',
                fontSize:'22px',
                color: '#191970',
                fontWeight:700,
                //text-align: right,
                
                 

                          },
            '& label.Mui-focused': {
              fontSize:'16px',
              color: '#b31d3e',
              fontWeight:700
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: '#add8e6',
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                fontFamily: '"Tajawal", sans-serif',
                fontSize:'16px',
                borderColor: '#add8e6',
              borderWidth: "2px",
               
              },
              '&:hover fieldset': {
                borderColor: 'purple',
              },
              '&.Mui-focused fieldset': {
                
                borderColor: 'purple',
              },
            } 
          }}
  
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          value={valuesphone1.textmaskphone1}
          inputRef={refphone1}
          defaultValue="0"
          ///error={valuesphone1.textmaskphone1.length !=9 ? true:false}
          ///helperText={valuesphone1.textmaskphone1.length !=9 ? "أدخل رقم هاتف صحيح":""}
          onChange={handleChangephone1}
          name="textmaskphone1"
           id="formatted-text-mask-input"
          inputComponent={textmaskphone1Custom}
          />
          
          {valuesphone1.textmaskphone1.length !=10 ? <>
        
        <FormHelperText 
         sx={{ color: "#b31d3e", fontFamily: 
         '"Tajawal", sans-serif', 
         fontSize: 14
         ,fontWeight:700 }}
         error id="accountId-error">
          أدخل رقم هاتف صحيح
          </FormHelperText>
            
      
      </>: <></>}



          </FormControl>
          </Box>
</Grid>
 
</Grid>
 </Box>

</MuiThemeProvider>


</CacheProvider>







{/* phone02 */ }
<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box sx={{ mt: 2 }} >

<Grid container >
       

        <Grid item xs={12}>

        <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth variant="outlined"
       
       
       >

        <InputLabel id="demo-simple-select-label"
        sx={{ color: "#b31d3e", fontFamily: 
        '"Tajawal", sans-serif', 
        fontSize: 16,
        color:'green'
        ,fontWeight:700 }}
        > هاتف 02 (إختياري)</InputLabel>
 
        <Input  
        fullWidth 
        dir="ltr"  
        sx={{
            
             //direction:'ltr',
             
            '& .MuiFormHelperText-root': {
              fontFamily: '"Tajawal", sans-serif',
               fontSize:'14px',
               color: '#b31d3e',
               fontWeight:700
                
           },
            '& .MuiFormLabel-root': {
              fontFamily: '"Tajawal", sans-serif',
               fontSize:'18px',
               color: '#191970',
               dir: 'rtl',
                
           },
            '& .MuiInputBase-input': {
               fontFamily: '"Tajawal", sans-serif',
                fontSize:'22px',
                color: '#191970',
                fontWeight:700,
                //text-align: right,
                
                 

                          },
            '& label.Mui-focused': {
              fontSize:'16px',
              color: '#b31d3e',
              fontWeight:700
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: '#add8e6',
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                fontFamily: '"Tajawal", sans-serif',
                fontSize:'16px',
                borderColor: '#add8e6',
              borderWidth: "2px",
               
              },
              '&:hover fieldset': {
                borderColor: 'purple',
              },
              '&.Mui-focused fieldset': {
                
                borderColor: 'purple',
              },
            } 
          }}
  
          type="number"
          inputRef={refphone2}
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue="0"
          value={valuesphone2.textmaskphone2}
          onChange={handleChangephone2}
          name="textmaskphone2"
           id="formatted-text-mask-input"
          inputComponent={textmaskphone2Custom}
          />
          
          {valuesphone2.textmaskphone2.length !=10 && valuesphone2.textmaskphone2 !=0 && valuesphone2.textmaskphone2.length !=0 ? <>
        
        <FormHelperText 
         sx={{ color: "#b31d3e", fontFamily: 
         '"Tajawal", sans-serif', 
         fontSize: 14
         ,fontWeight:700 }}
         error id="accountId-error">
        أتركها فارغة أو أدخل رقم هاتف صحيح
          </FormHelperText>
            
      
      </>: <></>}

          </FormControl>
          </Box>
</Grid>
 
</Grid>
 </Box>

</MuiThemeProvider>


</CacheProvider>







{/* Datepicker */ }
<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box >

<Grid container >
       

        <Grid item xs={12}>

        <Box sx={{ minWidth: 120, mt: 2 }} >
      <FormControl fullWidth variant="outlined"
       
       
       >

       
 


<LocalizationProvider dateAdapter={AdapterDayjs}>

<MobileDatePicker

fullWidth 
        dir="ltr"  
        
          name="valueDate" 
          label="تاريخ آخر تبرع"
          inputFormat="DD/MM/YYYY"
          value={valueDate}
           
          onChange={handleChangeDate}
          renderInput={(params) => <TextField
            sx={{
              
               //direction:'ltr',
               
              '& .MuiFormHelperText-root': {
                fontFamily: '"Tajawal", sans-serif',
                 fontSize:'14px',
                 color: '#b31d3e',
                 fontWeight:700
                  
             },
              '& .MuiFormLabel-root': {
                fontFamily: '"Tajawal", sans-serif',
                 fontSize:'18px',
                 color: '#191970',
                 dir: 'rtl',
                  
             },
              '& .MuiInputBase-input': {
                 fontFamily: '"Tajawal", sans-serif',
                  fontSize:'22px',
                  color: '#191970',
                  fontWeight:700,
                  //text-align: right,
                  
                   
  
                            },
              '& label.Mui-focused': {
                fontSize:'16px',
                color: '#b31d3e',
                fontWeight:700
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: '#add8e6',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  fontFamily: '"Tajawal", sans-serif',
                  fontSize:'16px',
                  borderColor: '#add8e6',
                borderWidth: "2px",
                 
                },
                '&:hover fieldset': {
                  borderColor: 'purple',
                },
                '&.Mui-focused fieldset': {
                  
                  borderColor: 'purple',
                },
              } 
            }}
  
            {...params} />}
        />


</LocalizationProvider>



</FormControl>
          </Box>
</Grid>
 
</Grid>
 </Box>

</MuiThemeProvider>


</CacheProvider>




{/* Email */ }
<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box sx={{ mt: 2 }}>
                  

<Grid container >
       

        <Grid item xs={12}>

        <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth variant="outlined"
       
       
       >

                <TextField    
                
                sx={{
                 
                   //direction:'ltr',
                   
                  '& .MuiFormHelperText-root': {
                    fontFamily: '"Tajawal", sans-serif',
                     fontSize:'14px',
                     color: '#b31d3e',
                     fontWeight:700
                      
                 },
                  '& .MuiFormLabel-root': {
                    fontFamily: '"Tajawal", sans-serif',
                     fontSize:'18px',
                     color: '#191970',
                     dir: 'rtl',
                      
                 },
                  '& .MuiInputBase-input': {
                     fontFamily: '"Tajawal", sans-serif',
                      fontSize:'22px',
                      color: '#191970',
                      fontWeight:700,
                      //text-align: right,
                      
                       
      
                                },
                  '& label.Mui-focused': {
                    fontSize:'16px',
                    color: '#b31d3e',
                    fontWeight:700
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#add8e6',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize:'16px',
                      borderColor: '#add8e6',
                    borderWidth: "2px",
                     
                    },
                    '&:hover fieldset': {
                      borderColor: 'purple',
                    },
                    '&.Mui-focused fieldset': {
                      
                      borderColor: 'purple',
                    },
                  } 
                }}

                      inputRef={refemail}
                     error={isValidemail === false}  
                     label="البريد الإلكتروني"
                     helperText={isValidemail === false ? "أدخل عنوان بريد إلكتروني صالح": <>

                     
                      
                     {(isValidemail === true && emailverificated === false && emailsended === true) ? 
                      "تم إرسال رمز التحقق إلى بريدك الإلكتروني": 
                      
                      <>
                       
                       {emailverificated? "تم التحقق من بريدك الإلكتروني بنجاح" : <> </>
                       }

                      </>}
                     
                       

                      </>}  

                    onBlur={(e) => onemailblur(e)}
                    onKeyPress={(ev) => {
                      //console.log(`Pressed keyCode ${ev.key}`);
                     if (ev.key === 'Enter') {
                      onemailblur(ev);
                       ev.target.blur();///to hide keyboard
                       console.log("enter")
                       // Do code here
                       //console.log(JSON.stringify(ev.target.value));
                       //this.onSearchClickbtn();
                       ev.preventDefault();
                     }
                   }}

                  ///  id={props.fieldName}                    
                   /// label={props.label}
                  //  name={props.fieldName}                    
                    variant="outlined" 
                    size={'small'}
                   // helperText={props.helperText}
                   name="valueemail"
                    value={valueemail}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => handleChangeemail(e)}

                    InputProps={{
                      endAdornment: (
                        <InputAdornment>


                            {emailverificated? <>
                              <CheckCircleIcon sx={{ color: "green" }} />
                              </>: <>
                              
                              {((isValidemail === true)  && codeemailsent) || ((isValidemail === true) && emailsended  ) ? 
                              <>
                              
                                <IconButton 
                          onClick={(e) => {
                            if(isValidemail){

                              ResendEmailClick(e);  
                           }
                             
                          }}
                           
          
                          >
                          <ReplayIcon sx={{ color: "#191970" }} />  
                          </IconButton>
                              
                              </>: <></> }
                              
                              
                              
                              </>}
                          

                        </InputAdornment>
                      )
                    }}
          

                    
                     
                    disabled={emailverificated}


                />
                
            </FormControl>


            </Box>
</Grid>
 
</Grid>
 </Box>

</MuiThemeProvider>


</CacheProvider>




{((isValidemail === true)  && codeemailsent) || ((isValidemail === true) && emailsended  ) ? 

<>


{/* Code verification Email */ }
<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box >

<Grid container >
       

        <Grid item xs={12}>

        <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth variant="outlined"
       
       
       >

       {/*  <InputLabel id="demo-simple-select-label"
        sx={{ color: "#b31d3e", fontFamily: 
        '"Tajawal", sans-serif', 
        fontSize: 16,
        color:'green'
        ,fontWeight:700 }}
        >أدخل الرمز الذي وصلك في البريد الإلكتروني</InputLabel>
 */}

        <Input  
        label="أدخل الرمز الذي وصلك في البريد الإلكتروني"
        helperText="إضغط على السهم الدائري لإعادة إرسال الرمز (تصفح صندوق الواردات لديك بما فيهم البريد المزعج )"
        fullWidth 
        dir="ltr"  
        sx={{
           
             //direction:'ltr',
             
            '& .MuiFormHelperText-root': {
              fontFamily: '"Tajawal", sans-serif',
               fontSize:'14px',
               color: '#b31d3e',
               fontWeight:700
                
           },
            '& .MuiFormLabel-root': {
              fontFamily: '"Tajawal", sans-serif',
               fontSize:'18px',
               color: '#191970',
               dir: 'rtl',
                
           },
            '& .MuiInputBase-input': {
               fontFamily: '"Tajawal", sans-serif',
                fontSize:'22px',
                color: '#191970',
                fontWeight:700,
                //text-align: right,
                
                 

                          },
            '& label.Mui-focused': {
              fontSize:'16px',
              color: '#b31d3e',
              fontWeight:700
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: '#add8e6',
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                fontFamily: '"Tajawal", sans-serif',
                fontSize:'16px',
                borderColor: '#add8e6',
              borderWidth: "2px",
               
              },
              '&:hover fieldset': {
                borderColor: 'purple',
              },
              '&.Mui-focused fieldset': {
                
                borderColor: 'purple',
              },
            } 
          }}
          hidden={emailverificated}
          inputRef={refcodeemail}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          value={valuescodeemail.textmaskcodeemail}
          onChange={handleChangecodeemail}
          onClick={(e) => handleClickcodeemail(e)}
          onFocus={(e)=> {
            ///alert('onfoucs')
            e.preventDefault();
          }}
          name="textmaskcodeemail"
           id="formatted-text-mask-input"
          inputComponent={textmaskcodeemailCustom}
          autoFocus={true} 
          
          
          />
          
          </FormControl>
          </Box>
</Grid>
 
</Grid>
 </Box>

</MuiThemeProvider>


</CacheProvider>


</>

: 


<>




</>



}







{/* Password 01 */ }
<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box sx={{ mt: 2 }}>

<Grid container >
       

        <Grid item xs={12}>

        <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth variant="outlined"
       
       
       > 


 
          <InputLabel 
          sx={{ color: "#b31d3e", fontFamily: 
          '"Tajawal", sans-serif', 
          fontSize: 16
          ,fontWeight:700 }}
          htmlFor="outlined-adornment-password">كلمة المرور</InputLabel>
          <OutlinedInput
           fullWidth 
           dir="rtl"  
           sx={{
                
                //direction:'ltr',
                
               '& .MuiFormHelperText-root': {
                 fontFamily: '"Tajawal", sans-serif',
                  fontSize:'14px',
                  color: '#b31d3e',
                  fontWeight:700
                   
              },
               '& .MuiFormLabel-root': {
                 fontFamily: '"Tajawal", sans-serif',
                  fontSize:'18px',
                  color: '#191970',
                  dir: 'rtl',
                   
              },
               '& .MuiInputBase-input': {
                  fontFamily: '"Tajawal", sans-serif',
                   fontSize:'22px',
                   color: '#191970',
                   fontWeight:700,
                   //text-align: right,
                   
                    
   
                             },
               '& label.Mui-focused': {
                 fontSize:'16px',
                 color: '#b31d3e',
                 fontWeight:700
               },
               '& .MuiInput-underline:after': {
                 borderBottomColor: '#add8e6',
               },
               '& .MuiOutlinedInput-root': {
                 '& fieldset': {
                   fontFamily: '"Tajawal", sans-serif',
                   fontSize:'16px',
                   borderColor: '#add8e6',
                 borderWidth: "2px",
                  
                 },
                 '&:hover fieldset': {
                   borderColor: 'purple',
                 },
                 '&.Mui-focused fieldset': {
                   
                   borderColor: 'purple',
                 },
               } 
             }}
  
             inputRef={refpassword1}
            id="outlined-adornment-password"
            type={valuesPassword.showPassword ? 'text' : 'password'}
            value={valuesPassword.password}
            name="valuesPassword"
            onChange={handleChangePassword('password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {valuesPassword.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="كلمة المرور"
          />
       </FormControl>
          </Box>
</Grid>
 
</Grid>
 </Box>

</MuiThemeProvider>


</CacheProvider>






{/* Password Renter */ }
<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box sx={{ mt: 2 }}>

<Grid container >
       

        <Grid item xs={12}>

        <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth variant="outlined"
       
       
       > 


 
          <InputLabel 
          sx={{ color: "#b31d3e", fontFamily: 
          '"Tajawal", sans-serif', 
          fontSize: 16
          ,fontWeight:700 }}
          htmlFor="outlined-adornment-password">اعادة كلمة المرور</InputLabel>
          <OutlinedInput
             
           fullWidth 
           dir="rtl"  
           sx={{
                
                //direction:'ltr',
                
               '& .MuiFormHelperText-root': {
                 fontFamily: '"Tajawal", sans-serif',
                  fontSize:'14px',
                  color: '#b31d3e',
                  fontWeight:700
                   
              },
               '& .MuiFormLabel-root': {
                 fontFamily: '"Tajawal", sans-serif',
                  fontSize:'18px',
                  color: '#191970',
                  dir: 'rtl',
                   
              },
               '& .MuiInputBase-input': {
                  fontFamily: '"Tajawal", sans-serif',
                   fontSize:'22px',
                   color: '#191970',
                   fontWeight:700,
                   //text-align: right,
                   
                    
   
                             },
               '& label.Mui-focused': {
                 fontSize:'16px',
                 color: '#b31d3e',
                 fontWeight:700
               },
               '& .MuiInput-underline:after': {
                 borderBottomColor: '#add8e6',
               },
               '& .MuiOutlinedInput-root': {
                 '& fieldset': {
                   fontFamily: '"Tajawal", sans-serif',
                   fontSize:'16px',
                   borderColor: '#add8e6',
                 borderWidth: "2px",
                  
                 },
                 '&:hover fieldset': {
                   borderColor: 'purple',
                 },
                 '&.Mui-focused fieldset': {
                   
                   borderColor: 'purple',
                 },
               } 
             }}
  
             inputRef={refpassword2}
            id="outlined-adornment-PasswordRe"
            type={valuesPasswordRe.showPasswordRe ? 'text' : 'Password'}
            value={valuesPasswordRe.PasswordRe}
            name="valuesPasswordRe"
            onChange={handleChangePasswordRe('PasswordRe')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPasswordRe}
                  onMouseDown={handleMouseDownPasswordRe}
                  edge="end"
                >
                  {valuesPasswordRe.showPasswordRe ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="اعادة كلمة المرور"
          />

        {valuesPasswordRe.PasswordRe !== valuesPassword.password? <>
        
          <FormHelperText 
           sx={{ color: "#b31d3e", fontFamily: 
           '"Tajawal", sans-serif', 
           fontSize: 14
           ,fontWeight:700 }}
           error id="accountId-error">
            يجب أن تدخل نفس كلمة السر التي أدخلتها أعلاه
            </FormHelperText>
              
        
        </>: <></>}
          


       </FormControl>
          </Box>
</Grid>
 
</Grid>
 </Box>

</MuiThemeProvider>


</CacheProvider>





{/* Submit Button */ }
<CacheProvider value={cacheRtl}>

<MuiThemeProvider theme={themetextfieldnew}>
 
<Box sx={{ mt: 2 }}>

<Grid container >
       

        <Grid item xs={12}>

        <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth variant="outlined"
       
       
       > 

      <div>
  <Button 
  
   disabled={valuefullname.length<4 || valuesphone1.textmaskphone1.length !=10
    || isValidemail === false || valuesPasswordRe.PasswordRe !== valuesPassword.password || !emailverificated || valuesPassword.password.length==0  ? true:false}
  onClick={(e)=>{
    e.preventDefault();

    

    if(valuefullname.length<4){
     
      reffullname.current.focus();
      
      return
    }

    if(valuesphone1.textmaskphone1.length !=10){
      refphone1.current.focus();
      return
    }
    
    if(valuesphone2.textmaskphone2.length !=10 && valuesphone2.textmaskphone2 !=0 && valuesphone2.textmaskphone2.length !=0){
      refphone2.current.focus();
      return
    }
    
    if(!isEmail(valueemail)){
      refemail.current.focus();
      return
    }

       

    if(valuesPassword.password.length==0 ){
      refpassword1.current.focus();
      return
    }

    if(valuesPasswordRe.PasswordRe.length===0){
      refpassword2.current.focus();
      return
    }
     
    if(valuesPassword.password != valuesPasswordRe.PasswordRe){
      refpassword2.current.focus();
      return
    }

    onsubmitclicked(e);

     
  }}
  

  variant="contained"
   endIcon={<SaveIcon sx={{ml:'5px', dir:'rtl'}} />}>
    <Typography  sx={{ color: "#FFFFFF", fontFamily: 
           '"Tajawal", sans-serif', 
           fontSize: 22
           ,fontWeight:900 }}>
            سجل الآن      
    </Typography>
        
      </Button>



      
      <Button
      hidden
              fullWidth
          
              type="submit"
             ref={refbtnsubmit}
              variant="contained"
              
                
            startIcon={<SearchIcon sx={{ml:'0px',width:30, height:30, dir:'rtl'}} />}>
    <Typography  sx={{ color: "#FFFFFF", fontFamily: 
           '"Tajawal", sans-serif', 
           fontSize: 22
           ,fontWeight:900 }}>
           submit button    
    </Typography>
              
            </Button>



      </div>


      </FormControl>
          </Box>
</Grid>
 
</Grid>
 </Box>

</MuiThemeProvider>


</CacheProvider>





 </div>

 </div>


    
    </Grid>

    </Container>
    
    <TextField
    hidden
  name="valueDateGet"
  value={valueDate}
  
/>


    <TextField
    hidden
  name="valuewly"
  value={valuewly.value}
  
/>

<TextField
hidden
  name="valuebld"
  value={valuebld.value}
  
/>

 


    <TextField
    hidden
  name="codeemailcrypt"
  value={md5(codeemail)}
  
/>

<TextField
hidden
  name="codeemailsent"
  value={emailsended? true:false}
 />



</>
}

</form>



<Footer />
        












       
<Dialog
       PaperProps={{
        style: {
          backgroundColor: '#add8e6',
          boxShadow: 'none',
        },
      }}
       //fullScreen={mediumScreen}
        open={OpenDialog}
        onClose={handleClickCloseDialog}
        aria-labelledby="responsive-dialog-title"
      >

<Card  style={{}} sx={{ maxWidth: 345  }}>

  {/* <p className="h5 font-weight-bold text-primary" id={showhidvalueteldate}><FaCalendarAlt/> تاريخ آخر تبرع: {props.daydon+'/'+props.monthdon+'/'+props.yeardon}</p>
 */ }
<CardHeader
        avatar={
          
            <CheckCircleIcon sx={{color:'#4BB543',width: 50, height: 50,ml:2}}></CheckCircleIcon>
         
        }
        action={
         
           
        <IconButton  onClick={handleClickCloseDialog} aria-label="settings" sx={{ ml:-1,mt:-1  }}>
                    
        <CloseIcon  /> 

        </IconButton>
        } 
        title={
          <>
          <Box sx={{ ml:2, mt:0, display: 'flex', alignItems: 'flex-end', justifyContent: 'center'}}>

          <Typography sx={{ color:'#191970', fontFamily: '"Tajawal", sans-serif', fontSize: 22,fontWeight:900}} >
          تم تسجيل معلوماتك بنجاح</Typography>
            </Box>
            
            </>
        
        }
        subheader=""
      />


       


</Card>

      </Dialog>
         







      <Dialog
       PaperProps={{
        style: {
          backgroundColor: '#add8e6',
          boxShadow: 'none',
        },
      }}
       //fullScreen={mediumScreen}
        open={OpenDialoglink}
        onClose={handleClickCloseDialoglink}
        aria-labelledby="responsive-dialog-title"
      >

<Card  style={{}} sx={{ maxWidth: 345  }}>

  {/* <p className="h5 font-weight-bold text-primary" id={showhidvalueteldate}><FaCalendarAlt/> تاريخ آخر تبرع: {props.daydon+'/'+props.monthdon+'/'+props.yeardon}</p>
 */ }
<CardHeader
        avatar={
          
            <InfoIcon sx={{color:'#2196F3',width: 50, height: 50,ml:2}}></InfoIcon>
         
        }
        action={
         
           
        <IconButton  onClick={handleClickCloseDialoglink} aria-label="settings" sx={{ ml:-1,mt:-1  }}>
                    
        <CloseIcon  /> 

        </IconButton>
        } 
        title={
          <>
          <Box sx={{ ml:2, mt:0, display: 'flex', alignItems: 'flex-end', justifyContent: 'center'}}>

          <Typography sx={{ color:'#191970', fontFamily: '"Tajawal", sans-serif', fontSize: 14,fontWeight:900}} >
          من الأفضل إستعمال متصفح كروم Chrome 
          </Typography>
            </Box>
            
            </>
        
        }
        subheader=""
      />


       


</Card>

      </Dialog>
 










     



{/* 
        <section className="hero has-background-grey-light is-fullheight is-fullwidth">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-4-desktop">
                            <form onSubmit={Register} className="box">
                                <p className="has-text-centered">{msg}</p>
                                <div className="field mt-5">
                                    <label className="label">Name</label>
                                    <div className="controls">
                                        <input type="text" className="input" placeholder="Name"
                                            value={name} onChange={(e) => setName(e.target.value)} />
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <label className="label">Email</label>
                                    <div className="controls">
                                        <input type="text" className="input" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <label className="label">Password</label>
                                    <div className="controls">
                                        <input type="password" className="input" placeholder="******" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <label className="label">Confirm Password</label>
                                    <div className="controls">
                                        <input type="password" className="input" placeholder="******" value={confPassword} onChange={(e) => setConfPassword(e.target.value)} />
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <button className="button is-success is-fullwidth">Register</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
*/ }



        </>
    )
}

export default RegisterDon
