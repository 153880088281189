import CardDonState from './CardDonState';

import React, { useState,useEffect }  from 'react';
import LinearProgress from '@mui/material/LinearProgress';

import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import Box from '@mui/material/Box';
 import {
    AppBar,
    Button,
    Tab,
    Tabs,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme,
  } from "@mui/material";

    const CardListDonState = (props) => {
      /*  const [robots, setrobots] = useState([]);
        const [BldlistsCard, setBldlistsCard] = useState([]);
        const [splistsCard, setsplistsCard] = useState([]);*/

        const theme = useTheme();
        console.log(theme);
        const isMatch = useMediaQuery(theme.breakpoints.down("md"));
      console.log(isMatch);

      const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      }));
    
      
  

     
    var robotsinfo = props.robots;
    var Bldlistsinfo = props.BldlistsCard;
    var splistsinfo = props.splistsCard;
    
       console.log('robotsinfo'+robotsinfo)
    /* const BldName = Bldlistsinfo.filter(Bldlistsinfo =>{
      return Bldlistsinfo.ID ==1;
    })
    console.log(BldName[0].NOM);

    
   
    BldName = Bldlistsinfo.filter(Bldlistsinfo =>{
      return Bldlistsinfo.ID ==1;
    });
    console.log(BldName);
*/

  

   

    return (
      


      <div>
        {(!props.hasOwnProperty('robots') ? <>
          <Box sx={{ width: '100%',mt:0 }} > 
      
      <LinearProgress />
      </Box>
      </>: <></>)}

<Box sx={{ flexGrow: 1,mt:2 }}>
      <Grid container spacing={1}>


        {/* <List     sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper'
 }}>*/}



      {

         

         robotsinfo.map((user, i) => {

          
          ////////////
          let BldNameshow='';
           if(Bldlistsinfo.length !=0 &&  robotsinfo[i].idbaladiya !=null){
            const BldName = Bldlistsinfo.find(Bldlistsinfo =>{
              return Bldlistsinfo.ID ==robotsinfo[i].idbaladiya;
            })
            if( BldName !=null){
              BldNameshow=BldName.Nomfr+' ('+BldName.NOM+')';
              
            }

          
          }
          //////////////////////

          let WilyNameshow='';
           
          if(Bldlistsinfo.length !=0 && robotsinfo[i].Wilaya !=null){
            const  WlyName = Bldlistsinfo.find(Bldlistsinfo =>{
              return Bldlistsinfo.MatWilaya ==robotsinfo[i].Wilaya && Bldlistsinfo.wlycaptal ==1;
            })
            if( WlyName !=null){
              WilyNameshow=WlyName.Nomfr+' ('+WlyName.NOM+')';
              
            }
          }

          //////////////////////

          let SpNameshow='';
          if(splistsinfo.length !=0 && robotsinfo[i].bloodtype !=null){
            const SpName = splistsinfo.filter(splistsinfo =>{
              return splistsinfo.value ==robotsinfo[i].bloodtype;
            })
            if( SpName !=null){
              SpNameshow=SpName[0].value;

            }
          }

          
          return (
            <>
        
        {/* if computer */ }
         {!isMatch ? 
        
         <>


            {/* split 2 */ }
            {i % 2 == 0 ? 
            
            <>

          
        <Grid item xs={6} >
        <CardDonState
              key={i}
              ID={robotsinfo[i].ID}
              Namef={robotsinfo[i].Namef}
              Bldianame={BldNameshow}
              Wilayname={WilyNameshow}
              Spname={SpNameshow}
              bloodtype={robotsinfo[i].bloodtype}
              Tel1={robotsinfo[i].Tel1}
              Tel2={robotsinfo[i].Tel2}
              Wilaya={robotsinfo[i].Wilaya}
              idbaladiya={robotsinfo[i].idbaladiya}
              daydon={robotsinfo[i].daydon}
              monthdon={robotsinfo[i].monthdon}
              yeardon={robotsinfo[i].yeardon}
              Email_doct={robotsinfo[i].Email_doct}
              Num_sp={robotsinfo[i].bloodtype}
              />

        </Grid>

        </> 
        
        : 
        
        <>

        <Grid item xs={6}>
          
          
        <CardDonState
              key={i}
              ID={robotsinfo[i].ID}
              Namef={robotsinfo[i].Namef}
              Bldianame={BldNameshow}
              Wilayname={WilyNameshow}
              Spname={SpNameshow}
              bloodtype={robotsinfo[i].bloodtype}
              Tel1={robotsinfo[i].Tel1}
              Tel2={robotsinfo[i].Tel2}
              Wilaya={robotsinfo[i].Wilaya}
              idbaladiya={robotsinfo[i].idbaladiya}
              daydon={robotsinfo[i].daydon}
              monthdon={robotsinfo[i].monthdon}
              yeardon={robotsinfo[i].yeardon}
              Email_doct={robotsinfo[i].Email_doct}
              Num_sp={robotsinfo[i].bloodtype}
              />


        </Grid>
            {/* End split 2 */ }

        </>}      

        </>:
        
        
        <>
        {/* if mobile */ }
        
        
        <Grid item xs={12}>
        <CardDonState
             key={i}
             ID={robotsinfo[i].ID}
             Namef={robotsinfo[i].Namef}
             Bldianame={BldNameshow}
             Wilayname={WilyNameshow}
             Spname={SpNameshow}
             bloodtype={robotsinfo[i].bloodtype}
             Tel1={robotsinfo[i].Tel1}
             Tel2={robotsinfo[i].Tel2}
             Wilaya={robotsinfo[i].Wilaya}
             idbaladiya={robotsinfo[i].idbaladiya}
             daydon={robotsinfo[i].daydon}
             monthdon={robotsinfo[i].monthdon}
             yeardon={robotsinfo[i].yeardon}
             Email_doct={robotsinfo[i].Email_doct}
             Num_sp={robotsinfo[i].bloodtype}
              />

        </Grid>
        
        
        </>}


 

              </>
          );
        })
      }

</Grid>
</Box>
     </div>

      )
  
    }
    
    export default CardListDonState;
  
  


/*const CardListDoc = ({ robots,Bldlists }) => {
  return (
    <div>
      {
         
        robots.map((user, i) => {
          return (
            <CardDoc
              key={i}
              Num_doct={robots[i].Num_doct}
              Name_doct={robots[i].Name_doct}
              Email_doct={robots[i].Email_doct}
              />
          );
        })
      }
    </div>
  );
}

export default CardListDoc;*/