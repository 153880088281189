 import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
import Modal from 'react-bootstrap/Modal';
import { FaMapMarkerAlt,FaPhoneAlt,FaMobileAlt,FaMapSigns, FaEnvelopeOpenText,FaMapMarkedAlt,FaMapPin,FaInfoCircle,FaCalendarAlt } from 'react-icons/fa';
import ThemeProvider from 'react-bootstrap/ThemeProvider';
import CloseButton from 'react-bootstrap/CloseButton';
import allergologie from '../iconimg/allergologie.png';
 


import React, { Component } from 'react'

export default class CardBlood   extends Component {

  constructor() {
    super()

    this.state = {
      show:false,
      showhidvaluetel1: 'HideBld',
      showhidvaluetel2: 'HideBld',
      showhidvalueemail: 'HideBld',
      showhidvalueteldate:'HideBld'
       
    }
  }

  
  render() {
    const handleClose = () => this.setState({ show: false });
  const handleShow = () => this.setState({ show: true });

  


  let tel1show=this.props.Tel1;
  if(tel1show.length==8 || tel1show.length==9){tel1show=`0${tel1show}`;}
  let tel2show=this.props.Tel2;
  if(tel2show.length==8 || tel2show.length==9){tel2show=`0${tel2show}`;}


let PhoneOrMobiletel1=<FaPhoneAlt />;
if(tel1show.length==10){PhoneOrMobiletel1=<FaMobileAlt />;}
let PhoneOrMobiletel2=<FaPhoneAlt />;
if(tel2show.length==10){PhoneOrMobiletel2=<FaMobileAlt />;}


  if(tel1show==0 || tel1show ==null ){
    this.state.showhidvaluetel1='HideBld'
  }
  else{
    this.state.showhidvaluetel1='ShowBld'
  }


  if(tel2show==0 || tel2show ==null ){
    this.state.showhidvaluetel2='HideBld'
  }
  else{
    this.state.showhidvaluetel2='ShowBld'
  }
////////////
  if(this.props.Email_doct==0 || this.props.Email_doct ==null || this.props.Email_doct =='' ){
    this.state.showhidvalueemail='HideBld'
  }
  else{
    this.state.showhidvalueemail='ShowBld'
  }

  //////////////////////
  if(this.props.daydon==0 || this.props.daydon ==null || this.props.monthdon==0 || this.props.monthdon ==null || this.props.yeardon==0 || this.props.yeardon ==null ){
    this.state.showhidvalueteldate='HideBld'
  }
  else{
    this.state.showhidvalueteldate='ShowBld'
  }

  
/////////////
var nameicon='bloddon.png';

if (this.props.Num_sp == 1) {
  nameicon='bloodaplus.png';
} else if (this.props.Num_sp == 2) {
  nameicon='bloodamin.png';
} else if (this.props.Num_sp == 3) {
  nameicon='bloodbplus.png';
} else if (this.props.Num_sp == 4) {
  nameicon='bloodbmin.png';
} else if (this.props.Num_sp == 5) {
  nameicon='bloodabplus.png';
} else if (this.props.Num_sp == 6) {
  nameicon='bloodabmin.png';
} else if (this.props.Num_sp == 7) {
  nameicon='bloodoplus.png';
} else if (this.props.Num_sp == 8) {
  nameicon='bloodomin.png';
}
else {
  nameicon='bloddon.png';
}


//////////blood name///////////////
var namebloodtype='الزمرة الدموية';
 

if(this.props.Num_sp== 1){namebloodtype="+A";}
                else if(this.props.Num_sp== 2){namebloodtype="-A";}
                else if(this.props.Num_sp== 3){namebloodtype="+B";}
                else if(this.props.Num_sp== 4){namebloodtype="-B";}
                else if(this.props.Num_sp== 5){namebloodtype="+AB";}
                else if(this.props.Num_sp== 6){namebloodtype="-AB";}
                else if(this.props.Num_sp== 7){namebloodtype="+O";}
                else if(this.props.Num_sp== 8){namebloodtype="-O";}
                else {
                  namebloodtype='الزمرة الدموية';
                }
            

//console.log('../iconimg/'+nameicon);
 
    return (
 

      <div>
     
    


    <Card border="primary" className='mt-2 mb-2 '>
      <Card.Body>
        
      <div className="container-fluid">
  <div className="row">
  
    <div className="col">
    <Card.Title>
    <p className="h2 font-weight-bold ">{this.props.Namef}</p>
    </Card.Title>
        <Card.Text>
        <p className="h6 font-weight-bold text-muted "><FaMapPin/> زمرة الدم: {namebloodtype}</p>
    <p className="h6 text-success"><span><FaMapMarkerAlt /> {this.props.Bldianame} , {this.props.Wilayname} </span> </p>
   
        </Card.Text>
    </div>
    <div className="col-3">
    <div className="text-center">
      
    <Image 
    className='img-fluid' 
    src={ require('../iconimg/'+nameicon) } />

    </div>

    </div>
  </div>
  
  </div>
     
  <div className="row">
  <Button variant="primary" onClick={handleShow}><p className="h4 font-weight-bold "> <FaInfoCircle/> المزيد... </p></Button>
  </div>

      </Card.Body>
    </Card>
    
    <ThemeProvider dir="rtl">

 
<Modal
    show={this.state.show}
    onHide={handleClose}
    backdrop="static"
    keyboard={false}
    size="lg"
  aria-labelledby="contained-modal-title-vcenter"
  centered
  >
    

    <Modal.Header  >
      <Modal.Title id="contained-modal-title-vcenter"><p className="h2 font-weight-bold ">{this.props.Namef}</p></Modal.Title>
       <span aria-hidden="true"><CloseButton onClick={handleClose}></CloseButton></span>
    
    </Modal.Header>
    <Modal.Body>

    
    <p className="h6 font-weight-bold text-muted "><FaMapPin/> زمرة الدم: {namebloodtype}</p>
    <p className="h6 text-success"><span><FaMapMarkerAlt /> {this.props.Bldianame} , {this.props.Wilayname} </span> </p>

<p className="h4" id={this.state.showhidvaluetel1}> {tel1show} <a className="btn btn-outline-primary" href={'tel:'+tel1show}>إتصل الآن  {PhoneOrMobiletel1} </a>   </p>
<p className="h4" id={this.state.showhidvaluetel2}> {tel2show} <a className="btn btn-outline-success" href={'tel:'+tel2show}>إتصل الآن  {PhoneOrMobiletel2} </a>   </p>    
<br />
<p className="h5 font-weight-bold text-primary" id={this.state.showhidvalueteldate}><FaCalendarAlt/> تاريخ آخر تبرع: {this.props.daydon+'/'+this.props.monthdon+'/'+this.props.yeardon}</p>


    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        إغلاق
      </Button>
     </Modal.Footer>
  </Modal>

  </ThemeProvider>

    
    </div>



    )
  }
}




/* 
 <img alt='robots' src={`https://robohash.org/${Num_doct}?size=200x200`} />
      <div>
        <h2>{Name_doct}</h2>
        <p>{Email_doct}</p>
      </div>
      */