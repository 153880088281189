 import CardBlood from './CardBlood';

import React, { Component } from 'react'

export default class CardListBlood  extends Component {

  constructor() {
    super()

    this.state = {
     }
  }


  render() {
     

    var robotsinfo = this.props.robots;
    var Bldlistsinfo = this.props.BldlistsCard;
    var splistsinfo = this.props.splistsCard;
    
 
    /* const BldName = Bldlistsinfo.filter(Bldlistsinfo =>{
      return Bldlistsinfo.ID ==1;
    })
    console.log(BldName[0].NOM);

    
   
    BldName = Bldlistsinfo.filter(Bldlistsinfo =>{
      return Bldlistsinfo.ID ==1;
    });
    console.log(BldName);
*/
    return (
      <div>
      {

         robotsinfo.map((user, i) => {

          ////////////
          let BldNameshow='';
           if(Bldlistsinfo.length !=0 &&  robotsinfo[i].idbaladiya !=null){
            const BldName = Bldlistsinfo.find(Bldlistsinfo =>{
              return Bldlistsinfo.ID ==robotsinfo[i].idbaladiya;
            })
            if( BldName !=null){
              BldNameshow=BldName.Nomfr+' ('+BldName.NOM+')';

            }

          
          }
          //////////////////////

          let WilyNameshow='';
          if(Bldlistsinfo.length !=0 && robotsinfo[i].Wilaya !=null){
            const  WlyName = Bldlistsinfo.find(Bldlistsinfo =>{
              return Bldlistsinfo.MatWilaya ==robotsinfo[i].Wilaya && Bldlistsinfo.wlycaptal ==1;
            })
            if( WlyName !=null){
              WilyNameshow=WlyName.Nomfr+' ('+WlyName.NOM+')';
                
            }
          }

          //////////////////////

          let SpNameshow='';
          if(splistsinfo.length !=0 && robotsinfo[i].bloodtype !=null){
            const SpName = splistsinfo.filter(splistsinfo =>{
              return splistsinfo.value ==robotsinfo[i].bloodtype;
            })
            if( SpName !=null){
              SpNameshow=SpName[0].value;

            }
          }

 

          return (
           
 
            <CardBlood
              key={i}
              ID={robotsinfo[i].ID}
              Namef={robotsinfo[i].Namef}
              Bldianame={BldNameshow}
              Wilayname={WilyNameshow}
              Spname={SpNameshow}
              bloodtype={robotsinfo[i].bloodtype}
              Tel1={robotsinfo[i].Tel1}
              Tel2={robotsinfo[i].Tel2}
              Wilaya={robotsinfo[i].Wilaya}
              idbaladiya={robotsinfo[i].idbaladiya}
              daydon={robotsinfo[i].daydon}
              monthdon={robotsinfo[i].monthdon}
              yeardon={robotsinfo[i].yeardon}
              Email_doct={robotsinfo[i].Email_doct}
              Num_sp={robotsinfo[i].bloodtype}
              
              />
          );
        })
      }
    </div>

      )
    }
  }
  


/*const CardListDoc = ({ robots,Bldlists }) => {
  return (
    <div>
      {
         
        robots.map((user, i) => {
          return (
            <CardDoc
              key={i}
              Num_doct={robots[i].Num_doct}
              Name_doct={robots[i].Name_doct}
              Email_doct={robots[i].Email_doct}
              />
          );
        })
      }
    </div>
  );
}

export default CardListDoc;*/