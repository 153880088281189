import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Pagination from "react-js-pagination";

import CardListBlood from '../components/CardListBlood';
import SearchBoxDoc from '../components/SearchBoxDoc';
import ScrollDoc from '../components/ScrollDoc';
import './AppBlood.css';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
//require("react-bootstrap/ModalHeader")
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import appico from '../iconimg/appico.png';
import playstoredownload from '../iconimg/playstoredownload.png';
import Spinner from 'react-bootstrap/Spinner';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


/*
 if(bloodtype.equals("1")){bloodtypen=" _ A+";}
                else if(bloodtype.equals("2")){bloodtypen=" _ A-";}
                else if(bloodtype.equals("3")){bloodtypen=" _ B+";}
                else if(bloodtype.equals("4")){bloodtypen=" _ B-";}
                else if(bloodtype.equals("5")){bloodtypen=" _ AB+";}
                else if(bloodtype.equals("6")){bloodtypen=" _ AB-";}
                else if(bloodtype.equals("7")){bloodtypen=" _ O+";}
                else if(bloodtype.equals("8")){bloodtypen=" _ O-";}
*/
 const options_Sp = [ //sp mean  blood typ
  { value: 0, label: "جميع الزمر الدموية"+' (Tous Les Groupes)' },
  { value: 1, label: '+A' },
  { value: 2, label: '-A' },
  { value: 3, label: '+B' },
  { value: 4, label: '-B' },
  { value: 5, label: '+AB' },
  { value: 6, label: '-AB' },
  { value: 7, label: '+O' },
  { value: 8, label: '-O' }
]


let options_Wly = [];
let options_Bld = [];
 
let robots=[];
let Wlylists=[];
let Bldlists=[];
let BldlistsCard=[];
let splistsCard=[];
let  filteredRobots=[];
let BldOnly=[];

class AppBlood extends Component {
  
  state = {
     selectedSpOption: null,
     selectedWlyOption: null,
     selectedBldOption: null
     
  };

  constructor() {
    super()

    this.state = {
       show:false,
      showhidvalue: 'HideBld',
      robots: [],
      searchfield: '',
      selectSpValue: 0,
      selectWlyValue: 0,
      selectBldValue: 0,

      setCurrentPage: 1,
      /*setCurrentPage:1,*/
      postsPerPage:10,

      splists: [],
      Wlylists: [],
      Bldlists:[],
      BldlistsCard:[],
      splistsCard:[]

 

    }
  }


  handleSelectSpChange = (selectedSpOption) => {
    this.setState({ selectedSpOption }, () =>
      console.log(`Option selected:`, this.state.selectedSpOption.value),
      console.log(`Option selected:`, selectedSpOption.value),
      this.setState({ selectSpValue: selectedSpOption.value}),
      this.setState({ setCurrentPage: 1 })
    );
  };


  handleSelectWlyChange = (selectedWlyOption) => {
    this.setState({ selectedWlyOption }, () =>
      console.log(`Option selected:`, this.state.selectedWlyOption),
      console.log(`Option selected Wilaya:`, selectedWlyOption.value),
      this.setState({ selectWlyValue: selectedWlyOption.value}),
      this.setState({ setCurrentPage: 1 }),
     
      
      BldOnly = this.state.Bldlists.filter(Bldlists =>{
        if(selectedWlyOption.value==0){
          this.state.showhidvalue='HideBld'
        }
        else{
          this.state.showhidvalue='ShowBld'
        }

        return Bldlists.MatWilaya ==selectedWlyOption.value;
      }),

      /////////////Baladia///////////////////
options_Bld =BldOnly.sort(function(a, b){
  if(Number(a.ID) < Number(b.ID)) return -1;  // Ascending Order
  if(Number(a.ID) > Number(b.ID)) return 1;   // Descending Order  
  return 0; // Default
}).map(function (Bldlists,i) {
return { value: Bldlists.ID, label: (i+1)+'- ' + Bldlists.NOM+' ('+Bldlists.Nomfr+')'};
}),

options_Bld.unshift({value: '0', label: 'جميع البلديات'+' (Toutes Les Communes)'}),
this.setState({ selectedBldOption: {value: '0', label: 'جميع البلديات'+' (Toutes Les Communes)'}})

    )
 
  };


  handleSelectBldChange = (selectedBldOption) => {
    this.setState({ selectedBldOption }, () =>
      console.log(`Option selected:`, this.state.selectedBldOption),
      console.log(`Option selected Baladia:`, selectedBldOption.value),
      this.setState({ selectBldValue: selectedBldOption.value}),
      this.setState({ setCurrentPage: 1 })
    );
  };

  componentDidMount() {
    
    fetch('https://doctorsdz.machrou3.com/user/getBaladiya4appdoc')
      .then(response=> response.json())
      .then(getBaladiya4appdoc => {
        this.setState({ Wlylists: getBaladiya4appdoc ,Bldlists: getBaladiya4appdoc,BldlistsCard: getBaladiya4appdoc})
      });

       fetch('https://doctorsdz.machrou3.com/user/getsp4appdoc')
      .then(response=> response.json())
      .then(getsp4appdoc => {this.setState({ splists: getsp4appdoc,splistsCard:getsp4appdoc})});
     

    /*fetch('https://doctorsdz.machrou3.com/user/getinfoAppDoc')
      .then(response=> response.json())
      .then(getinfoAppDoc => {this.setState({ robots: getinfoAppDoc})});*/


      fetch('https://doctorsdz.machrou3.com/user/getinfoAppBlood')
      .then(response=> response.json())
      .then(getinfoAppBlood => {this.setState({ robots: getinfoAppBlood})});


  }


   

  render() {
    console.log('render');
 
    const { selectedSpOption,selectSpValue,selectedWlyOption,selectWlyValue,selectedBldOption,selectBldValue } = this.state;
    let { robots } = this.state;
    
    const {  searchfield,setCurrentPage,postsPerPage,splists } = this.state;


 /////////////Wilayets///////////////////

const WlyOnly = this.state.Wlylists.filter(Wlylists =>{
  return Wlylists.wlycaptal !==null;
})

options_Wly =WlyOnly.sort(function(a, b){
  if(Number(a.MatWilaya) < Number(b.MatWilaya)) return -1;  // Ascending Order
  if(Number(a.MatWilaya) > Number(b.MatWilaya)) return 1;   // Descending Order  
  return 0; // Default
}).map(function (Wlylists,i) {
return { value: Wlylists.MatWilaya, label: Wlylists.MatWilaya+'- ' + Wlylists.NOM+' ('+Wlylists.Nomfr+')'};
}) 

options_Wly.unshift({value: '0', label: 'جميع الولايات'+' (Toutes Les Wilayas)'})




console.log(selectSpValue);
console.log(selectWlyValue);
console.log(selectBldValue);
console.log(robots.length);

     filteredRobots = robots.filter(robots =>{

       //All bloodtype and All wly
      if(selectSpValue==0 && selectWlyValue==0){
         return robots;
      }
      //All bloodtype and one wly
      else if(selectSpValue==0 && selectWlyValue != 0){
        console.log('All bloodtype And one wly And One Bld')
        //All bloodtype And one wly And One Bld
         if(selectBldValue!=0){
          return  ((robots.idbaladiya) ===selectBldValue.toString()) ;
         }
         else{
          //All bloodtype and one wly
          return  ((robots.Wilaya) ===selectWlyValue.toString()) ;

         }
      }
      //one bloodtype and All wly
      else if(selectSpValue != 0 && selectWlyValue == 0){
         return (((robots.bloodtype) === (selectSpValue).toString())) ;
      }
      //one bloodtype and one wly
      else if(selectSpValue != 0 && selectWlyValue != 0){

        //one bloodtype and one wly and one bld
        if(selectBldValue!=0){
          return ((robots.bloodtype) ===selectSpValue.toString()) && (robots.idbaladiya) ===selectBldValue.toString() ;

        }else{
          return ((robots.bloodtype) ===selectSpValue.toString()) &&  (robots.Wilaya) ===selectWlyValue.toString() ;
        }

      }

       
    })

    

    // Get current posts
  const indexOfLastPost = setCurrentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredRobots.slice(indexOfFirstPost, indexOfLastPost);

  console.log(filteredRobots.length);


  const handlePageChange = pageNumber => {
    console.log(`active page is ${pageNumber}`);
 
    this.setState({ setCurrentPage: pageNumber });
  };

   
  const handleClose = () => this.setState({ show: false });
  const handleShow = () => this.setState({ show: true });

 
    return !robots.length ?
   /* <div className="container_loading">
  <div className="text-center" >
  <Spinner animation="grow imgB2" variant="primary" size="sm" />

  <img src={appico} className="img-fluid imgB1" alt="Responsive image" width="48" height="48" />

  </div>
</div>*/

<Spinner animation="grow spinsize" variant="primary" size="sm" />


:

      (
        
        
           
          <div className="container">
           
    <div className="row text-center">
         
    

        <Select
        className="selectsp text-center"
        value={selectedSpOption}
        onChange={this.handleSelectSpChange}
        defaultValue={{ label: "جميع الزمر الدموية"+' (Tous Les Groupes)', value: 0 }}
        options={options_Sp}
        />

        <Select
        className="selectwly text-center"
        value={selectedWlyOption}
        onChange={this.handleSelectWlyChange}
        defaultValue={{ label: 'جميع الولايات'+' (Toutes Les Wilayas)', value: 0 }}
        options={options_Wly}
        />

        <Select
        id={this.state.showhidvalue}
        className="selectbld text-center"
        value={selectedBldOption}
        onChange={this.handleSelectBldChange}
        defaultValue={{ label: "جميع البلديات"+' (Toutes Les Communes)', value: 0 }}
        options={options_Bld}
        />

         
          
             <CardListBlood 
             robots={currentPosts}
             BldlistsCard={this.state.BldlistsCard}
             splistsCard={options_Sp} />
           

           
          <div style={{ display: "flex", justifyContent: "center" }}>

          <Pagination
          
          itemClass="page-item"
          linkClass="page-link"
          totalItemsCount={filteredRobots.length}
          onChange={handlePageChange}
          activePage={this.state.setCurrentPage}
          forceSelected={this.state.setCurrentPage}
          itemsCountPerPage={15}
          pageRangeDisplayed={4}
          prevPageText="‹"
          nextPageText="›"
          firstPageText="«"
          lastPageText="»"
           
        />


</div>

 

</div>

        </div>
      );
  }
}

export default AppBlood;