import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';

async function revokeAccessToken() {
  // Make a request to Google's OAuth revoke endpoint with the token
  const revokeResponse = await fetch('https://oauth2.googleapis.com/revoke', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: `token=${localStorage.getItem('accessToken')}`,
  });

  // Handle revoke response (check for success or failure)
  if (!revokeResponse.ok) {
    console.error('Error revoking access token:', await revokeResponse.text());
  }
}

function DeleteAccount() {
  const [isDeleting, setIsDeleting] = useState(false);
  const [alert, setAlert] = useState({ message: '', className: '' });
  const navigate = useNavigate();

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      // Revoke access token before account deletion
      await revokeAccessToken();

      // Send a DELETE request to the API endpoint for account deletion
      const response = await fetch('/api/account/delete', {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`, // Assuming token based auth
        },
      });

      if (response.ok) {
        // Account deletion successful
        localStorage.removeItem('accessToken'); // Remove auth token
        setAlert({ message: 'Account Deleted', className: 'alert-success' });
        navigate('/logindon'); // Redirect to login page
      } else {
        // Handle deletion error
        console.error('Error deleting account:', await response.text());
        setIsDeleting(false); // Reset deletion state
        setAlert({ message: 'Error deleting account', className: 'alert-danger' });
      }
    } catch (error) {
      console.error('Error deleting account:', error);
      setIsDeleting(false); // Reset deletion state
      setAlert({ message: 'An error occurred while deleting the account', className: 'alert-danger' });
    }
  };

  const responseGoogle = async (response) => {
    console.log(response);
    if (response.profileObj && response.profileObj.email) {
      try {
        const deleteResponse = await fetch(`https://doctorsdz.machrou3.com/User/delete_account/${response.profileObj.email}`);
        const data = await deleteResponse.json();
         
        if (data.delete_account === true) {
          // Account deleted successfully
          setAlert({ message: 'Account Pending Deletion', className: 'alert-success' });
        } else {
          // Account deletion failed
          setAlert({ message: 'Account not Deleted', className: 'alert-danger' });
        }
      } catch (error) {
        console.error('Error deleting account:', error);
        setAlert({ message: 'An error occurred while deleting the account', className: 'alert-danger' });
      }
    }
  };

  return (
    <div dir='ltr' className="d-flex justify-content-center align-items-center min-vh-100">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Delete Account</h5>
          </div>
          <div className="modal-body">
            <p>Are you sure you want to delete your account? This action is permanent and cannot be undone.</p>
            <p>
              For detailed instructions on deleting your account and associated data, please visit our{' '}
              <a href="/logindon">Delete Your Account</a> page.
            </p>
            <p>
              {/* Optional: Link to Google Account Settings */}
              You can also manage your Google Account settings
              <a href="/logindon" target="_blank">
                here
              </a>.
            </p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" disabled={isDeleting} onClick={() => navigate('/doc')}>
              Cancel
            </button>
            
            <GoogleLogin
              clientId="1035721365389-4o712jenov94dc9npbhe8o7676doq1k7.apps.googleusercontent.com"
              className="btn btn-danger"
              buttonText="Delete Account"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
            />
          </div>
          {alert.message && (
            <div className={`alert ${alert.className}`} role="alert">
              {alert.message}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DeleteAccount;
