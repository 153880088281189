import React, { useState,useEffect }  from 'react';
//import 'bootstrap/dist/css/bootstrap.min.css';

import FacebookIcon from '@mui/icons-material/Facebook';
import Avatar from '@mui/material/Avatar';

import Grid from '@mui/material/Grid';

import appico from '../iconimg/appico.png';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';



export default function Footer() {
   
  


   return(
     
    <>
      
    

<div class="container" dir='ltr' id="footer">
  <footer  class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
     

    <Grid spacing={0}
  direction="column"
  alignItems="center"
  justifyContent="center"
  dir="rtl" container sx={{mt:0,}}>
              <Grid item >
               
              <a href="https://play.google.com/store/apps/details?id=dz.sebrou.docts_guide.app" class="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
      <Avatar
        alt="تطبيق أطباء الجزائر"
        src={appico}
        sx={{ width:40, height:40, border: '2px solid #448a9a' }}
      />
      </a>

              </Grid>
              <Grid item sx={{mt:1,}}>
                
              <Typography variant="body2" color="text.secondary" align="center" >
      {'Copyright © '}
      <Link color="inherit" href="https://AtibaDz.com/">
        Abdelaziz sebrou
      </Link> {' '}
      {new Date().getFullYear()}
      {' '}
    </Typography>

              </Grid>

              <Grid item sx={{mt:1,}}>
                
    <a style={{textDecoration:'none'}} class="text-muted" href="https://www.facebook.com/atibaaeldjazair">  
       
    <Typography 
          sx={{ color: "#00000", fontFamily: 
          '"Tajawal", sans-serif' 
          ,fontWeight:400,
          fontSize:16
           }}
          >
              <FacebookIcon></FacebookIcon> صفحتنا  
          </Typography>
          </a>
</Grid>
            </Grid>



  </footer>
</div>


</>
    )








    
    



   
  
}

 
