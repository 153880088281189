import CardDocState from './CardDocState';

import React, { useState,useEffect }  from 'react';
import LinearProgress from '@mui/material/LinearProgress';

import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import Box from '@mui/material/Box';
 import {
    AppBar,
    Button,
    Tab,
    Tabs,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme,
  } from "@mui/material";

    const CardListDocState = (props) => {
      /*  const [robots, setrobots] = useState([]);
        const [BldlistsCard, setBldlistsCard] = useState([]);
        const [splistsCard, setsplistsCard] = useState([]);*/

        const theme = useTheme();
        console.log(theme);
        const isMatch = useMediaQuery(theme.breakpoints.down("md"));
      console.log(isMatch);

      const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      }));
    
      
  

     
    var robotsinfo = props.robots;
    var Bldlistsinfo = props.BldlistsCard;
    var splistsinfo = props.splistsCard;
    
       console.log('robotsinfo'+robotsinfo)
    /* const BldName = Bldlistsinfo.filter(Bldlistsinfo =>{
      return Bldlistsinfo.ID ==1;
    })
    console.log(BldName[0].NOM);

    
   
    BldName = Bldlistsinfo.filter(Bldlistsinfo =>{
      return Bldlistsinfo.ID ==1;
    });
    console.log(BldName);
*/

  

   

    return (
      


      <div>
        {(!props.hasOwnProperty('robots') ? <>
          <Box sx={{ width: '100%',mt:0 }} > 
      
      <LinearProgress />
      </Box>
      </>: <></>)}

<Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>


        {/* <List     sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper'
 }}>*/}



      {

         

         robotsinfo.map((user, i) => {

          ////////////
          let BldNameshow='';
          if(Bldlistsinfo.length !=0 && robotsinfo[i].idbaladiya !=null){
            const  BldName = Bldlistsinfo.filter(Bldlistsinfo =>{
              return Bldlistsinfo.ID ==robotsinfo[i].idbaladiya;
            })
            if( BldName !=null){
              BldNameshow=BldName[0].Nomfr+' ('+BldName[0].NOM+')';

            }
            
          }
          //////////////////////

          let WilyNameshow='';
          if(Bldlistsinfo.length !=0 && robotsinfo[i].Wilaya_doct !=null){
            const  WlyName = Bldlistsinfo.filter(Bldlistsinfo =>{
              return Bldlistsinfo.MatWilaya ==robotsinfo[i].Wilaya_doct && Bldlistsinfo.wlycaptal ==1;
            })
            if( WlyName !=null){
            WilyNameshow=WlyName[0].Nomfr+' ('+WlyName[0].NOM+')';
            }
          }

          //////////////////////

          let SpNameshow='';
          if(splistsinfo.length !=0 && robotsinfo[i].Num_sp !=null){
            const SpName = splistsinfo.filter(splistsinfo =>{
              return splistsinfo.num_sp == robotsinfo[i].Num_sp;
            })
            if( SpName !=null){
            SpNameshow=SpName[0].name_sp+' ('+SpName[0].name_spfr+')';
            }
             
          }


          return (
            <>
        
        {/* if computer */ }
         {!isMatch ? 
        
         <>


            {/* split 2 */ }
            {i % 2 == 0 ? 
            
            <>

          
        <Grid item xs={6}>
        <CardDocState
              key={i}
              Num_doct={robotsinfo[i].Num_doct}
              Name_doct={robotsinfo[i].Name_doct}
              Bldianame={BldNameshow}
              Wilayname={WilyNameshow}
              Spname={SpNameshow}
              Email_doct={robotsinfo[i].Email_doct}
              Adress_doct={robotsinfo[i].Adress_doct}
              Tel1_doct={robotsinfo[i].Tel1_doct}
              Tel2_doct={robotsinfo[i].Tel2_doct}
              description_doct={robotsinfo[i].description_doct}
              latcor={robotsinfo[i].latcor}
              loncor={robotsinfo[i].loncor}
              Num_sp={robotsinfo[i].Num_sp}
              />

        </Grid>

        </> 
        
        : 
        
        <>

        <Grid item xs={6}>
          
          
        <CardDocState
              key={i}
              Num_doct={robotsinfo[i].Num_doct}
              Name_doct={robotsinfo[i].Name_doct}
              Bldianame={BldNameshow}
              Wilayname={WilyNameshow}
              Spname={SpNameshow}
              Email_doct={robotsinfo[i].Email_doct}
              Adress_doct={robotsinfo[i].Adress_doct}
              Tel1_doct={robotsinfo[i].Tel1_doct}
              Tel2_doct={robotsinfo[i].Tel2_doct}
              description_doct={robotsinfo[i].description_doct}
              latcor={robotsinfo[i].latcor}
              loncor={robotsinfo[i].loncor}
              Num_sp={robotsinfo[i].Num_sp}
              />


        </Grid>
            {/* End split 2 */ }

        </>}      

        </>:
        
        
        <>
        {/* if mobile */ }
        
        
        <Grid item xs={12}>
        <CardDocState
              key={i}
              Num_doct={robotsinfo[i].Num_doct}
              Name_doct={robotsinfo[i].Name_doct}
              Bldianame={BldNameshow}
              Wilayname={WilyNameshow}
              Spname={SpNameshow}
              Email_doct={robotsinfo[i].Email_doct}
              Adress_doct={robotsinfo[i].Adress_doct}
              Tel1_doct={robotsinfo[i].Tel1_doct}
              Tel2_doct={robotsinfo[i].Tel2_doct}
              description_doct={robotsinfo[i].description_doct}
              latcor={robotsinfo[i].latcor}
              loncor={robotsinfo[i].loncor}
              Num_sp={robotsinfo[i].Num_sp}
              />

        </Grid>
        
        
        </>}


 

              </>
          );
        })
      }

</Grid>
</Box>
     </div>

      )
  
    }
    
    export default CardListDocState;
  
  


/*const CardListDoc = ({ robots,Bldlists }) => {
  return (
    <div>
      {
         
        robots.map((user, i) => {
          return (
            <CardDoc
              key={i}
              Num_doct={robots[i].Num_doct}
              Name_doct={robots[i].Name_doct}
              Email_doct={robots[i].Email_doct}
              />
          );
        })
      }
    </div>
  );
}

export default CardListDoc;*/